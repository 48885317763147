export type OptionTab = {
    index: number,
    title: string,
    activeTab: number,
    setActiveTab: (val: number) => void,
    iconActive: any,
    iconInactive: any,
    action?: () => void,
}

type ScrollNavChipsType = {
    tabs: OptionTab[],
    activeTab: number,
    setActiveTab: (val: number) => void,
}

export const ScrollNavChips = (props: ScrollNavChipsType) => {
    return (
        <div className='rowTabs '>
            {props.tabs.map((x, i) => <NavChip key={i} {...{ ...x, iconActive: x.iconActive, iconInactive: x.iconInactive, activeTab: props.activeTab, setActiveTab: props.setActiveTab }} />)}
        </div>
    )
}

const NavChip = (props: OptionTab) => {
    const isActive = props.index === props.activeTab;
    const onSetActiveTab = () => props.setActiveTab(props.index);
    const iconValue = isActive ? props.iconActive() : props.iconInactive();

    return (
        <div onClick={onSetActiveTab} className={`chip align-items-center ${isActive ? ' chip-active' : ' chip-inactive'} pointer`} >
            {iconValue ? <img src={iconValue} alt={props.title} style={{ marginTop: 2 }} /> : null}
            <p className={`title-nav ${isActive ? 'title-nav-active' : 'title-nav-inactive'}`}>{props.title}</p>
        </div>
    )
}
