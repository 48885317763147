import { useMemo, useEffect, useCallback, useContext } from "react";
import { ActionType } from "../../../../services/ActionService";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import { translateInvoiceSetFields } from "../../../../services/InvoiceService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { FileListEditor } from "../../../shared/components/Editors";
import TooltipComponent from "../../../shared/TooltipComponent";
import { EditProps } from "../EditAction";
import { Checkbox, Field } from "./FieldHelpers";
import Label from "../../../shared/form/Label";
import AdvancedFilters, { AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import FilterService, { FilterCollection } from "../../../../services/FilterService";
import FileService from "../../../../services/FileService";
import { EmailInvoiceListType } from "../../../../services/ActivityService";

export const EmailAditionalContent = ({ setField, data }: EditProps) => {
    const sortBy = useMemo(() => {
        return (data.Mail_OrderBy ? data.Mail_OrderBy : CompanyService.getCompanyAuth()!.OrderMailBy).split(";").map(x => parseInt(x));
    }, [data.Mail_OrderBy]);
    const sortByOrder = useMemo(() => {
        return (data.Mail_OrderByOrderDesc ? data.Mail_OrderByOrderDesc : CompanyService.getCompanyAuth()!.OrderMailByOrderDesc).split(";").map(x => parseInt(x));
    }, [data.Mail_OrderByOrderDesc]);
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    useEffect(() => {
        advancedFiltersContext.setShow((data.Mail_Include ?? false) && (data.Mail_IOFilter !== undefined && data.Mail_IOFilter !== ""));
    }, [advancedFiltersContext, data.Mail_IOFilter, data.Mail_Include]);
    const invoiceListModeOptions: { value: "0" | "1", text: string }[] = useMemo(() => [
        { value: "1", text: TranslationService.translate.EmailOnBody },
        { value: "0", text: TranslationService.translate.EmailAsAttachment }
    ], []);
    const invoiceListTypeOptions = useMemo(() => [
        // { value: EmailInvoiceListType.All, text: TranslationService.translate.AllInvoices },
        { value: EmailInvoiceListType.Pending, text: TranslationService.translate.AllPending },
        { value: EmailInvoiceListType.Due, text: TranslationService.translate.OnlyDues },
        { value: EmailInvoiceListType.Claimables, text: TranslationService.translate.OnlyClaimable },
        { value: EmailInvoiceListType.DueClaimables, text: TranslationService.translate.OnlyDuesClaimable },
        { value: EmailInvoiceListType.Other, text: TranslationService.translate.AdvancedFilters },
    ], []);
    const reportTemplates = useMemo(() => CompanyService.getReportExports()
        .filter(x => x.Report === Entities.Invoice && x.Public)
        .map(x => ({ value: x.ReportExportID, text: x.Name ?? TranslationService.translate.Default })), []);

    const groupByList = useMemo(() => [
        { AdditionalDefinitionID: -1015, Name: TranslationService.translate.IOStatus },
        { AdditionalDefinitionID: -1017, Name: TranslationService.translate.Currency },
        { AdditionalDefinitionID: -1007, Name: CompanyService.getGroupName() },
        ...CompanyService.getAdditionalDefinitions().filter((def) => (def.entity === 1)),
    ].map(x => ({ text: x.Name, value: x.AdditionalDefinitionID })), []);

    const sortByList = useMemo(() => [
        ...[-1010, -1023, -1024, -1009, -1011, -1012, -1015, -1017, -1008]
            .map(x => ({ AdditionalDefinitionID: x, Name: translateInvoiceSetFields(x)! })),
        { AdditionalDefinitionID: -1008, Name: TranslationService.translate.Group },
        ...CompanyService.getAdditionalDefinitions().filter((def) => (def.entity === 1)),
    ].map(x => ({ text: x.Name, value: x.AdditionalDefinitionID })), []);

    const applyFilter = (filters: string[]) => {
        setField("Mail_IOFilter")(FilterService.GetExtraFiltersRequestString(filters));
    };

    const onChangeIncludeInvoiceList = useCallback((value: boolean) => {
        setField("Mail_IncludeAttachment")(false);
        setField("IncludeInvoices")(value)

        if (value) {
            setField('ListMode')('1')
            if ((ActionType.MailSend === data.Type && !data.when) || ActionType.ActionSendLetter === data.Type) {
                setField('QuickFilter')(EmailInvoiceListType.Pending)
            } else {
                setField('QuickFilter')(null)
            }
            setField("Mail_Include")(null); // deprecado
        } else {
            setField('ListMode')(null);
            setField('QuickFilter')(null);
            setField("Mail_Include")(null); // deprecado
            setField("Mail_IncludeClaimable")(null); // deprecado
            setField("Mail_IncludeDue")(null); // deprecado
            setField("Mail_IncludeDueClaimable")(null); // deprecado
            setField("Mail_Attach")(null); // deprecado
            setField("Mail_AttachClaimable")(null); // deprecado
            setField("Mail_AttachDue")(null); // deprecado
            setField("Mail_AttachDueClaimable")(null); // deprecado
        }
    }, [data.Type, data.when, setField]);

    const onChangeInvoice = (value: "0" | "1" | 0 | 1 | null) => {
        if (value) {
            setField("ListMode")(value)
        } else if (data.Mail_IncludeDue || data.Mail_AttachDue) {
            setField("Mail_IncludeDue")(null) // deprecado (value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachDue")(null) // deprecado (value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else if (data.Mail_IncludeClaimable || data.Mail_AttachClaimable) {
            setField("Mail_IncludeClaimable")(null) // deprecado (value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachClaimable")(null) // deprecado (value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else if (data.Mail_IncludeDueClaimable || data.Mail_AttachDueClaimable) {
            setField("Mail_IncludeDueClaimable")(null) // deprecado (value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_AttachDueClaimable")(null) // deprecado (value === InvoiceListModeOptions.EmailAsAttachment);
        }
        else {
            setField("Mail_Include")(null) // deprecado (value === InvoiceListModeOptions.EmailOnBody);
            setField("Mail_Attach")(null) // deprecado (value === InvoiceListModeOptions.EmailAsAttachment);
        }
    };

    const onChangeClaimable = (value: EmailInvoiceListType | null) => {
        setField("QuickFilter")(value)

        setField("Mail_Include")(null) // Deprecado (value === InvoiceListTypeOptions.AllPending || value === InvoiceListTypeOptions.OtherFilters);
        setField("Mail_IncludeClaimable")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyClaimable);
        setField("Mail_IncludeDue")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyDues);
        setField("Mail_IncludeDueClaimable")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyDuesClaimable);
        setField("Mail_Attach")(null) // Deprecado (value === InvoiceListTypeOptions.AllPending || value === InvoiceListTypeOptions.OtherFilters);
        setField("Mail_AttachClaimable")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyClaimable);
        setField("Mail_AttachDue")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyDues);
        setField("Mail_AttachDueClaimable")(null) // Deprecado (value === InvoiceListTypeOptions.OnlyDuesClaimable);

        advancedFiltersContext.setShow(value === EmailInvoiceListType.Other);
    };

    const changeSort = (index: number, newVal: number) => {
        sortBy[index] = newVal;
        setField("Mail_OrderBy")(sortBy.join(";"));
    };

    const changeSortOrder = (index: number, newVal: boolean) => {
        sortByOrder[index] = newVal ? 1 : 0;
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    };

    const sortAdd = () => {
        const first = sortByList[1].value;
        sortBy.push(first);
        sortByOrder.push(0);
        setField("Mail_OrderBy")(sortBy.join(";"));
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    };

    const sortRemove = () => {
        sortByOrder.pop();
        sortBy.pop();
        setField("Mail_OrderBy")(sortBy.join(";"));
        setField("Mail_OrderByOrderDesc")(sortByOrder.join(";"));
    };

    /**
     * Cambiar a notificacion de nueva factura te va a quitar la posibilidad de indicar
     * incluir tipo de facturas. Por ende Quickfilter debe ser null
     */
    useEffect(() => {
        if (data.IncludeInvoices) {
            if (
                !((ActionType.MailSend === data.Type && !data.when) || ActionType.ActionSendLetter === data.Type)
                && data.QuickFilter !== null
            ) {
                setField("QuickFilter")(EmailInvoiceListType.Pending)
            }
            else if (
                ((ActionType.MailSend === data.Type && !data.when) || ActionType.ActionSendLetter === data.Type)
                && data.QuickFilter === null
            ) {
                setField('QuickFilter')(EmailInvoiceListType.Pending)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.IncludeInvoices, data.QuickFilter, data.Type, data.when])

    return (
        <>
            <Checkbox onChange={setField("Mail_IncludeLink")} defaultValue={data.Mail_IncludeLink}
                text={
                    <>
                        {TranslationService.translate.AddLink}
                        <TooltipComponent placement="right" title={TranslationService.translate.AddLinkTip}>
                            <i className="fal fa-question-circle ms-2" />
                        </TooltipComponent>
                    </>
                } />

            <Checkbox text={
                data.Type === ActionType.ActionSendLetter ?
                    <>
                        {TranslationService.translate.IncludeInvoiceList}
                        <TooltipComponent placement="right" title={TranslationService.translate.AddInvoicesTooltip}>
                            <i className="fal fa-question-circle ms-2" />
                        </TooltipComponent>
                    </>
                    :
                    TranslationService.translate.IncludeInvoiceList
            }
                onChange={onChangeIncludeInvoiceList}
                value={Boolean(data.ListMode?.toString())}
            />

            {
                Boolean(data.ListMode?.toString()) ?
                    <>
                        <div className="row">
                            {ActionType.MailSend === data.Type &&
                                <Field title={TranslationService.translate.Invoices}>
                                    <Dropdown items={invoiceListModeOptions} onChange={onChangeInvoice} defaultValue={data.ListMode} />
                                </Field>}
                            {((ActionType.MailSend === data.Type && !data.when) || ActionType.ActionSendLetter === data.Type) &&
                                <Field title={TranslationService.translate.Including}>
                                    <Dropdown items={invoiceListTypeOptions} onChange={onChangeClaimable} defaultValue={data.QuickFilter} />
                                </Field>
                            }
                            <Field title={TranslationService.translate.ReportTemplate}>
                                <Dropdown items={reportTemplates} optionLabel={TranslationService.translate.Default} onChange={setField("Mail_ReportExportID")} defaultValue={data.Mail_ReportExportID} />
                            </Field>
                        </div>
                        <div className="row">
                            {CompanyService.getSetting("alloweditgroupmailby") && <Field title={TranslationService.translate.GroupBy}>
                                <Dropdown items={groupByList} onChange={setField("Mail_GroupBy")} defaultValue={data.Mail_GroupBy ?? parseInt(CompanyService.getCompanyAuth()!.GroupMailBy)} optionLabel={TranslationService.translate.None} />
                            </Field>}
                            {sortBy.map((x, i) =>
                                <Field key={i} title={i === 0 ? TranslationService.translate.SortBy : TranslationService.translate.ThenBy}>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <Dropdown items={sortByList} onChange={val => changeSort(i, val)} defaultValue={x} selectClasses={"d-inline"} />
                                        </div>
                                        <div className="col-auto">
                                            <Checkbox text={<i className="fa-regular fa-arrow-down-z-a"></i>} onChange={val => changeSortOrder(i, val)} defaultValue={sortByOrder[i] === 1} />
                                        </div>
                                    </div>
                                </Field>)}
                            <div className="col-4 mb-3">
                                <Label>
                                    <div className="d-flex">
                                        {sortBy.length < 3 &&
                                            <button type="button" onClick={sortAdd} className="btn btn-link text-start ps-0">{TranslationService.translate.AddOrder} <i className="fa-regular fa-plus" /></button>
                                        }
                                        {
                                            sortBy.length > 1 &&
                                            <button type="button" onClick={sortRemove} className="btn btn-link text-start pe-0">{TranslationService.translate.RemoveOrder} <i className="fa-regular fa-trash-can" /></button>
                                        }
                                    </div>
                                </Label>
                            </div>
                        </div>
                    </> : null}
            <AdvancedFilters page={FilterCollection.ActionInvoice} defaultValue={data.Mail_IOFilter} onFilterApply={applyFilter} showSaveFilter={false} />
            {
                data.IncludeInvoices &&
                <Checkbox text={TranslationService.translate.IncludeAttachment} onChange={setField("Mail_IncludeAttachment")} defaultValue={data.Mail_IncludeAttachment} />
            }
        </>);
};

export const AttachFile = ({ setField, data }: EditProps) =>
    <FileListEditor downloadFile={(fileId, fileName) => FileService.downloadAction(fileId, data.ActionID?.toString() ?? "", fileName)} onFilesChange={x => setField("files")(x.map(y => ({ fileName: y.name, id: y.id, response: "" })))} files={data.files?.map(x => ({ id: x.id, name: x.fileName }))} canEdit={true} />;