import { useContext, useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import ToastContext from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { DatePickerEditor, TextAreaBigEditor } from "../../shared/components/Editors";
import { EntityField } from "../../shared/entities/EntityField";
import { TranslationService } from "../../../services/TranslationService";
import FieldEdit from "./InvoiceMassiveChangesFieldEdit";
import { InvoiceEditMarkAsPayed, Receipt } from "./entities/InvoiceEditMarkAsPayed";
import FileService from "../../../services/FileService";
import ClientDetailContext, { ClientDetailContextValues } from "../ClientDetailContext";
import { EmailCompose } from "../components/EmailCompose";
import FloatingPanelService from "../../shared/FloatingPanel";
import ModalService from "../../shared/bootstrap/Modal";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { DefaultModalProps } from "../../shared/bootstrap/DefaultModal";
import { InvoiceListRequest } from "./entities/InvoiceListRequest";
import { OptionalMap } from "../../../utils/Utils";

export const InvoiceMarkAsPayed = ({ personId, onSubmit, selectedInvoiceCount, filters, clientDetailContext }:
    { personId: number, onSubmit: () => void, selectedInvoiceCount: number, filters: InvoiceListRequest, clientDetailContext: ClientDetailContextValues }) => {
    const [editModel, setEditModel] = useState(new InvoiceEditMarkAsPayed(personId.toString()));
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const { invoiceAll, invoiceIds, setInvoiceAll, setInvoiceIds } = clientDetailContext;
    const [isSaving, setIsSaving] = useState<Receipt | boolean>(false);
    const { showDefaultModal } = ModalService;
    const hasReceipt = (CompanyService.canDo("hasReceipt") && CompanyService.canShowReceipt());

    const fields = [
        ...CompanyService.getConfigItemsFiltered(Entities.Payment).map(x => new EntityField(x)),
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Payment).map(x => new EntityField(x)),
    ];
    const transactionTypes = CompanyService.getTransactionTypes();
    const clientDetailContextValues = useContext(ClientDetailContext);
    const defaultFieldChange = (propName: string) => (newValue: string | number | undefined) => {
        setEditModel({ ...editModel, [propName]: newValue });
    };

    const paymentFieldChange = (field: EntityField) => (value: string | undefined) => {
        if (value && field.type === DataType.Date) {
            value = new Date(value).toJSON();
        }
        const newAdditionals = [...editModel.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }];
        setEditModel({ ...editModel, additionals: newAdditionals });
    };

    const submit = async (receipt?: Receipt) => {
        const modalProps: DefaultModalProps = {
            message: (selectedInvoiceCount === 1 ? translate.MassiveActionConfirmPay : translate.MassiveActionConfirmPayMultiple).replaceAll("{0}", selectedInvoiceCount.toString()),
            acceptButtonLabel: translate.Accept,
            title: translate.MarkAsPaid,
            onAcceptClick: async () => {
                if (isSaving) {
                    return;
                }
                setIsSaving(receipt ?? true);
                const result = await InvoiceService.togglePay({
                    pay: true,
                    invoiceIds: invoiceIds,
                    all: invoiceAll,
                    filters: filters.filter,
                    personId: editModel.personId,
                    comments: editModel.comments,
                    date: OptionalMap(editModel.selPayDate, x => new Date(x)),
                    transactiontype: editModel.transactiontype,
                    additional: editModel.additionals.map(x => ({ Id: x.Id.toString(), Value: x.Value })),
                    receipt: receipt === Receipt.Download
                });
                if (result instanceof Error) {
                    showToast(translate.ErrorProcessingRequest, undefined, "danger");
                }
                else {
                    showToast(translate.SavedChanges, undefined, "success");
                    setInvoiceAll(false);
                    setInvoiceIds([]);
                    onSubmit();
                    switch (receipt) {
                        case Receipt.Download:
                            if (result.receiptId !== 0) {
                                FileService.downloadReceipt(result.receiptId.toString());
                                FloatingPanelService.hidePanel();
                            }
                            break;
                        case Receipt.SendViaEmail:
                            FloatingPanelService.showPanel({
                                title: translate.EmailToClient,
                                children: <EmailCompose personId={personId.toString()} defaultTo={CompanyService.getUserEmail()} clientDetailContext={clientDetailContextValues} />,
                                width: 900,
                                height: 669,
                            });
                            break;
                        default:
                            FloatingPanelService.hidePanel();
                    }
                }
                setIsSaving(false);
            }
        };
        showDefaultModal(modalProps);
    };

    return (
        <>
            <div className="floatingBody p-4">
                <h5 className="pb-3">{translate.IOMarkAsPaidTitle}</h5>
                <div className="d-flex input-column mb-3 col-md-3">
                    <label className="form-label">{translate.PaymentDate}</label>
                    <DatePickerEditor onChange={defaultFieldChange("selPayDate")} defaultValue={new Date().toDateString()} />
                </div>
                <div className="d-flex input-column mb-3">
                    <label className="form-label">{translate.Observations}</label>
                    <TextAreaBigEditor onChange={defaultFieldChange("comments")} />
                </div>
                <div className="d-flex input-column mb-3  col-md-6">
                    <label className="form-label">{translate.PaymentType}</label>
                    <Dropdown optionLabel={translate.None} onChange={defaultFieldChange("transactiontype")} items={transactionTypes.map(x => ({ value: x.Id, text: x.Value }))} />
                </div>
                {fields.map(field =>
                    <FieldEdit key={field.id} field={field} onChange={paymentFieldChange(field)} halfSize={true} personId={personId.toString()} />
                )}
            </div>

            <FloatingPanelFooter>
                <button className='btn btn-primary px-3' onClick={() => submit()}>
                    {translate.Save}
                    {isSaving === true && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>

                {hasReceipt &&
                    <>
                        <button className='btn btn-primary px-3' onClick={() => { submit(Receipt.Download); }}>
                            {translate.SaveAndDownloadReceipt}
                            {isSaving === Receipt.Download && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </button>
                        <button className='btn btn-primary px-3' onClick={() => { submit(Receipt.SendViaEmail); }}>
                            {translate.SaveAndSendReceiptEmail}
                            {isSaving === Receipt.SendViaEmail && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </button>
                    </>
                }
            </FloatingPanelFooter>

        </>
    );
};