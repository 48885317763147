import React, { PropsWithChildren, useState } from "react";

export const InboxListProvider = ({ children, reloadExtraData = undefined }: PropsWithChildren<{ reloadExtraData?: () => void }>) => {
    const [messageIds, setMessageIds] = useState<number[]>([]);
    const [messageAll, setMessageAll] = useState(false);
    const [messageCount, setMessageCount] = useState<number>(0);
    const [messageFilters, setMessageFilters] = useState<any>({ quickfilter: "", extraFilters: [], quickExtraFilter: "", iotree: "" });

    return (<InboxListContext.Provider value={{
        messageIds,
        setMessageIds,
        messageAll,
        setMessageAll,
        messageCount,
        setMessageCount,
        messageFilters,
        setMessageFilters,
        reloadExtraData: reloadExtraData ?? (() => undefined),
    }}>
        {children}
    </InboxListContext.Provider>);
};

export class InboxListContextValues {
    messageIds: number[] = [];
    messageAll = false;
    messageCount = 0;
    messageFilters: any = { quickfilter: "", extraFilters: [], quickExtraFilter: "", iotree: "" }

    setMessageIds: (ids: number[]) => void = () => { throw Error("error"); };
    setMessageAll: (all: boolean) => void = () => { throw Error("error"); };
    setMessageCount: (count: number) => void = () => { throw Error("error"); };
    setMessageFilters: (filters: any) => void = () => { throw Error("error"); };
    reloadExtraData: () => void = () => { throw Error("error"); };
}
const InboxListContext = React.createContext<InboxListContextValues>(new InboxListContextValues());
export default InboxListContext;    