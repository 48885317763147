import { default as Table, TableHeaderWithFieldId } from "../shared/Table";
import { Link, useLocation } from "react-router-dom";
import FilterContainer from "../client/components/FilterContainer";
import { TranslationService } from "../../services/TranslationService";
import moment from "moment";
import ReportHistoryIoListTableProvider from "./providers/ReportHistoryIoListTableProvider";
import ClientService, { ClientFields } from "../../services/ClientService";
import InvoiceService from "../../services/InvoiceService";
import { InvoiceHistoryListRequest } from "../client/invoice/entities/InvoiceHistoryListRequest";
import { ReportInvoiceItem } from "./ReportInvoice";
import CompanyService, { DataType } from "../../services/CompanyService";
import TableContext from "../task/TableContext";
import { useContext } from "react";
import { OptionalMap, Switch } from "../../utils/Utils";
import { InvoiceHistoryListResponse } from "../client/invoice/entities/InvoiceHistoryListResponse";
import { formatCurrency } from "../../utils/FormatUtils";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

const ReportHistoryIoList: React.FC = () => {
    const tableHeaders = getTableHeaders();
    const { search } = useLocation<{ prevRequest?: InvoiceHistoryListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);

    const fromDate = urlParams.get("from")?.replaceAll("-", "");
    const toDate = urlParams.get("to")?.replaceAll("-", "");

    const req = new InvoiceHistoryListRequest();
    req.dateFrom = fromDate || "";
    req.dateTo = toDate || "";
    req.filter = urlParams.get("filter");

    const prevRequest = req;

    const dateFrom = moment(fromDate, "YYYY-MM-DD").format("DD MMM YYYY");

    return (
        <div className="container-fluid padding">
            <h2 className="mb-3">{TranslationService.translate.DueState} {dateFrom}</h2>
            <ReportHistoryIoListTableProvider prevRequest={prevRequest} >
                <FilterContainer
                    enableImport={false}
                    hideCreate
                    additionalAction={
                        <>
                            <div className="w-auto">
                                <Link to={`/${TranslationService.currentLanguage}/report/historyclientlist?${urlParams}`}>
                                    {TranslationService.translate.ViewCustomerResume}
                                </Link>
                            </div>
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.Export}
                                    icon="fa-light fa-arrow-down-to-line"
                                    onClick={() => InvoiceService.exportHistoryIoList(req.dateFrom, req.dateTo, 2)}
                                />
                            }
                        </>} />

                <Table headers={tableHeaders} item={ReportInvoiceItem} stickyHeader>
                    <div className="card mh-100 p-0">
                        <HistoryIoTotal tableHeaders={tableHeaders} />
                    </div>
                </Table>

            </ReportHistoryIoListTableProvider>
        </div>
    );
};

function getTableHeaders() {
    const tableHeaders = InvoiceService.getTableHeaders();
    const idxStatus = tableHeaders.findIndex(x => x.fieldId === "status");
    if (idxStatus >= 0) {
        const status = { ...tableHeaders[idxStatus] };
        status.numeric = false;
        tableHeaders.splice(idxStatus, 1, status);
    }
    const clientHeaders = ClientService.getClientHeaders();

    const idMap: Record<string, number> = {
        "Name": ClientFields.Name,
        "Address": ClientFields.Address,
        "Phone": ClientFields.Phone,
    };

    const clientSortedIds = CompanyService.getPersonSortedFields().split(",");
    const sortedClientHeaders = clientSortedIds
        .map(x => clientHeaders.find(y => (idMap[y.fieldId]?.toString() ?? y.fieldId) === x))
        .filterFalsey();

    if (sortedClientHeaders.find(x => x.fieldId === "Name") === undefined) {
        sortedClientHeaders.unshift(new TableHeaderWithFieldId("client", TranslationService.translate.Client, false, true, "Name", DataType.Client, "w-250px"));
    }

    tableHeaders.unshift(...sortedClientHeaders);
    return tableHeaders;
}

const HistoryIoTotal = ({ tableHeaders }: { tableHeaders: TableHeaderWithFieldId[] }) => {
    const response = useContext(TableContext).response as InvoiceHistoryListResponse;
    return (
        <tr className={"font-weight-bold"}>
            {tableHeaders.map(x => <td key={x.fieldId} className="text-end">{
                OptionalMap(
                    Switch(
                        [x.fieldId === "amount", response.totalAmount],
                        [x.fieldId === "pending", response.totalPending],
                    ),
                    x => formatCurrency(x)
                )}</td>)}
        </tr>);
};

export default ReportHistoryIoList;