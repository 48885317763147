import { TableHeader, default as Table } from "../shared/Table";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { ClientHistoryListRequest } from "./entities/ClientHistoryListRequest";
import ReportHistoryClientListTableProvider from "./providers/ReportHistoryClientListTableProvider";
import ClientHistoryTableItem from "../client/components/ClientHistoryTableItem";
import ClientHistoryTableTotal from "../client/components/ClientHistoryTableTotal";
import { TranslationService } from "../../services/TranslationService";
import moment from "moment";
import { ClientTableContextValues } from "../client/providers/ClientTableProvider";
import ClientService from "../../services/ClientService";
import TableContext from "../task/TableContext";
import CompanyService from "../../services/CompanyService";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import { FilterCollection } from "../../services/FilterService";
import { Cast } from "../../utils/Utils";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

const ReportHistoryClientList: React.FC = () => {
    const { search } = useLocation<{ prevRequest?: ClientHistoryListRequest } | undefined>();

    const req = new ClientHistoryListRequest();
    const urlParams = new URLSearchParams(search);
    const filterQSfilter = urlParams.get("filter");
    const fromQS = urlParams.get("from")?.replaceAll("-", "");
    const toQS = urlParams.get("to")?.replaceAll("-", "");

    if (fromQS) {
        req.datefrom = fromQS;
    }
    if (toQS) {
        req.dateto = toQS;
    }
    req.filter = filterQSfilter;

    const prevRequest = req;
    const dateFrom = moment(req.datefrom, "YYYY-MM-DD").format("DD MMM YYYY");

    const tableHead: TableHeader[] = [
        new TableHeader("   ", TranslationService.translate.Client, undefined, undefined, "w-250px"),
        new TableHeader("cant", TranslationService.translate.Invoices, undefined, undefined, "w-150px"),
        new TableHeader("amount", TranslationService.translate.Amount, undefined, undefined, "w-100px"),
        new TableHeader("   ", "", undefined, undefined, "w-100px"),
    ];

    return (
        <div className="container-fluid padding">
            <h2 className="mb-3">{TranslationService.translate.DueState} {dateFrom}</h2>
            <ReportHistoryClientListTableProvider prevRequest={prevRequest} >

                <div className="genericHeader" style={{ height: 72 }}>
                    <div className="actionButtonsHeader">
                        <div className="w-auto">
                            <Link to={`/${TranslationService.currentLanguage}/report/historyiolist?${urlParams}`}>
                                {TranslationService.translate.ViewInvoicesResume}
                            </Link>
                        </div>
                        {CompanyService.canDo("export") && <ExportButton />}
                        <AdvancedFiltersButton />
                    </div>
                </div>

                <AdvancedFilters page={FilterCollection.Client} defaultValue={filterQSfilter ?? ""} />
                <div className="card mh-100 p-0">
                    <Table
                        stickyHeader={true}
                        headers={tableHead}
                        item={ClientHistoryTableItem}>
                        <ClientHistoryTableTotal />
                    </Table>
                </div>

            </ReportHistoryClientListTableProvider>
        </div>
    );
};
const ExportButton = () => {
    const tableContext = useContext(TableContext);
    const requestExport = () => {
        const { request, response } = tableContext as ClientTableContextValues;
        const req = Cast<ClientHistoryListRequest>(request);
        const exportReq: Record<string | number, string | number | null> = { ...req, dateFrom: req.datefrom, dateTo: req.dateto };
        ClientService.exportClientHistory(Cast<ClientHistoryListRequest>(exportReq), response!.itemCount);
    };
    if (CompanyService.canDo("export")) {
        return <ButtonTooltipIcon
            title={TranslationService.translate.Export}
            icon="fa-light fa-arrow-down-to-line"
            onClick={requestExport}
        />
    }
    return <></>;
};

export default ReportHistoryClientList;