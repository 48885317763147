import React, { useContext, useEffect, useRef, useState } from "react";
import TabPosition from "../shared/TabPosition";
import { TranslationService } from "../../services/TranslationService";
import Table, { TableHeader } from "../shared/Table";
import SegmentTableProvider, { SegmentTableContextValues } from "./SegmentTableProvider";
import { SegmentListResponse } from "../../entities/segment/SegmentListResponse";
import { Collapse } from "bootstrap";
import { formatCurrency, formatInteger } from "../../utils/FormatUtils";
import TableContext from "../task/TableContext";
import Tooltip from "../shared/bootstrap/Tooltip";
import FloatingPanelService from "../shared/FloatingPanel";
import EditSegment from "./EditSegment";
import Label from "../shared/form/Label";
import SegmentService from "../../services/SegmentService";
import { ToastService } from "../shared/bootstrap/Toast";
import { useHistory } from "react-router-dom";
import { FloatingPanelFooter } from "../shared/components/FloatingPanelFooter";
import ModalService from "../shared/bootstrap/Modal";
import { BehaviorSubject } from "rxjs";
import { SegmentClientListHistory } from "./SegmentClientList";
import PriorityService from "../../services/PriorityService";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import CompanyService from "../../services/CompanyService";
import TooltipComponent from "../shared/TooltipComponent";
import { FixToTop } from "../shared/FixToTop";
import SearchInput from "../shared/components/SearchInput";
import MenuButton from "../shared/components/MenuButton";
import { addIf } from "../../utils/Utils";

const SegmentList = () => {
    return (
        <TabPosition module="segment">
            <SegmentTableProvider>
                <SegmentContainer />
            </SegmentTableProvider>
        </TabPosition >
    );
};

const SegmentContainer = () => {
    const { applySearch, response, reload } = useContext(TableContext) as SegmentTableContextValues;
    const subject = useRef(new BehaviorSubject(response)).current;
    useEffect(() => {
        subject.next(response);
    }, [response, subject]);
    const openEditSegment = () => {
        FloatingPanelService.showPanel({
            children: <EditSegment folders={subject.asObservable()} reload={reload} />,
            title: TranslationService.translate.NewCustomerSegment,
            width: 1200,
            height: 700,
        });
    };

    const headers: TableHeader[] = [
        new TableHeader("segment", spaceSeparator + TranslationService.translate.Segment, false, true, "w-300px"),
        new TableHeader("client", TranslationService.translate.Clients, true, true),
        new TableHeader("io", TranslationService.translate.IO, true, true),
        new TableHeader("due", TranslationService.translate.Due, true, true),
        new TableHeader("nodue", TranslationService.translate.NoDue, true, true),
        new TableHeader("amount", TranslationService.translate.Amount, true, true),
        new TableHeader("bargraph", "", false, false)
    ];
    if (CompanyService.getSetting("enablesegmentpriority")) {
        headers.push(new TableHeader("priority", TranslationService.translate.Priority, true, true));
    }

    headers.push(new TableHeader("buttons", "", true, false));

    const ToggleHiddenSegmentsButton = () => {
        const { showHidden, toggleHidden } = useContext(TableContext) as SegmentTableContextValues;
        const canToggle = CompanyService.canDo("allowsegmentfiltertoggle") &&
            CompanyService.getHasSegments() &&
            !CompanyService.getFilterUserIdPaused();
        if (!canToggle) {
            return <></>;
        }
        return (
            <button type="button" onClick={toggleHidden} className="ms-auto btn btn-secondary">
                {showHidden ? TranslationService.translate.ShowOnlyAlwaysVisibleSegments : TranslationService.translate.ShowAllSegments}
            </button>);
    };
    const editFolder = () => FloatingPanelService.showPanel({
        children: <EditFolderPanel name={undefined} folderId={undefined} reload={reload} endpoint={SegmentService.setFolder} />,
        title: TranslationService.translate.NewFolder,
        width: 450,
        height: 280,
        position: "center",
    });

    const newActions = [
        ...addIf(CompanyService.canDo("editsegment"), { text: TranslationService.translate.NewSegment, action: openEditSegment }),
        ...addIf(CompanyService.canDo("editsegment"), { text: TranslationService.translate.NewFolder, action: editFolder }),
    ];

    return (
        <section className="segments">
            <div className="container-fluid padding ">
                <div className="card mh-100 p-0">
                    <FixToTop>
                        <div className="genericHeader">
                            <div className="w-100 ps-0">
                                <div className="col-auto ps-0">
                                    <div className="actionButtonsHeader">
                                        {CompanyService.canDo("editsegment") && <MenuButton text={TranslationService.translate.New} showTitle actions={newActions} icon="fa-light fa-plus" hideChevron />}
                                        <ToggleHiddenSegmentsButton />
                                    </div>
                                </div>
                            </div>
                            <SearchInput onSearch={applySearch} autosearch={true} />
                        </div>
                    </FixToTop>
                    <Table headers={headers} item={SegmentItem} stickyHeader={true} />
                </div>
            </div>
        </section>
    );
};


export const EditFolderPanel = (props: { name: string | undefined, folderId: number | undefined, endpoint: ((name: string, Id: number | undefined) => Promise<Error | unknown>), reload: () => void }) => {
    const [name, setName] = useState<string>(props.name ?? "");
    const submitFolder = async () => {
        if (!name || name.length === 0) {
            ToastService.showToast(TranslationService.translate.EnterFolderName);
            return;
        }
        const result = await handleErrorWithToast(props.endpoint(name, props.folderId));
        FloatingPanelService.hidePanel();
        if (!result) {
            return;
        }
        ToastService.showToast(TranslationService.translate.FolderCreatedSuccessfully, undefined, "success");
        props.reload();
    };

    return (<>
        <div className="floatingBody p-4">
            <Label label={TranslationService.translate.FolderName}>
                <input className="form-control" onChange={(e) => setName(e.target.value)} defaultValue={name} />
            </Label>
        </div>
        <FloatingPanelFooter>
            <button type="button" className="btn btn-primary" onClick={submitFolder}>{TranslationService.translate.Save}</button>
        </FloatingPanelFooter>
    </>);
};

export const EditFolderButton = ({ children, endpoint, className = "btn btn-link", folder = undefined }: React.PropsWithChildren<{ folder?: { name: string, Id: number }, className?: string, endpoint: ((name: string, Id: number | undefined) => Promise<Error | unknown>) }>) => {
    const { reload } = useContext(TableContext);
    const createFolder = () => {
        FloatingPanelService.showPanel({
            children: <EditFolderPanel name={folder?.name} folderId={folder?.Id} reload={reload} endpoint={endpoint} />,
            title: folder ? TranslationService.translate.EditFolderTitle : TranslationService.translate.NewFolder,
            width: 450,
            height: 280,
            position: "center",
        });
    };
    const IconFolder = () => <i className="fa-light fa-folder-plus text-granite-gray" style={{ fontSize: 16 }}></i>;


    return (<button type="button" className={className} onClick={createFolder}>
        {children ? children : folder?.Id ? TranslationService.translate.EditFolder : <IconFolder />}</button>);
};

const SegmentItem = ({ data }: { data: SegmentListResponse.SegmentFolder }) => {
    const [showRows, setShowRows] = useState(false);
    const history = useHistory<SegmentClientListHistory>();
    const { max, showHidden, response, reload } = useContext(TableContext) as SegmentTableContextValues;

    const deleteItem = (endpoint: (id: number) => Promise<Error | unknown>, id: number, message: string) => async () => {
        const result = await endpoint(id);
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            ToastService.showToast(message, undefined, "success");
            reload();
        }
    };
    return (
        <>
            <tr onClick={() => setShowRows(x => !x)} className={"pointer"}>
                <td valign="middle" colSpan={CompanyService.getSetting("enablesegmentpriority") ? 8 : 7} >
                    <span className="arrowDown mb-2"><i className={"fa-solid fa-fw " + (!showRows ? "fa-chevron-right" : "fa-chevron-down")}></i></span>
                    <span className="iconFolder"><i className={"fa-light fa-fw mx-2 fa-folder" + (showRows ? "-open" : "")}></i></span>
                    <span className="folderName">{data.name ?? TranslationService.translate.WithoutFolder}</span>
                </td>
                <td className="actions" >
                    {data.name && <span onClick={(e) => { e.stopPropagation(); return false; }}>
                        {CompanyService.canDo("editsegment") &&
                            <>
                                <EditFolderButton className="" folder={{ Id: data.id, name: data.name }} endpoint={SegmentService.setFolder}><TooltipComponent title={TranslationService.translate.Edit}><i className="fa-regular fa-pen px-2"></i></TooltipComponent></EditFolderButton>
                                <DeleteButtonModal onDelete={deleteItem(SegmentService.deleteFolder, data.id, TranslationService.translate.FolderDeletedSuccessfully)} message={TranslationService.translate.SureDeleteSegmentFolder}><i className="fa-regular fa-trash-can px-2 text-danger"></i></DeleteButtonModal>
                            </>
                        }
                    </span>}
                </td>
            </tr >
            {
                data.items.length === 0 &&
                <CollapsableTr show={showRows} className={"bg-warning p-2 bg-opacity-25 pointer"}>
                    <td colSpan={CompanyService.getSetting("enablesegmentpriority") ? 9 : 8}> {TranslationService.translate.EmptyFolder}</td>
                </CollapsableTr >
            }
            {
                data.items.length > 0 && data.items.map(x =>
                    <CollapsableTr key={x.SegmentID} className={"pointer"} show={showRows && (showHidden || x.AlwaysVisible)} onClick={() => history.push(`/${TranslationService.currentLanguage}/segment/details?segmentid=${x.SegmentID}`, { segment: x, folders: response })}>
                        <td>
                            {spaceSeparator}
                            <TooltipComponent title={x.Name}>
                                {x.Name}
                            </TooltipComponent>
                            {x.Error && <Tooltip title={TranslationService.translate.ErrorProcessingSegment}><i className="fa-regular fa-circle-exclamation text-danger ms-2"></i></Tooltip>}
                        </td>
                        <td className="text-end">{x.count ?? 0}</td>
                        <td className="text-end">{x.countio ?? 0}</td>
                        <td className="text-end">{formatCurrency(x.overdueAmount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}</td>
                        <td className="text-end">{formatCurrency((x.amount ?? 0) - (x.overdueAmount ?? 0), CompanyService.getDefaultCurrencyId(), formatInteger)}</td>
                        <td className="text-end">{formatCurrency((x.amount ?? 0), CompanyService.getDefaultCurrencyId(), formatInteger)}</td>
                        <td className="bar-progress">
                            <div className="bar">
                                <div className="expired" style={{ width: ((x.amount ?? 0) * 100 / max) + "%" }}></div>
                                <div className="not-expired" style={{ width: (((x.amount ?? 0) - (x.overdueAmount ?? 0)) * 100 / max) + "%" }}></div>
                            </div></td>
                        {CompanyService.getSetting("enablesegmentpriority") &&
                            <td className="fw-semibold text-end"><span className={"me-0 priority-" + PriorityService.getRankingIndex(x.Priority)}>{x.Priority}</span></td>
                        }
                        <td className="actions">
                            <span onClick={(e) => { e.stopPropagation(); return false; }}>
                                {CompanyService.canDo("editsegment") && <>
                                    <EditSegmentButton className="" id={x.SegmentID}><TooltipComponent title={TranslationService.translate.Edit}><i className="fa-regular fa-pen px-2"></i></TooltipComponent></EditSegmentButton>
                                    <DeleteButtonModal onDelete={deleteItem(SegmentService.delete, x.SegmentID, TranslationService.translate.SegmentDeletedSuccessfully)} message={TranslationService.translate.SureDeleteSegment}><i className="fa-regular fa-trash-can px-2 text-danger"></i></DeleteButtonModal>
                                </>}
                            </span>
                        </td>
                    </CollapsableTr>)
            }
        </>
    );
};

const EditSegmentButton = ({ children, id = undefined, className = "btn btn-link" }: React.PropsWithChildren<{ id?: number, className?: string }>) => {
    const { response, reload } = useContext(TableContext) as SegmentTableContextValues;
    const subject = useRef(new BehaviorSubject(response)).current;
    useEffect(() => {
        subject.next(response);
    }, [response, subject]);
    const openEditPanel = () => {
        FloatingPanelService.showPanel({
            children: <EditSegment id={id} folders={subject.asObservable()} reload={reload} />,
            title: id ? TranslationService.translate.EditCustomersSegment : TranslationService.translate.NewCustomerSegment,
            width: 1200,
            height: 700,
        });
    };
    return (<button type="button" className={className} onClick={openEditPanel}>{children}</button>);
};

export const CollapsableTr: React.FC<{ show: boolean, className?: string, onClick?: React.MouseEventHandler<HTMLTableRowElement> }> = (props) => {
    const collapsableDiv = useRef<HTMLTableRowElement>(null);
    useEffect(() => {
        if (collapsableDiv.current) {
            Collapse.getOrCreateInstance(collapsableDiv.current, { toggle: false })[props.show ? "show" : "hide"]();
            collapsableDiv.current.classList[props.show ? "add" : "remove"]("opening");
        }
    }, [props.show]);
    return (
        <tr className={"collapse" + (props.onClick ? " pointer" : "") + (props.className ? (" " + props.className) : "")} ref={collapsableDiv} onClick={props.onClick}>{props.children}</tr>
    );
};

export const DeleteButtonModal = ({ children, onDelete, message }: React.PropsWithChildren<{ onDelete: () => void, message: string }>) => {
    const openDeleteModal = () => {
        ModalService.showDefaultModal(
            {
                onAcceptClick: onDelete,
                acceptButtonLabel: TranslationService.translate.Delete,
                acceptButtonClassName: "btn btn-danger",
                message,
                title: TranslationService.translate.Delete,
            });
    };
    return (<TooltipComponent title={TranslationService.translate.Delete}><button type="button" onClick={openDeleteModal}>{children}</button></TooltipComponent>);
};
const spaceSeparator = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";
export default SegmentList;