import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ImportTemplateName } from "./Components/FieldList";
import { getDefaultTemplateModel, UpdateModelFunc } from "./ImportTemplateEditBase";
import Dropdown from "../../../shared/components/Dropdown";
import CompanyService, { DataType, Entities } from "../../../../services/CompanyService";
import InvoiceService from "../../../../services/InvoiceService";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import ImportTemplateService from "../../../../services/ImportTemplateService";
import { ToastService } from "../../../shared/bootstrap/Toast";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { RequiredManager } from "../../../shared/RequieredManager";

const ImportTemplateEditFile = ({ data, reload, importsList = undefined, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const { translate } = TranslationService;
    const [submiting, setSubmiting] = useState(false);
    const [model, setModel] = useState<Partial<ImportTemplateListResponse.Item>>(() => getDefaultTemplateModel(data, entityId));
    const requiredManager = new RequiredManager();

    const onSubmit = async () => {
        if (submiting || !requiredManager.validate()) {
            return;
        }
        setSubmiting(true);
        const result = await ImportTemplateService.set(model as ImportTemplateListResponse.Item);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ToastService.showToast(translate.ImportTemplateSaved, undefined, "success");
        setSubmiting(false);
        reload();
        FloatingPanelService.hidePanel();
    };

    const updateModel: UpdateModelFunc = <T extends keyof ImportTemplateListResponse.Item,>(key: T) => (value: ImportTemplateListResponse.Item[T]) => {
        setModel(x => ({ ...x, [key]: value }));
    };

    const onChange = useCallback((val: ImportTemplateListResponse.Column[]) => {
        updateModel("Columns")(val);
        updateModel("ColumnData")(JSON.stringify(val));
    }, []);

    return (<>
        <div className="floatingBody p-4">
            <div className="mb-3 mt-4">
                <h5 className="mb-4">{translate.SetupInvoiceImportingProcess} </h5>
                <ImportTemplateName updateModel={updateModel} data={model.Name} requiredManager={requiredManager} />
                <hr className="my-5" />
                <h6 className="mb-3">{translate.SelectTheInvoicesFieldsThatMakeUpTheFileNames}</h6>
                <FieldsItem data={model as ImportTemplateListResponse.Item} onChange={onChange} />
            </div>
        </div>
        <FloatingPanelFooter>
            <button className='btn btn-primary' onClick={onSubmit}>
                {translate.Save}
                {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>
        </FloatingPanelFooter></>);
};

const FieldsItem = ({ data, onChange }: { data: ImportTemplateListResponse.Item, onChange: (value: ImportTemplateListResponse.Column[]) => void }) => {
    const [selectedColumns, setSelectedColumns] = useState(data.Columns ?? []);
    const fields: { text: string, value: string }[] = useMemo(() => {
        const fields = [];
        fields.push(...CompanyService.getAdditionalDefinitions().filter(x => x.type === DataType.Text && x.Entity === Entities.Invoice).map(x => ({ text: x.Name, value: x.AdditionalDefinitionID.toString() })));
        fields.unshift({
            text: InvoiceService.getReferenceNumberName(),
            value: "0",
        });
        fields.push({
            text: TranslationService.translate.Freetext,
            value: "-1",
        });
        return fields;
    }, []);

    const notSelectedFields = useMemo(() => {
        const selectedIds = selectedColumns.map(x => x.ix);
        return fields.filter(x => !selectedIds.includes(x.value));
    }, [fields, selectedColumns]);

    const onSelectedItem = (index: number) => (value?: string) => {
        setSelectedColumns(x => {
            const copy = [...x];
            copy[index].ix = value!;
            return copy;
        });
    };
    useEffect(() => {
        onChange(selectedColumns.filter(x => x.ix !== undefined && x.ix !== "").map((x, i) => ({ ...x, name: (i + 1).toString() })));
    }, [onChange, selectedColumns]);

    return (
        <>
            <div className="mb-3 inline-error ">
                <label className="w-lg col-form-label">{TranslationService.translate.Fields}</label>
                <div className="row">
                    <div className="col d-flex flex-column gap-3">
                        {selectedColumns.map((x, i) => (
                            <div className="w-sm" key={i}>
                                <Dropdown onChange={onSelectedItem(i)} items={[fields.find(y => y.value === x.ix)!, ...notSelectedFields].filter(x => x)} optionLabel={TranslationService.translate.Select} defaultValue={x.ix} />
                                {x.ix === "-1" && <input className="form-control" onChange={e => setSelectedColumns(x => { const copy = [...x]; copy.find(y => y.ix === "-1")!.pattern = e.target.value!; return copy; })} defaultValue={x.pattern} />}
                            </div>
                        ))}
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => setSelectedColumns(x => [...x, { ix: "", name: "", pattern: "" }])}>{TranslationService.translate.CombineWithMoreColumns}</button>
                    </div>
                </div>
            </div></>);
    //   {<div className="w-sm" key={i}><TextEditor onChange={setTextValue(i)} defaultValue={values[i]} /></div>}

};

export default ImportTemplateEditFile;