
import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import TooltipComponent from "../../shared/TooltipComponent";
import { Client } from "../entities/ClientGetResponse";

export const DashboardExceededLimit = ({ client }: { client: Client | null | undefined }) => {
    const { translate } = TranslationService;
    const currencyId = CompanyService.getDefaultCurrencyId();
    const creditLimit = client?.CreditLimit ?? 0;
    const amountSpent = (client?.amount ?? 0);
    const creditAvailable = creditLimit - amountSpent;
    const creditAvailablePerc = Math.abs(creditAvailable * 100 / (creditLimit === 0 ? 1 : creditLimit));
    const hasCredit = creditAvailable > 0;
    return (
        <div className="dashboard__sales card-dashboard py-3">
            <div className={!hasCredit ? "col-4" : "col-3"}>
                <div className="dashboard__total">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.CreditLimit}</p>
                        </div>
                        <div className="d-flex gap-2 align-items-center mt-1">
                            <h2>{formatCurrency(creditLimit ?? 0, currencyId, formatInteger)}</h2>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            {hasCredit ?
                <div className="col-3">
                    <div className={`dashboard__${creditAvailable > 0 ? "notExpired" : "expired"}`} >
                        <div className="card-body px-2" >
                            <div className="card-header mb-1">
                                <p>{translate.Used}</p>
                            </div>
                            <div className="d-flex gap-2 align-items-center mt-1" style={{ flexWrap: "wrap" }}>
                                <h2 className={creditAvailable > 0 ? "creditAvailableBlack" : "creditAvailableRed"}>
                                    {formatCurrency(Math.abs(amountSpent), CompanyService.getDefaultCurrencyId())}</h2>
                                {creditAvailable > 0 ?
                                    <span>{formatInteger(Math.abs((amountSpent * 100) / creditLimit))}%</span> :
                                    <span className="excededCredit">{formatInteger(Math.abs((amountSpent * 100) / creditLimit))}%</span>}
                            </div>
                        </div>
                    </div>
                </div> : null}
            <div className={!hasCredit ? "col-4" : "col-3"}>
                <div className={`dashboard__${creditAvailable > 0 ? "notExpired" : "expired"}`} >
                    <div className="card-body px-2" >
                        <div className="card-header mb-1">
                            {creditAvailable > 0 ?
                                <p>{translate.CreditAvailable}</p> :
                                <p>{translate.CreditExceeded}</p>}
                        </div>
                        <div className="d-flex gap-2 align-items-center mt-1" style={{ flexWrap: "wrap" }}>
                            <h2 className={creditAvailable > 0 ? "creditAvailableBlack" : "creditAvailableRed"}>{formatCurrency(Math.abs(creditAvailable), CompanyService.getDefaultCurrencyId())}</h2>
                            {creditAvailable > 0 ?
                                <span className="okCredit">{formatInteger(creditAvailablePerc)}%</span> :
                                <span className="excededCredit">{formatInteger(creditAvailablePerc)}%</span>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={!hasCredit ? "col-4 center" : "col-3 center"} style={{ alignSelf: "center" }}>
                <div className="dashboard__notExpired">
                    <div className="card-body px-2 ">
                        <div className="card-header dashboard-used mb-3">
                            {creditAvailable > 0 ?
                                <p className="creditAvailableBlue">{translate.Used} {formatInteger(Math.abs((amountSpent * 100) / creditLimit))}%</p> :
                                <p className="creditAvailableRed">{translate.Used} {formatInteger(Math.abs((amountSpent * 100) / creditLimit))}%</p>}
                            {creditAvailable > 0 ?
                                <p className="creditAvailableGreen">{translate.CreditAvailable} {formatInteger(creditAvailablePerc)}%</p> :
                                <p className="creditAvailableRed">{translate.CreditExceeded} {formatInteger(creditAvailablePerc)}%</p>}
                        </div>
                        <div className="d-flex gap-2 align-items-center mt-2">
                            {creditLimit &&
                                <tr className="tablePriority ">
                                    <td className="bar-progress bar-progress-w">
                                        <div className="bar big-bar">
                                            {creditAvailable > 0 ?
                                                <TooltipComponent
                                                    title={<div>
                                                        <div className="text-primary">{TranslationService.translate.Credit}: <b>{formatCurrency(amountSpent)}</b></div>
                                                        <div>{TranslationService.translate.CreditAvailable}: <b>{formatCurrency(Math.abs(creditAvailable))} ({formatInteger(creditAvailablePerc) + "%"})</b></div>
                                                    </div>}>
                                                    <div
                                                        className={"not-expired big-bar"}
                                                        style={{ width: (formatInteger(100 - creditAvailablePerc)) + "%" }}
                                                    />
                                                </TooltipComponent>
                                                :
                                                <TooltipComponent
                                                    title={<div>
                                                        <div className="text-primary">{TranslationService.translate.Credit}: <b>{formatCurrency(amountSpent)}</b></div>
                                                        <div className="text-danger">{TranslationService.translate.CreditExceeded}: <b>{formatCurrency(Math.abs(creditAvailable))} ({formatInteger(creditAvailablePerc) + "%"})</b></div>
                                                    </div>}>
                                                    <div className="bar-container-bk">
                                                        <div className="not-expired-bk big-bar"
                                                            style={{ width: ((creditLimit / amountSpent) * 100) + "%" }} />
                                                        <div className="expired-bar-bk big-bar"
                                                            style={{ width: ((Math.abs(creditAvailable) / amountSpent) * 100) + "%" }} />
                                                    </div>
                                                </TooltipComponent>
                                            }
                                        </div>

                                    </td>
                                </tr>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardExceededLimit;