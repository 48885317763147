import { ActionDetailResponse } from "../pages/action/entities/ActionDetailResponse";
import { ActionGetResponse, ActionRun } from "../pages/action/entities/ActionGetResponse";
import { ActionListResponse } from "../pages/action/entities/ActionListResponse";
import RequestService from "./RequestService";

class ActionService {
    public static list() {
        return RequestService.post<ActionListResponse>("/action/list", {});
    }
    public static togglePause(disabled: boolean): Promise<{ disabled: boolean } | Error> {
        return RequestService.post("/action/switchglobal", { disabled });
    }
    public static setFolder(name: string, id: number = 0): Promise<unknown | Error> {
        return RequestService.post("/action/setfolder", { name, id });
    }
    public static deleteFolder(id: number): Promise<Error | unknown> {
        return RequestService.post("/action/deleteFolder", { id });
    }
    public static switchAction(id: number, disabled: boolean): Promise<unknown | Error> {
        return RequestService.post("/action/switch", { disabled, id });
    }
    public static get(id: number): Promise<ActionGetResponse | Error> {
        return RequestService.post("/action/get", { id });
    }
    public static set(action: ActionGetResponse.Action): Promise<ActionGetResponse | Error> {
        return RequestService.post("/action/set", action);
    }
    public static delete(id: number): Promise<ActionGetResponse | Error> {
        return RequestService.post("/action/delete", { id });
    }
    public static clone(id: number, count: number): Promise<ActionGetResponse | Error> {
        return RequestService.post("/action/clone", { id, count });
    }
    public static details(id: number, page: number): Promise<ActionDetailResponse | Error> {
        return RequestService.post("/action/details", { id, page, pageSize: 4 });
    }
    public static run(actionRun: ActionRun): Promise<unknown | Error> {
        return RequestService.post("/action/run", actionRun);
    }
}

export enum ActionType {
    MailSend,
    ActionTask,
    ActionSMSSend,
    ActionSendList,
    ActionSendIOList,
    ActionSendLetter,
    // Multiple,
    ActionSendWhatsApp,
}

export default ActionService;