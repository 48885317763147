import ClientTableProvider from "./providers/ClientTableProvider";
import { TableHeader, default as Table } from "../shared/Table";
import TabPosition from "../shared/TabPosition";
import ClientTableItem from "./components/ClientTableItem";
import FilterContainer from "./components/FilterContainer";
import ClientTableTotal from "./components/ClientTableTotal";
import { DeleteAllClients } from "./components/DeleteAllClients";
import { useLocation } from "react-router-dom";
import { ClientListRequest } from "./entities/ClientListRequest";
import { Switch } from "../../utils/Utils";
import CompanyService from "../../services/CompanyService";
import { FixToTop } from "../shared/FixToTop";
import { TranslationService } from "../../services/TranslationService";

const ClientList: React.FC = () => {
    const { state, search } = useLocation<{ prevRequest?: ClientListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);
    const prevRequest = Switch(
        [Boolean(state?.prevRequest), state?.prevRequest],
        [Boolean(urlParams.get("asid")), () => { const req = new ClientListRequest(); req.actionsentid = urlParams.get("asid"); req.reason = urlParams.get("reason"); return req; }],
        [Boolean(urlParams.get("filter")), () => { const req = new ClientListRequest(); req.filter = urlParams.get("filter"); return req; }],
    );
    const tableHead: TableHeader[] = [
        new TableHeader("name", TranslationService.translate.Client, false, true, "w-300px"),
        new TableHeader("cant", TranslationService.translate.Invoices, true, true),
        new TableHeader("amount", TranslationService.translate.Amount, true, true),
        new TableHeader("due", TranslationService.translate.Due + '/' + TranslationService.translate.NoDue, false, true),
        new TableHeader("lastlog", TranslationService.translate.LastActivity, false, true, "w-200px"),
    ];
    if (!CompanyService.getCompanyAuth()?.HidePriority) {
        tableHead.push(new TableHeader("rank", TranslationService.translate.Priority, true, true, "", TranslationService.translate.PriorityTip));
    }

    return (
        <TabPosition module="client">
            <div className="container-fluid padding">
                <div className="card mh-100 p-0">
                    <ClientTableProvider prevRequest={prevRequest} >
                        <FixToTop>
                            <FilterContainer showActions />
                        </FixToTop>
                        <Table headers={tableHead} item={ClientTableItem} stickyHeader={true}>
                            <ClientTableTotal />
                        </Table>
                        <DeleteAllClients />
                    </ClientTableProvider>
                </div>
            </div>
        </TabPosition>
    );
};

export default ClientList;
