
import { TranslationService } from "../../../services/TranslationService";
import { CheckBoxEditor } from "../../shared/components/Editors";
import { Field } from "../../action/Edit/components/FieldHelpers";
import Dropdown from "../../shared/components/Dropdown";
import { Switch } from "../../../utils/Utils";
import { useMemo, useState } from "react";
import { toEvent } from "../../../utils/FuncUtils";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { smsName, SmsType } from "./SMSConfiguration";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";

const SMSConfigurationEdit = ({data: originalConfig, onSubmit}: {data: SmsConfigurationEditModel, onSubmit?: () => Promise<unknown>}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState<SmsConfigurationEditModel>(originalConfig);
    const type = useMemo(() => parseSmsType(data.smsmethod), [data]);
    const changeData = <T extends keyof typeof data,>(key: T) => (value: (typeof data)[T]) => setData(x => ({ ...x, [key]: value }));
    const setValueProps = { type, changeData, data };
    const submit = async () => {
        setIsSaving(true);
        await handleErrorWithToast(CompanyService.setSmsConfig(data), () => {
            setIsSaving(false);
        });
        onSubmit && await onSubmit();
        FloatingPanelService.hidePanel();
    };
    return (
        <>
            <div className="floatingBody p-4 pb-0">
                <h5>{TranslationService.translate.SMSSetup}</h5>
                <div className="row my-4">
                    <div className="col-12 ">
                        <div className="mb-3">
                            <Field title={TranslationService.translate.SendMethod}>
                                <Dropdown onChange={changeData("smsmethod")} items={Object.keys(smsName).map(x => x as SmsType).map(x => ({ text: smsName[x], value: x }))} defaultValue={data.smsmethod} />
                            </Field>
                        </div>
                        <ConceptoMovilInfo {...setValueProps} />
                        <SetValueRegister {...setValueProps} />
                        <SetValueTextbox {...setValueProps} valueKey="idcuenta" name="ID Cuenta" />
                        <SetValueTextbox {...setValueProps} valueKey="apikey" name="Api Key" />
                        <SetValueTextbox {...setValueProps} valueKey="apisecret" name="Api Secret" />
                        <SetValueTextbox {...setValueProps} valueKey="cliente" name="Cliente" />
                        <SetValueTextbox {...setValueProps} valueKey="apitoken" name="Token" />
                        <SetValueTextbox {...setValueProps} valueKey="username" name="Usuario" />
                        <SetValueTextbox {...setValueProps} valueKey="password" name="Contraseña" password />
                        <TwilioInfo {...setValueProps} />
                        <SetValueTextbox {...setValueProps} valueKey="domainid" name="DomainId" />
                        <SetValueTextbox {...setValueProps} valueKey="senderid" name="SenderId" />
                        <SetValueTextbox {...setValueProps} valueKey="prefix" name={TranslationService.translate.Prefix} />
                    </div>
                </div>
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary btn-sm" onClick={submit} disabled={isSaving}>
                    {TranslationService.translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

function parseSmsType(value: string): SmsType {
    return Switch(
        [value === "SMSTigoBusiness", "SMSTigoBusinessGuatemala"],
        [value === "SMSTigo", "SMSTigoGuatemala"],
    ) ?? value as SmsType;
}

export type SmsConfigurationEditModel = {
    smsmethod: string,
    register: "0" | "1",
    idcuenta: string,
    apikey: string,
    apisecret: string,
    cliente: string,
    apitoken: string,
    username: string,
    password: string,
    number: string,
    sid: string,
    token: string,
    api_key: string,
    api_secret: string,
    domainid: string,
    senderid: string,
    prefix: string,
}

type EditProps = {
    type: SmsType,
    changeData: <T extends keyof SmsConfigurationEditModel>(key: T) => (value: SmsConfigurationEditModel[T]) => void,
    data: SmsConfigurationEditModel,
}

const ConceptoMovilInfo = ({ type }: EditProps) => {
    if (type !== "SMSConceptoMovil") return <></>;
    return (
        <>
            <p>Para enviar SMS automáticos, deberás crear una cuenta en <a href="https://www.conceptomovil.com/" target="_blank" rel="noreferrer">Concepto Móvil</a>.</p>
            <br />
        </>
    );
};

const TwilioInfo = (props: EditProps) => {
    if (props.type !== "Twilio") {
        return <></>;
    }
    return (
        <>
            <p>{TranslationService.translate.TwilioHelp1}<a href="http://www.twilio.com/" target="_blank" rel="noreferrer">Twilio</a>.</p>
            <p>{TranslationService.translate.TwilioHelp2}</p>
            <br />
            <SetValueTextbox {...props} valueKey="number" name={TranslationService.translate.Number} />
            <SetValueTextbox {...props} valueKey="sid" name="ACCOUNT SID" password />
            <div style={{ border: "1px solid black", borderRadius: 8, padding: 10, paddingBottom: 4, marginBottom: 8 }}>
                <SetValueTextbox {...props} valueKey="token" name="TOKEN" password />
            </div>
            <div className="d-flex justify-content-center mb-2">{TranslationService.translate.Or}</div>
            <div style={{ border: "1px solid black", borderRadius: 8, padding: 10, paddingBottom: 4, marginBottom: 8 }}>
                <SetValueTextbox {...props} valueKey="api_key" name="API KEY" password />
                <SetValueTextbox {...props} valueKey="api_secret" name="API SECRET" password />
            </div>
        </>
    );
};

const SetValueRegister = ({ type, changeData, data }: EditProps) => {
    if (type !== "SMSManual") return <></>;
    return (
        <CheckBoxEditor message={TranslationService.translate.KeepARecordInTheCustomersActivityLog} onChange={x => changeData("register")(x ? "1" : "0")}  defaultValue={data.register === "1"}/>
    );
};

type StringKeys<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

const SetValueTextbox = <T extends StringKeys<SmsConfigurationEditModel>,>({ data, type, valueKey, changeData, name, password = false }: EditProps & { valueKey: T, name: string, password?: boolean }) => {
    const shouldShow: Record<keyof SmsConfigurationEditModel, boolean | undefined> = {
        smsmethod: false,
        register: false,
        idcuenta: ["SMSConnectUs", "SMSTigoBusinessGuatemala", "SMSWhaticket"].includes(type),
        apikey: ["SMSTigo", "SMSConceptoMovil", "SMSHablame", "SMSConnectUs", "SMSTigoBusinessGuatemala", "SMSQuiubas", "SMSTigoBusinessParaguay"].includes(type),
        apisecret: ["SMSTigoBusinessGuatemala", "SMSQuiubas"].includes(type),
        cliente: type === "SMSHablame",
        apitoken: ["SMSHablame", "SMSConceptoMovil", "SMSWhaticket"].includes(type),
        username: ["SMSMasiv", "SMSInteligente", "SMSMasivos", "SMSBox", "SMSDiloo", "SMSAltiria",
            "SMSITDChile", "SMSFacil", "SMSInfobip", "SMSTeleprom", "SMSTigoBusinessGuatemala", "SMSIntico"].includes(type),
        password: ["SMSMasiv", "SMSInteligente", "SMSMasivos", "SMSBox", "SMSDiloo", "SMSAltiria", "SMSITDChile", "SMSFacil", "SMSInfobip", "SMSTeleprom", "SMSTigoBusinessGuatemala", "SMSIntico"].includes(type),
        senderid: ["SMSAltiria", "SMSIntico"].includes(type),
        prefix: ["SMSMasiv", "SMSInteligente", "SMSAltiria", "Twilio", "SMSConnectUs", "SMSHablame", "SMSTeleprom", "SMSTigoBusinessParaguay"].includes(type),
        number: type === "Twilio",
        sid: type === "Twilio",
        token: type === "Twilio",
        api_key: type === "Twilio",
        api_secret: type === "Twilio",
        domainid: type === "SMSAltiria",
    };
    if (!shouldShow[valueKey]) {
        return <></>;
    }
    return (
        <Field title={name} colClass="col-12">
            <input className="form-control w-100" onChange={toEvent(changeData(valueKey as "sid"))} type={password ? "password" : "text"} defaultValue={data[valueKey]} />
        </Field>);
};

export default SMSConfigurationEdit;