import { useContext, useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import ButtonSecondary from '../../account/components/ButtonSecondary';
import FileService from '../../../services/FileService';
import ToastContext from '../../shared/bootstrap/Toast';
import * as yup from "yup";
import CompanyService from '../../../services/CompanyService';
import ConfigurationContext from '../ConfigurationContext';
import Loading from '../../shared/components/Loading';
import ModalService from '../../shared/bootstrap/Modal';

type ReceiptTemplateConfigurationType = {
    receiptTemplate: any,
    receiptNumber: string
}

const TYPES_DOC = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

export const ReceiptTemplateConfiguration = ({ receiptTemplate, receiptNumber }: ReceiptTemplateConfigurationType) => {
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [fileUploaded, setFileUploaded] = useState<File>()
    const [formData, setFormData] = useState({ receiptTemplate, receiptNumber })
    const [numberHasChanged, setNumberHasChanged] = useState(false)

    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const { refreshConfigValues } = useContext(ConfigurationContext);

    const [saveData, setSaveData] = useState(false)
    const [modifyTemplate, setModifyTemplate] = useState(false)

    const hasReceipt = (CompanyService.canDo("hasReceipt") && CompanyService.canShowReceipt());

    const formDataSchema = yup.object({
        receiptTemplate: yup.string().required(translate.ReceiptTemplateIsRequired),
        receiptNumber: yup.number().min(1, translate.ReceiptNumberGreaterThanZero).required(translate.ReceiptNumberRequired),
    });

    const saveValues = () => {
        setSaveData(false)
        setModifyTemplate(false)
        uploadFile()
        updateReceiptTemplateNumber()
    }

    const updateReceiptTemplateNumber = async () => {
        await CompanyService.setConfigCompany({
            'receiptNumber': numberHasChanged ?
                formData.receiptNumber :
                hasReceipt ?
                    formData.receiptNumber :
                    (parseInt(formData.receiptNumber) + 1).toString()
        })
            .then(() => !numberHasChanged && setFormData((prevState) => ({ ...prevState, 'receiptNumber': (parseInt(prevState.receiptNumber) + 1).toString() })))
            .finally(async () => {
                await refreshConfigValues()
                setNumberHasChanged(false)
            })
    }

    const uploadFile = async () => {
        try {
            setLoading(true)
            if (fileUploaded) {
                const result = await FileService.upload(fileUploaded)
                if (result instanceof Error) {
                    showToast(translate.ErrorProcessingRequest, undefined, "danger");
                    return result;
                }
                await FileService.uploadReceiptTemplate(result.id)
                    .finally(() => setLoading(false))
            }
        } catch (error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setLoading(false)
        }
    }

    const handleFileInputChange = ({ target }: any) => {
        const file: File = target.files[0]
        if (TYPES_DOC.includes(file?.type)) {
            setFormData((prevState) => ({
                ...prevState,
                [target.name]: file.name,
            }));
            setFileUploaded(file)
        } else {
            setFormData((prevState) => ({
                ...prevState,
                'receiptTemplate': '',
            }));
            setFileUploaded(undefined)
            openModalDocType()
        }
    }


    const handleInputChange = ({ target }: any) => {
        setNumberHasChanged(true)
        setFormData((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    }

    const clearFileUploaded = () => {
        setLoading(true)
        FileService.deleteReceiptTemplate()
            .then(async () => await refreshConfigValues())
            .finally(() => setLoading(false))
    }

    const openModalDelete = () => ModalService.showDefaultModal(
        {
            onAcceptClick: clearFileUploaded,
            title: TranslationService.translate.Delete + ' ' + TranslationService.translate.ReceiptTemplate,
            message: TranslationService.translate.ConfirmDeleteFile,
            acceptButtonLabel: TranslationService.translate.Delete,
            acceptButtonClassName: "btn btn-danger",
            closeButtonLabel: TranslationService.translate.Cancel,
        });

    const openModalDocType = () => ModalService.showDefaultModal({ message: TranslationService.translate.WarningTheFileHasToBeDOCType });


    const handleDownloadTemplate = async () => {
        const result = await FileService.downloadReceiptTemplate()
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return result;
        }
    }

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return loading ? <Loading /> : (
        <div className="col-md-8 m-2">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                {hasReceipt ?
                    !modifyTemplate &&
                    <div className="col input-group">
                        <div className='col' onClick={handleDownloadTemplate}>
                            {translate.ReceiptTemplate} <i className="far fa-download ms-1"></i>
                        </div>
                    </div> : null}
            </div>
            <div className='d-flex justify-content-between align-items-center gap-2 mb-3'>
                {modifyTemplate ?
                    <>
                        <div className="col align-items-center input-group">
                            <div className="mx-2">{translate.CurrentReceiptNumber}</div>
                            <input
                                name='receiptNumber'
                                onChange={handleInputChange}
                                type="number"
                                className="form-control form-control-sm"
                                defaultValue={formData.receiptNumber}
                                aria-describedby="basic-addon1" />
                        </div>
                        <div className="col align-items-center input-group">
                            <input type="file" className="form-control" id="receiptTemplate" name='receiptTemplate' onChange={handleFileInputChange} />
                        </div>
                    </> :
                    <>
                        {hasReceipt ?
                            <div className="col">{translate.CurrentReceiptNumber}: {formData.receiptNumber}</div> :
                            <div className="col">{translate.NoReceiptTemplatesToShow}</div>}
                        <div className="d-flex gap-2">
                            {hasReceipt && <ButtonSecondary onClick={openModalDelete}>{translate.Delete + ' ' + translate.ReceiptTemplate}</ButtonSecondary>}
                            <ButtonPrimary onClick={() => setModifyTemplate(true)}>{hasReceipt ? translate.ModifyData : translate.Add}</ButtonPrimary>
                        </div>
                    </>}
            </div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                {modifyTemplate && <>
                    <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", margin: "10px", }}>
                        {error}
                    </div>
                    <div className='d-flex gap-1'>
                        {saveData && !error && <ButtonPrimary className='ml-auto p-2' onClick={saveValues}>{translate.Save}</ButtonPrimary>}
                        <ButtonSecondary onClick={() => setModifyTemplate(false)}>{translate.Cancel}</ButtonSecondary>
                    </div>
                </>}
            </div>
        </div>
    )
}
