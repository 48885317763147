import { forwardRef, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ActivityService from "../../services/ActivityService";
import ClientService from "../../services/ClientService";
import CompanyService from "../../services/CompanyService";
import { FilterCollection } from "../../services/FilterService";
import { TranslationService } from "../../services/TranslationService";
import { Switch } from "../../utils/Utils";
import ActivityTableProvider, { ActivityTableContextValues } from "../client/activity/ActivityTableProvider";
import { ActivityListResponse } from "../client/activity/entities/AcitivityListResponse";
import { InvoiceList } from "../client/invoice/entities/InvoiceListResponse";
import { TableColumn } from "../client/invoice/InvoiceTableItem";
import ToastContext from "../shared/bootstrap/Toast";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";
import Table from "../shared/Table";
import { TaskStatus } from "../task/entities/TaskStatus";
import TableContext from "../task/TableContext";
import imgWhatsAppIco from "../../assets/img/ico-whatsapp.png";
import { ReportScheduledEditItem } from "./ReportScheduledEditItem";
import FloatingPanelService from "../shared/FloatingPanel";

const ReportActivity = () => {
    const filterQS = new URLSearchParams(useLocation().search).get("filter") ?? "";
    return (
        <ActivityTableProvider pageSize={25} defaultFilter={filterQS}>
            <ReportActivityList />
        </ActivityTableProvider>
    );
};

const ExportButton = ({ exportRequestData }: any) => {
    const exportInvoices = async (reportId: number | null) => {
        await exportRequestData(reportId ?? undefined);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === 0)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader">
        {CompanyService.canDo("export") && <MenuButton text={TranslationService.translate.Export} actions={exportItems} extraClassName="select-intiza-outline" icon="fa-light fa-arrow-down-to-line pe-1" hideChevron />}
    </div>);
};

const ReportActivityList = () => {
    const filterQS = new URLSearchParams(useLocation().search).get("filter") ?? "";

    const tableHeaders = getTableHeaders();
    const { translate } = TranslationService;
    const { applySearch, exportRequestData } = useContext(TableContext) as ActivityTableContextValues;

    const typeValues = [
        { text: translate.AllActivities, value: 0 },
        { text: translate.OnlyEmails, value: 1 },
        { text: translate.OnlyComments, value: 2 },
        { text: translate.OnlyTasks, value: 3 },
        { text: translate.OnlySMS, value: 4 }];

    if (CompanyService.canDo("newwhatsapp") && CompanyService.canShowWhatsApp()) {
        typeValues.push({ text: translate.OnlyWhatsApp, value: 6 });
    }

    const openProgramModal = () => {
        FloatingPanelService.showPanel({
            children: <ReportScheduledEditItem defaultReportId={0} />,
            title: TranslationService.translate.ScheduledReports,
        });
    };
    return (
        <div className="container-fluid padding">
            <div className="d-flex justify-content-between">
                <h2>{TranslationService.translate.ActivityList}</h2>
            </div>
            <div className="mh-100 p-0">
                <div className="genericHeader">
                    <div className="searcherFilterHeader align-items-center">
                        <ExportButton exportRequestData={exportRequestData} />
                        <AdvancedFiltersButton />
                        <Dropdown quickFilter items={typeValues} onChange={x => applySearch(x.toString())}></Dropdown>
                    </div>
                    <div className="d-flex align-content-center gap-3">
                        {CompanyService.getSetting("allowscheduledreports") && <>
                            <Link to={`/${TranslationService.currentLanguage}/report/scheduled`} className="align-content-center">
                                {TranslationService.translate.ViewScheduledReports}
                            </Link>
                            <button onClick={openProgramModal} className="btn btn-primary">
                                {TranslationService.translate.Program}
                            </button>
                        </>}

                    </div>
                </div>
                <div className="pt-0">
                    <AdvancedFilters defaultValue={filterQS} page={FilterCollection.ReportActivity} />
                </div>
                <Table stickyHeader={true} limitSize headers={tableHeaders} item={ReportActivityItem}></Table>
            </div>
        </div>
    );
};

export const ActivityContent = forwardRef<HTMLTableCellElement, { item: ActivityListResponse.Item }>(function ActivityContentImpl({ item: x }, ref) {
    return (<div ref={ref}>
        {x.typeName === "Email" ? EmailDisplay({ item: x }) : x.typeName === "Comment" ? <span dangerouslySetInnerHTML={{ __html: x.Content }}></span> : x.typeName === "SMS" ? <span>
            <b>{x.smsphone}:</b>{x.Content}</span> : x.Content}
    </div>);
});

export const ActivityTypeName = (x: ActivityListResponse.Item) => {
    switch (x.typeName) {
        case "Comment":
            return (<td>
                <i className="fa fa-comment-alt-lines"></i> {TranslationService.translate.Comment}
            </td>);
        case "Email":
            return (<td>
                <i className="fa fa-envelope"></i> {TranslationService.translate.Email}
            </td>);
        case "SMS":
            return (<td>
                <i className={"fa fa-sms" + (x.Status === -999 ? " text-danger" : "")}></i> {TranslationService.translate.SMS}
            </td>);
        case "Task":
            return (<td>
                <i className={"fa fa-fw " + Switch(
                    [x.Status === TaskStatus.Validated, "fa-check-double"],
                    [x.Status === TaskStatus.Fulfilled, "fa-check"]) ?? ""}></i> {TranslationService.translate.Task}
            </td>);
        case "Whatsapp":
            return (<td>
                <div className="icon">
                    <img className="img-fluid" src={imgWhatsAppIco} alt="icon comment" />
                </div>
            </td>);
        default:
            return (<td></td>);
    }
};

const ReportActivityItem = ({ data }: { data: ActivityListResponse.Item }) => {
    const { translate, currentLanguage } = TranslationService;
    const tableHeaders = getTableHeaders();
    const specialCases = {
        "Name": (x: ActivityListResponse.Item) => (<td className="is-link">
            <span className="w-md">
                <Link to={`/${currentLanguage}/client/details?id=${data.PersonId}`}>
                    {x.personName}
                </Link>
            </span>
        </td>),
        "ActivityTypeID": (x: ActivityListResponse.Item) => (<td>
            {CompanyService.getActivityTypes().find(y => y.ActivityTypeID === x.ActivityTypeID)?.ActivityTypeName}
        </td>),
        "Content": (x: ActivityListResponse.Item) => <td><ActivityContent item={x} /></td>,
        "hasAttachments": (x: ActivityListResponse.Item) => (<td>
            {x.hasAttachments ? translate.Yes : translate.No}
        </td>),
        "typeName": ActivityTypeName,
    };
    return (<tr key={data.ID}>
        {tableHeaders.map(header => <TableColumn key={header.fieldId} data={data as unknown as InvoiceList.Item} header={header} specialCases={specialCases} />)}
    </tr>);
};

function getTableHeaders() {
    const clientFields = ClientService.getClientHeaders();
    clientFields.forEach(x => x.sorteable = false);
    const activtyFieldsStart = ActivityService.getActivityHeadersStart();
    const activtyFieldsEnd = ActivityService.getActivityHeadersEnd();
    return [...activtyFieldsStart, ...clientFields, ...activtyFieldsEnd];
}

const EmailDisplay = ({ item }: { item: ActivityListResponse.Item }) => {
    const [mailText, setMailText] = useState<string>();
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const onReadMoreClicked = async (id: number) => {
        const result = await ActivityService.getEmailContent({ id: id.toString(), noheader: "0" });
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        setMailText(result.content);
    };

    return (
        <>
            {!mailText &&
                <> <div><b>{translate.From}:</b> {item.From}</div>
                    <div><b>{translate.To}:</b> {item.To}</div>
                    <div className="mb-2"><b>{translate.Subject}:</b> {item.Subject}</div>
                </>
            }
            <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: mailText ? mailText : item.Content }} />

            {!mailText && item.HasMoreContent && <button className="btn btn-link text-primary pl-0" onClick={() => onReadMoreClicked(item.ID)}>{translate.ReadMore}</button>}
        </>
    );
};


export default ReportActivity;

