import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { TranslationService } from '../../services/TranslationService';
import PortalClientService from '../../services/PortalClientService';
import Loading from './components/Loading';

export const PortalSiteHashValidator = () => {
    const history = useHistory();
    const hashToken = new URLSearchParams(history.location.search).get("h") ?? "";

    useEffect(() => {
        const validateHashToken = async (token: string) => {
            const result = await PortalClientService.getTokenByHash({ hash: hashToken });
            if (result instanceof Error) {
                window.location.href = `/${TranslationService.currentLanguage}/logon`;
                return;
            } else {
                window.location.href = `${window.location.origin}/${TranslationService.currentLanguage}/portalclient?t=` + (result?.data.token || hashToken || 'notoken');
                return;
            }
        };
        validateHashToken(hashToken)
    }, [hashToken])

    return (
        <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh', minHeight: '100%' }}>
            <Loading />
        </div>
    )
}
