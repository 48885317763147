import { ChangeEvent } from "react";

export function negate(func: (val: boolean) => void) {
    return (val: boolean) => func(!val);
}

const throttledCache: [() => unknown, Date, unknown][] = [];
export function throttleCached<TFunc extends (...params: unknown[]) => unknown>(func: TFunc, ...params: Parameters<TFunc>): ReturnType<TFunc> {
    let val = throttledCache.find(x => x[0] === func);
    if (val === undefined) {
        val = [func, new Date(), func(...params)];
        throttledCache.push(val);
    } else if ((Date.now() - val[1].getTime()) > 500) {
        val[1] = new Date();
        val[2] = func(...params);
    }
    return val[2] as ReturnType<TFunc>;
}

export function toEvent(onChange: (value: string) => void) {
    return (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
}