import React, { useCallback, useState } from 'react'
import ButtonPrimary from '../../account/components/ButtonPrimary'
import { TranslationService } from '../../../services/TranslationService'
import CompanyService from '../../../services/CompanyService'

type TextLinkPortalType = {
    linkPortal: string
}

export const TextLinkPortal = ({ linkPortal }: TextLinkPortalType) => {
    const [saveData, setSaveData] = useState(false)
    const [dataText, setDataText] = useState({ linkPortal })

    const { translate } = TranslationService;

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ linkPortal: dataText.linkPortal })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataText])

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSaveData(true)
        setDataText(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div className="col justify-content-end align-items-end mx-3">
           <div className="configuration-display-item mb-3">
                {translate.CustomerPortalLinkTextInEmails}
            </div>
            <div className='col'>
                <textarea id="linkPortal" name="linkPortal" rows={8} cols={50} onInput={handleInputChange}>
                    {dataText.linkPortal}
                </textarea>
            </div>
            <div>
                {saveData && <ButtonPrimary className='ml-auto p-2' onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
