import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import ActivityService from "../../../services/ActivityService";
import { formatDateDigits, formatDateMonthYear, formatDateShort, formatDateTimeDigits, formatDateTimeShort } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import TaskStar from "../../task/components/TaskStar";
import ErrorMessage from "../../shared/components/Error";
import { ActivityListResponse } from "./entities/AcitivityListResponse";
import { ActivityPendingResponse } from "./entities/AcitivityPendingResponse";
import CompanyService from "../../../services/CompanyService";
import Paginator from "../../shared/Paginator";
import TableContext, { TableContextValues } from "../../task/TableContext";
import ActivityTableProvider, { ActivityTableContextValues } from "./ActivityTableProvider";
import ModalService from "../../shared/bootstrap/Modal";
import NewComment, { FilesAttached, LabelActionText } from "./components/NewComment";
import { SMSCompose } from "../components/SMSCompose";
import NewTaskForm from "../../task/components/NewTaskForm";
import TaskDetail from "../../task/components/TaskDetail";
import { TaskStatus } from "../../task/entities/TaskStatus";
import TaskService from "../../../services/TaskSerivce";
import AdvancedFilters, { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";
import { FilterCollection } from "../../../services/FilterService";
import { EmailCompose } from "../components/EmailCompose";
import FloatingPanelService from "../../shared/FloatingPanel";
import Empty from "../../shared/components/Empty";
import ClientDetailContext from "../ClientDetailContext";
import { ActivityEmail } from "./components/ActivityEmailDisplay";
import { addIf, Cast, isNullOrWhitespace, Switch } from "../../../utils/Utils";
import { ToastService } from "../../shared/bootstrap/Toast";
import { SMSView } from "../components/SMSView";
import { TranslationService } from "../../../services/TranslationService";
import MenuButton from "../../shared/components/MenuButton";
import { ActivityHistoryType } from "../../shared/enums";
import iconComment from "../../../assets/img/iconComment.svg";
import iconSMS from "../../../assets/img/iconSMS.svg";
import iconSMSError from "../../../assets/img/iconSMSError.svg";
import iconTask from "../../../assets/img/taskIcon.svg";
import iconTaskPending from "../../../assets/img/icon-task-pending.svg";
import iconTaskDue from "../../../assets/img/icon-task-due.svg";

import iconEmail from "../../../assets/img/iconEmail.svg";
import TooltipComponent from "../../shared/TooltipComponent";
import iconWhatsapp from "../../../assets/img/ico-whatsapp.png";

import { restTimezone } from "../../../utils/ParseUtils";
import { TaskGetMessageListResponse } from "../../task/entities/TaskGetMessageListResponse";
import Collapsable from "../../shared/bootstrap/Collapsable";
import FileService from "../../../services/FileService";
import { Client } from "../entities/ClientGetResponse";
import { WhatsappCompose } from "../components/WhatsappCompose";
import { ActivityWhatsapp } from "./components/ActivityWhatsappDisplay";
import { StatusMessage, WhatsappStatus } from "../../shared/components/whatsappChat/WhatsappChat";
import { ModalTypeDefault } from "../ClientDetail";
import TabPositionContext from "../../shared/TabPositionContext";

import { ActivityGetResponse } from "./entities/AcitivityGetResponse";
import { SortDirection } from "../../shared/entities/Sort";
import Table, { TableHeader } from "../../shared/Table";
import InvoiceService from "../../../services/InvoiceService";
import ButtonLink from "../../segment/component/form/ButtonLink";
import InvoiceTableItemReadOnly from "../invoice/InvoiceTableItemReadOnly";
import { FixToTop } from "../../shared/FixToTop";
import Dropdown from "../../shared/components/Dropdown";

export const ActivityHistory = ({ personid, person, emailAddress, groupId = undefined, setReloads = undefined, openModalDefault = "" }: { personid: string, person?: Client, emailAddress: string, groupId?: number, setReloads?: { activity: () => void }, openModalDefault?: ModalTypeDefault | string }) => {
    return (
        <div>
            <ActivityTableProvider personid={personid} groupId={groupId}>
                <ActivityTabFilter personid={personid} person={person} emailAddress={emailAddress} setReloads={setReloads} openModalDefault={openModalDefault} />
            </ActivityTableProvider>
        </div>);
};

const ActivityPendingTasks = ({ loading, error, response, reload, selected }: { loading: boolean, error: boolean, response: ActivityPendingResponse, reload: () => void, selected: number }) => {
    const { translate } = TranslationService;
    if (loading) {
        return (<Loading />);
    }
    if (error) {
        return (<ErrorMessage onRefresh={reload} message={translate.ErrorProcessingRequest} />);
    }
    if ((response.mails ? response.mails.length : 0) + (response.scheduledtasks ? response.scheduledtasks.length : 0) + (response.tasks ? response.tasks.length : 0) === 0) {
        return (<></>);
    }

    const taskProps: PedingListProps<ActivityPendingResponse.Task> = {
        reload,
        list: response.tasks,
        name: translate.PendingTask,
        Children: ActivityPendingTask,
        keyFun: x => x.TaskID
    };

    return (<>
        <div className="listResults">
            {(selected === 0 || selected === 3) && <PendingList {...{ ...taskProps, typeName: "Task", hideTitle: true }} />}
        </div>
    </>);
};

const ActivityPending = ({ loading, error, response, reload, selected }: { loading: boolean, error: boolean, response: ActivityPendingResponse, reload: () => void, selected: number }) => {
    const { translate } = TranslationService;
    if (loading) {
        return (<Loading />);
    }
    if (error) {
        return (<ErrorMessage onRefresh={reload} message={translate.ErrorProcessingRequest} />);
    }
    if ((response.mails ? response.mails.length : 0) + (response.scheduledtasks ? response.scheduledtasks.length : 0) + (response.tasks ? response.tasks.length : 0) === 0) {
        return (<></>);
    }
    const mailProps: PedingListProps<ActivityPendingResponse.Mail> = {
        reload,
        list: response.mails,
        name: translate.ScheduledEmails,
        Children: ActivityScheduledEmail,
        keyFun: x => x.MessageID
    };
    const scheduledTasksProps: PedingListProps<ActivityPendingResponse.Scheduledtask> = {
        reload,
        list: response.scheduledtasks,
        name: translate.ScheduledTasks,
        Children: ActivityScheduledTask,
        keyFun: x => x.ScheduledTaskID
    };
    return (<>
        <div className="listResults">
            {(selected === 0 || selected === 1) && <PendingList {...mailProps} />}
            {(selected === 0 || selected === 3) && <PendingList {...scheduledTasksProps} />}
        </div>
    </>);
};
type PedingListProps<T> = {
    reload: () => void,
    list: Array<T>,
    name: string,
    Children: React.FC<{ data: T, reload: () => void, typeName?: string }>,
    keyFun: (item: T) => React.Key
    typeName?: string
    hideTitle?: boolean
}

function PendingList<T>({ reload, list, name, Children, keyFun, typeName, hideTitle = false }: PedingListProps<T>) {
    const [minimize, setMinimize] = useState(false);

    if (!list || list.length <= 0) {
        return (<></>);
    }
    return (
        <>
            {!hideTitle && <h5 className="h5 pointer" onClick={() => setMinimize(x => !x)}>{name} <i className={"fa-solid fa-fw " + (minimize ? "fa-chevron-down" : "fa-chevron-up")} /></h5>}
            <Collapsable show={!minimize}>
                <div className="listResults">
                    {list.map(x => <Children key={keyFun(x)} data={x} reload={reload} typeName={typeName} />)}
                </div>
            </Collapsable>
        </>
    );
}

const ActivityList = ({ reload }: { reload: (action?: (val?: any) => void) => void }) => {
    const { translate } = TranslationService;
    const { error, response, loading } = useContext(TableContext) as ActivityTableContextValues;
    const groupedByMonth = response?.list.reduce((prev, curr) => {
        const index = formatDateMonthYear(restTimezone(new Date(curr.date)));
        const array = prev[index] ?? [];
        array.push(curr);
        prev[index] = array;
        return prev;
    }, {} as { [index: string]: ActivityListResponse.Item[] });

    const users = CompanyService.getUsers().concat(response?.oldusers ?? []);

    return (
        <>
            {loading ? <Loading height={512} /> :
                ((error || response === undefined || groupedByMonth === undefined) ? <ErrorMessage onRefresh={reload} message={translate.ErrorProcessingRequest} /> :
                    response.list.length === 0 ?
                        <><Empty /><div style={{ height: 400 }}></div></> : (
                            <>{Object.keys(groupedByMonth).map((month) =>
                                <div className="listResults" key={month}>
                                    <h5 className="h5">{month}</h5>
                                    {groupedByMonth[month].map(x => {
                                        const params = {
                                            key: x.LogId,
                                            data: x,
                                            reload: reload,
                                            users: users
                                        };
                                        switch (x.typeName) {
                                            case "Comment":
                                                return <ActivityComment {...params} />;
                                            case "Email":
                                                return <ActivityEmail {...params} />;
                                            case "SMS":
                                                return <ActivitySms {...params} />;
                                            case "Task":
                                                return <ActivityTask {...params} />;
                                            case "Whatsapp":
                                                return <ActivityWhatsapp {...params} />;
                                            default:
                                                throw new Error("Missing element for activity with type: " + x.typeName);
                                        }
                                    })}
                                </div>)}</>
                        ))}
            <Paginator />

        </>
    );
};

export const ActivityItemTemplate = ({ data, type, date, creator, to, subject, content, actions, contentBeforeTitle, hasTaskCheckCondition, onTaskClick, loadingTaskCheck, taskStar = null, cc, bcc }: {
    data: ActivityListResponse.Item | ActivityPendingResponse.Task | ActivityPendingResponse.Mail | ActivityPendingResponse.Scheduledtask, type: ActivityHistoryType, date?: Date | string, creator: string, to?: string, subject?: string, content: JSX.Element | string, actions: { icon: string, classes?: string, onClick: (onAction?: () => void) => void, tooltip?: string }[], contentBeforeTitle?: JSX.Element | string, hasTaskCheckCondition?: boolean, onTaskClick?: () => void, loadingTaskCheck?: boolean, taskStar?: ReactNode, cc?: string, bcc?: string
}) => {
    const [comment, setComment] = useState<TaskGetMessageListResponse>();
    const [loadingComment, setLoadingComment] = useState(false);
    const [fixedIos, setFixedIos] = useState<ActivityGetResponse["ios"]>();
    const [showMore, setShowMore] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { translate } = TranslationService;

    let imgSrc = "";
    switch (type) {
        case ActivityHistoryType.comment:
            imgSrc = iconComment;
            break;
        case ActivityHistoryType.email:
            imgSrc = iconEmail;
            break;
        case ActivityHistoryType.pendingTask:
            imgSrc = ("dueDays" in data && data.dueDays > 0) ? iconTaskDue : iconTaskPending;
            break;
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            imgSrc = iconTask;
            break;
        case ActivityHistoryType.sms:
            imgSrc = ("Failed" in data && data.Failed) ? iconSMSError : iconSMS;
            break;
        case ActivityHistoryType.whatsapp:
            imgSrc = iconWhatsapp;
            break;
    }

    const hasComments = ("hasComments" in data && data.hasComments) || ("HasComments" in data && data.HasComments);
    const isComment = type === ActivityHistoryType.comment;

    const doShowComment = async () => {
        setLoadingComment(true);
        const id = "ID" in data ? data.ID : ("TaskID" in data ? data.TaskID : 0);
        if (id === 0) {
            return;
        }
        const result = await TaskService.getComments(id);
        setLoadingComment(false);
        if (result instanceof Error) {
            setComment(undefined);
            return;
        }
        setComment(result);
    };

    let typeSubject = <></>;
    switch (type) {
        case ActivityHistoryType.email:
            typeSubject = <div className="type">
                {DisplayActivityType(data)}{subject && ` - ${subject} `}
                <span className="creator">
                    {" "}{translate.From.toLowerCase()} {" " + ((data as ActivityPendingResponse.Mail).FromName || "") + " <" + (data as ActivityPendingResponse.Mail).From + ">"}
                    {DisplayActivityName(data)}
                    {to && <> | {translate.EmailTo2} {to} </>}
                    {cc && <> | {translate.Cc} {cc} </>}
                    {bcc && <> | {translate.Bcc} {bcc} </>}
                </span>
            </div>;
            break;
        case ActivityHistoryType.pendingTask:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            typeSubject = <>
                <div className="type">
                    {DisplayActivityType(data)}
                    <span className="creator">
                        {" "}{translate.To.toLowerCase()} {creator.split(", ").reverse().join(" ")}{DisplayActivityName(data)}
                    </span>
                </div>
                <div className="my-2">
                    {content}
                </div>
            </>;
            break;
        case ActivityHistoryType.comment:
        case ActivityHistoryType.sms:
        case ActivityHistoryType.whatsapp:
            typeSubject = <div className="type">
                {DisplayActivityType(data)}
                <span className="creator">
                    {" "}{translate.From.toLowerCase()} {creator.split(", ").reverse().join(" ")}{DisplayActivityName(data)}
                    {to && <> | {translate.To}: {to} </>}
                </span>
            </div>;
            break;
    }

    let typeContent;
    switch (type) {
        case ActivityHistoryType.email:
            typeContent = content;
            break;
        case ActivityHistoryType.pendingTask:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            typeContent = <>
                {DisplayGroupName(data)}
                <div className="d-flex align-items-center justify-center gap-1" style={{ color: "#777676" }}>
                    {DisplayFileList(data, type)}
                    {DisplayTagIcon(data, true)}
                    <div className="d-flex flex-row">
                        {contentBeforeTitle && <div>{contentBeforeTitle}</div>}
                    </div>
                </div>
            </>;
            break;
        case ActivityHistoryType.comment:
            typeContent = <>
                <div>
                    {subject && <small><em><strong>{TranslationService.translate.Subject}: {subject}</strong></em></small>}
                    <div className="activity-history-text" style={{ wordBreak: "break-all" }}>
                        {content}
                    </div>
                </div>
                {hasComments && comment === undefined &&
                    <button className="btn btn-intiza ps-0 mt-1 ctaViewCommentsRead" onClick={doShowComment}>
                        {TranslationService.translate.ViewComments}
                        {loadingComment && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}
                {/* BOTONES  RESUMIDOS*/}
                {(!showMore && isComment) ?
                    <div>
                        <div className="d-flex">
                            {DisplayGroupName(data)}
                            {DisplayFileIcon(data, type, false)}
                            {DisplayTagIcon(data, false)}
                            {contentBeforeTitle && <>{contentBeforeTitle}</>}
                        </div>
                    </div> : null}
            </>;
            break;
        case ActivityHistoryType.sms:
        case ActivityHistoryType.whatsapp:
            typeContent = <div>
                {subject && <small><em><strong>{TranslationService.translate.Subject}: {subject}</strong></em></small>}
                <div className="activity-history-text" style={{ wordBreak: "break-all" }}>
                    {content}
                </div>
            </div>;
            break;
    }

    let typeClass = "";
    switch (type) {
        case ActivityHistoryType.comment:
            typeClass = "historyCard pointer pb-1";
            break;
        case ActivityHistoryType.pendingTask:
            typeClass = "historyCard pointer pb-1 " + (("dueDays" in data && data.dueDays > 0) ? "bgDueTask" : "bgPendingTask");
            break;
        case ActivityHistoryType.email:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
        case ActivityHistoryType.sms:
        case ActivityHistoryType.whatsapp:
            typeClass = "historyCard";
            break;
        default:
            typeClass = `historyCard ${isComment ? "pointer pb-1" : ""}`;
    }

    let typeIcon = <></>;
    switch (type) {
        case ActivityHistoryType.pendingTask:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            typeIcon = <>
                {loadingTaskCheck ?
                    <i className="fas fa-spinner-third fa-spin third ms-2"></i> :
                    <div>
                        {(type === ActivityHistoryType.task || hasTaskCheckCondition) &&
                            <img onClick={(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) ? onTaskClick : undefined} className="img-fluid pointer ms-2" src={imgSrc} alt="icon comment" />}
                    </div>}
            </>;
            break;
        case ActivityHistoryType.comment:
        case ActivityHistoryType.email:
        case ActivityHistoryType.whatsapp:
            typeIcon = <img className="img-fluid" src={imgSrc} alt="icon comment" />;
            break;
        case ActivityHistoryType.sms:
            typeIcon = <TooltipComponent title={("Failed" in data && data.Failed) ? TranslationService.translate.SMSError : TranslationService.translate.SMSOk}>
                <img className="img-fluid" src={imgSrc} alt="icon comment" />
            </TooltipComponent>;
            break;
    }

    let typeDate = <></>;
    switch (type) {
        case ActivityHistoryType.pendingTask:
        case ActivityHistoryType.scheduledTask:
        case ActivityHistoryType.task:
            typeDate = <div className="dateTertiaryQ d-flex align-items-center">
                <div>
                    {DisplayReminder(data)}
                    {taskStar}
                    {"dueDays" in data && data.dueDays > 0 && <span className="alert-sm alert-sm-danger me-2">{TranslationService.translate.StatusExpired}</span>}
                </div>
                <div>
                    {date && <span className={("dueDays" in data && data.dueDays > 0) ? "fw-bold text-danger" : "fw-bold "}>
                        {
                            typeof date === "string" ?
                                date :
                                (date.getHours() === 0 && date.getMinutes() === 0) ?
                                    formatDateDigits(date) :
                                    formatDateTimeDigits(restTimezone(date))
                        }
                    </span>}
                </div>
            </div>;
            break;
        default:
            typeDate = <div className="dateTertiaryQ">
                {date ?
                    <>
                        {typeof date === "string" ? date : (date.getHours() === 0 && date.getMinutes() === 0)
                            ? formatDateDigits(date)
                            : formatDateTimeDigits(restTimezone(date))}
                    </> : null}
            </div>;
            break;
    }

    const requestDataIos = useCallback(async () => {
        setLoadingComment(true);
        const id = "ID" in data ? data.ID : ("TaskID" in data ? data.TaskID : 0);
        if (data === undefined) {
            return;
        }

        const result = await ActivityService.get(id);
        setLoadingComment(false);
        if (result instanceof Error) {
            return;
        }

        if (result.ios !== undefined) {
            setFixedIos(result.ios);
        }
    }, [data]);

    const handleToggle = async () => {
        if (!showMore) {
            await requestDataIos()
                .then(() => setShowMore(!showMore));
        } else {
            setShowMore(!showMore);
        }
    };

    const handleClose = async () => {
        setShowMore(false);
        await requestDataIos().then(() => setShowMore(true));
    };

    return (
        <div
            onClick={handleToggle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={typeClass}>
            <div className="row">
                <div className="col-auto pe-0">
                    {typeIcon}
                </div>

                <div className="col pt-1">
                    {typeSubject}
                    {typeContent}
                </div>

                {/* FECHA, BOTONES EDITAR Y ELIMINAR */}
                <div className="col-auto">
                    {typeDate}

                    <div className="row justify-content-end">
                        <div className="actions justify-content-end d-flex gap-12 ms-3 mt-f">
                            {actions.map((action, index) =>
                                <TooltipComponent title={action.tooltip ?? false} key={index}>
                                    <button>
                                        <i className={"far " + action.icon + " " + action.classes} onClick={() => {
                                            if (action.icon.includes("fa-pen text-secondary")) {
                                                action.onClick(handleClose);
                                            } else {
                                                action.onClick();
                                            }
                                        }} />
                                    </button>
                                </TooltipComponent>
                            )}
                            {DisplayStatusWhatsapp(data)}
                        </div>
                    </div>
                </div>

            </div>

            {(showMore && isComment) && <hr className="mb-0" />}

            {/* Contenido abierto */}
            <div className="row">
                <div className="col-auto pe-0" style={{ width: 25 }} />

                <div className="col px-4 justify-content-center align-items-center">
                    {isComment ?
                        showMore ?
                            <>
                                {DisplayFileIcon(data, type, true)}
                                {DisplayTagIcon(data, true)}
                                {fixedIos && fixedIos?.length > 0 && DisplayContent(fixedIos)}
                            </>
                            : null
                        : null}
                </div>
            </div>

            {(!showMore && isComment) ?
                <div className="row" style={{ position: "relative" }}>
                    <div className="col d-flex justify-content-center align-items-center"
                        style={{ position: "absolute", top: -15, bottom: 15, left: 0, right: 0 }}>
                        {loadingComment ? (
                            <i className="fas fa-spinner-third fa-spin third ms-2"></i>
                        ) : (
                            isHovered && (
                                <div className="seeMoreClose">
                                    <i className="fa fa-arrow-down mx-1" />
                                    {" "}{TranslationService.translate.SeeMore}
                                </div>
                            )
                        )}
                    </div>
                </div> : null}

            {/* Cerrar */}
            <div className="row" style={{ position: "relative" }}>
                <div className="col d-flex justify-content-center align-items-center"
                    style={{ position: "absolute", top: -15, bottom: 15, left: 0, right: 0 }}>
                    <div className="seeMoreClose">
                        {isComment ?
                            <>
                                {showMore ?
                                    <>
                                        <div className="mx-auto close-container">
                                            <ButtonLink style={{ marginRight: "6%" }} className="btn btn-link a-button">
                                                <div className="seeMoreClose">
                                                    <i className={"fa fa-arrow-up mx-1"} />
                                                    {" "}{TranslationService.translate.Close}
                                                </div>
                                            </ButtonLink>
                                        </div>
                                    </> : null}
                            </> : null}
                    </div>
                </div>
            </div>

        </div>
    );
};

const DisplayContent = (fixedIos: any) => {
    const tableHeaders: TableHeader[] = [
        ...InvoiceService.getTableHeaders(),
    ];

    const tableValues = new TableContextValues();
    tableValues.error = false;
    tableValues.response = { list: fixedIos };
    tableValues.loading = false;
    tableValues.sort = { sortColumn: "", sortDirection: SortDirection.Ascending };
    tableValues.setSortColumn = () => Boolean(4);

    return <>
        {fixedIos !== undefined && CompanyService.getSetting("messageiolink") &&
            <>
                <div className="d-flex align-items-center">
                    <TooltipComponent title={TranslationService.translate.ManagementAssociatedWithInvoices}>
                        <i className="far fa-notes activity-icon-color m-2" />
                    </TooltipComponent>
                    <h6 className="linkedInvoicesComments pb-2">{TranslationService.translate.LinkedInvoices}</h6>
                </div>

                <TableContext.Provider value={tableValues}>
                    <Table headers={tableHeaders} item={InvoiceTableItemReadOnly(undefined)} />
                </TableContext.Provider>
            </>
        }
    </>;
};

export const ActivityItemLeft = ({ icon, date, name = undefined, onClick = undefined }: { icon: string, date: Date, name?: string, onClick?: () => void }) => {
    return (
        <>
            <i className={"far fa-fw me-2 " + icon + (onclick ? " pointer" : "")} onClick={onClick} />
            {formatDateShort(date)}
            {name !== undefined && <div className="text-secondary">{name}</div>}
        </>);
};

export const deleteActivityAction = (
    id: number,
    title: string,
    message: string,
    reload: () => void,
    onError?: () => void
) => {
    return {
        icon: "fa-trash",
        classes: "text-secondary",
        onClick: () => {
            ModalService.showDefaultModal({
                title: title,
                message: message,
                acceptButtonLabel: TranslationService.translate.Delete,
                acceptButtonClassName: "btn btn-danger",
                onAcceptClick: async () => {
                    const result = await ActivityService.delete(id.toString());
                    if (result instanceof Error) {
                        onError && onError();
                        return;
                    }
                    reload && reload();
                }
            });
        },
        tooltip: TranslationService.translate.Delete,
    };
};

export const deleteTaskAction = (
    id: number,
    reload: () => void,
    onError?: () => void
): { icon: string; classes: string; onClick: () => void; } => {
    return {
        icon: "fa-trash",
        classes: "text-secondary",
        onClick: () => {
            ModalService.showDefaultModal({
                title: TranslationService.translate.DeleteActivity,
                message: TranslationService.translate.AreYouSureDeleteActivity,
                acceptButtonLabel: TranslationService.translate.Delete,
                onAcceptClick: async () => {
                    const result = await TaskService.deleteSingle(id);
                    if (result instanceof Error) {
                        onError && onError();
                        return;
                    }
                    reload && reload();
                }
            });
        }
    };
};

const ActivityScheduledEmail = ({ data, reload }: { data: ActivityPendingResponse.Mail, reload: () => void }) => {
    const { translate } = TranslationService;
    const onReadMoreClicked = useCallback(
        async (id: number) => {
            const result = await ActivityService.get(data.MessageID);
            if (result instanceof Error) {
                ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            ModalService.showDefaultModal({
                message: () => <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: result.item.Content }}></div>,
                title: translate.Email,
                size: "modal-xl",
            });
        },
        [data.MessageID, translate.Email, translate.ErrorProcessingRequest],
    );


    const openEmailModal = useCallback(
        async () => {
            const emailRequest = await ActivityService.get(data.MessageID);
            if (emailRequest instanceof Error) {
                ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            FloatingPanelService.showPanel({
                title: translate.EmailToClient,
                children:
                    <EmailCompose
                        personId={data.PersonID.toString()}
                        emailBase={emailRequest.item}
                        onSubmit={reload} />,
                width: 900,
                height: 669,
            });
        },
        [data.MessageID, data.PersonID, reload, translate.EmailToClient, translate.ErrorProcessingRequest],
    );


    const replyAction = () => ({
        icon: "fa-pen text-seconadary",
        onClick: openEmailModal,
        tooltip: translate.Edit,
    });

    const actions = [
        replyAction(),
        deleteActivityAction(data.MessageID, translate.ScheduledEmailDeleteTitle, translate.ScheduledEmailDeleteMessage, reload, () => ToastService.showToast(translate.ErrorProcessingRequest)),
    ];

    const content = <div>
        <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Content }}></div>
        {data.HasMoreContent &&
            <>
                <button
                    className="btn btn-intiza ps-0 mt-1 ctaViewCommentsRead"
                    onClick={() => onReadMoreClicked(data.MessageID)}>{translate.ReadMore}  <i className="fa fa-chevron-down" /></button>

            </>}
    </div>;
    return (
        <ActivityItemTemplate
            creator={data.From}
            data={data}
            type={ActivityHistoryType.email}
            subject={data.Subject}
            date={data.Draft ? TranslationService.translate.Draft : new Date(data.ScheduledDate)}
            to={data.To}
            cc={data.CC}
            bcc={data.BCC}
            content={content}
            actions={CompanyService.canDo("editmail") ? actions : []} />
    );
};

const ActivityPendingTask = ({ data, reload }: { data: ActivityPendingResponse.Task, reload: (action?: (val?: any) => void) => void }) => {
    const [loadingTaskCheck, setloadingTaskCheck] = useState(false);
    const { translate } = TranslationService;
    const onTaskClick = async () => {
        setloadingTaskCheck(true);
        await TaskService.toggle(data.TaskID, TaskStatus.Fulfilled);
        await reload(setloadingTaskCheck);
    };
    const openEditModal = () =>
        FloatingPanelService.showPanel({
            children: <TaskDetail taskId={data.TaskID} clientId={data.PersonID} creatorUserId={data.CreatorUserID} dateCreated={data.Created} reload={reload} />,
            title: "",
            height: 800,
            width: 750,
        });

    const hasTaskCheckCondition = (CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) && (CompanyService.canDo("edittask") || CompanyService.canDo("checkotherstask") || (data.ResponsibleUserID?.toString() === CompanyService.getUserid()));
    const actions = [{ icon: "fa-pen text-secondary", onClick: openEditModal, tooltip: translate.Edit }, deleteTaskAction(data.TaskID, reload, () => ToastService.showToast(translate.ErrorProcessingRequest))];
    const creator = CompanyService.getUsers()?.find(x => x.Id === data.ResponsibleUserID?.toString())?.Value ?? translate.NotAssigned;
    // const content = <div>
    //     {(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) &&
    //         (CompanyService.canDo("edittask") || CompanyService.canDo("checkotherstask") || (data.ResponsibleUserID?.toString() === CompanyService.getUserid())) &&
    //         <input className="form-check-input" type="checkbox" onClick={(CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) ? onTaskClick : undefined} />
    //     }
    //     <div className="form-check-label">
    //         {data.Description}
    //         {DisplayTagList(data)}
    //     </div>
    // </div>;

    return (
        <ActivityItemTemplate data={{ ...data, typeName: "Task" }} onTaskClick={onTaskClick} hasTaskCheckCondition={hasTaskCheckCondition} type={ActivityHistoryType.pendingTask} date={formatDateDigits(new Date(data.dueDate))} creator={creator} content={data.Description} actions={CompanyService.canDo("edittask") ? actions : []} loadingTaskCheck={loadingTaskCheck} taskStar={<TaskStar {...{ Fav: data.Fav, TaskID: data.TaskID }} className={"me-1 align-text-top"} />} />
    );
};

const ActivityScheduledTask = ({ data, reload }: { data: ActivityPendingResponse.Scheduledtask, reload: () => void }) => {
    const { translate } = TranslationService;
    const actions = [deleteTaskAction(data.ScheduledTaskID, reload, () => ToastService.showToast(translate.ErrorProcessingRequest))];
    const creator = CompanyService.getUsers()?.find(x => x.Id === data.ResponsibleUserID?.toString())?.Value ?? translate.NotAssigned;
    return (
        <ActivityItemTemplate data={{ ...data, typeName: "Task" }} type={ActivityHistoryType.scheduledTask} date={getScheduledTime(data)} creator={creator} content={data.Description} actions={CompanyService.canDo("edittask") ? actions : []} />
    );
};

const getScheduledTime = (data: ActivityPendingResponse.Scheduledtask) => {
    const { translate } = TranslationService;
    const days = [translate.Sunday, translate.Monday, translate.Tuesday, translate.Wednesday, translate.Thursday, translate.Friday, translate.Saturday,];
    switch (data.RepeatPeriod) {
        case 0:
            return translate.Daily;
        case 1:
            return `${translate.Weekly} ${translate.On.toLowerCase()} ${days[data.RepeatFrequency].toLowerCase()}`;
        case 2:
            return `${translate.Monthly} ${translate.On.toLowerCase()} ${data.RepeatFrequency}`;
    }
};

const DisplayActivityType = (data: {} | { typeName?: string }) => {
    if ("typeName" in data && data.typeName !== undefined) {
        return <>{TranslationService.getTranslation(data.typeName)}</>;
    }
    return (<></>);
};

const DisplayReminder = (data: {} | { Remember?: string }) => {
    if ("Remember" in data && data.Remember) {
        return <TooltipComponent title={TranslationService.translate.Reminder + " " + formatDateTimeShort(restTimezone(new Date(data.Remember)))} >{<i className="fa-regular fa-bell me-2" />}</TooltipComponent>;
    }
    return (<></>);
};

const DisplayFileList = (data: ActivityListResponse.Item | ActivityPendingResponse.Task | ActivityPendingResponse.Mail | ActivityPendingResponse.Scheduledtask, type: ActivityHistoryType) => {
    const [showFiles, setShowFiles] = useState<boolean>(false);
    if ("item" in data) {
        data = Cast<{ item: ActivityListResponse.Item }>(data).item;
    }
    const download = (fileId: string, fileName: string) => {
        switch (type) {
            // [FALLTHROUGH] Comment uses the same endpoint as email
            case ActivityHistoryType.comment:
            case ActivityHistoryType.whatsapp:
            case ActivityHistoryType.email:
                FileService.downloadFileMessage(fileId, (data as ActivityListResponse.Item).ID ?? (data as ActivityPendingResponse.Mail).MessageID, fileName);
                break;
            case ActivityHistoryType.scheduledTask:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Scheduledtask).ScheduledTaskID.toString(), fileName);
                break;
            case ActivityHistoryType.pendingTask:
            case ActivityHistoryType.task:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Task).TaskID.toString(), fileName);
                break;
            case ActivityHistoryType.sms:
                break;
        }
    };

    const files: { fileName: string, id: string }[] = [];
    if ("Attachments" in data) {
        files.push(...data.Attachments.map(x => ({ fileName: x.Filename, id: x.AttachmentID.toString() })));
    }
    if ("files" in data) {
        files.push(...data.files);
    }

    if (files.length > 0) {
        return (
            <>
                <TooltipComponent title={TranslationService.translate.AttachedFile}>
                    <i className="far fa-fw fa-paperclip activity-icon-color my-2" onClick={() => setShowFiles(!showFiles)} />
                </TooltipComponent>

                {showFiles && (
                    <div className="modal fade show" tabIndex={-1} style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{TranslationService.translate.Attachments}</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowFiles(false)}></button>
                                </div>
                                <div className="modal-body">
                                    {files.map((x, index) => (
                                        <div className="col" key={x.id}>
                                            <button className="btn btn-link p-0 m-0 ms-2" onClick={() => download(x.id, x.fileName)}>
                                                {x.fileName}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowFiles(false)}>{TranslationService.translate.Close}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showFiles && <div className="modal-backdrop fade show"></div>}

            </>
        );
    }

};

export const DisplayFileIcon = (data: ActivityListResponse.Item | ActivityPendingResponse.Task | ActivityPendingResponse.Mail | ActivityPendingResponse.Scheduledtask, type: ActivityHistoryType, list: boolean) => {
    if ("item" in data) {
        data = Cast<{ item: ActivityListResponse.Item }>(data).item;
    }

    const download = (fileId: string, fileName: string) => {
        switch (type) {
            // [FALLTHROUGH] Comment uses the same endpoint as email
            case ActivityHistoryType.comment:
            case ActivityHistoryType.whatsapp:
            case ActivityHistoryType.email:
                FileService.downloadFileMessage(fileId, (data as ActivityListResponse.Item).ID ?? (data as ActivityPendingResponse.Mail).MessageID, fileName);
                break;
            case ActivityHistoryType.scheduledTask:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Scheduledtask).ScheduledTaskID.toString(), fileName);
                break;
            case ActivityHistoryType.pendingTask:
            case ActivityHistoryType.task:
                FileService.downloadTask(fileId, (data as ActivityPendingResponse.Task).TaskID.toString(), fileName);
                break;
            case ActivityHistoryType.sms:
                break;
        }
    };

    const files: { fileName: string, id: string }[] = [];

    if ("Attachments" in data) {
        files.push(...data.Attachments.map(x => ({ fileName: x.Filename, id: x.AttachmentID.toString() })));
    }
    if ("files" in data) {
        files.push(...data.files);
    }

    if (files.length > 0) {
        return (
            <div className="d-flex align-items-center">
                <TooltipComponent title={TranslationService.translate.AttachedFile}>
                    <i className="far fa-fw fa-paperclip activity-icon-color my-2" />
                </TooltipComponent>
                {list ?
                    <div className="pointer d-flex">
                        <FilesAttached
                            files={files}
                            downloadFile={download}
                            canEdit={(files.length > 0)} />
                    </div> : null}
            </div>
        );
    }
};

// const DisplayTagList = (data: { tagList: string } | { tags: string } | { Tags: string } | {}) => {
//     let tags = "";
//     if ("tagList" in data) {
//         tags = data.tagList;
//     }
//     else if ("tags" in data) {
//         tags = data.tags;
//     }

//     if (tags === null && "Tags" in data) {
//         tags = data.Tags;
//     }

//     if (!tags || tags.length === 0) {
//         return <></>;
//     }
//     return <div className="d-flex align-items-center my-1">
//         {
//             tags.split(',').map((tag: any) =>
//                 <div className="m-1">
//                     <LabelActionText bgColor={false} text={tag} action={() => { }} />
//                 </div>
//             )
//         }
//     </div>
// };

const DisplayTagIcon = (data: { tagList: string } | { tags: string } | { Tags: string } | {}, list: boolean) => {
    let tags = "";
    if ("tagList" in data) {
        tags = data.tagList;
    }
    else if ("tags" in data) {
        tags = data.tags;
    }

    if (tags === null && "Tags" in data) {
        tags = data.Tags;
    }

    if (!tags || tags.length === 0) {
        return <></>;
    }

    return (
        <div className="d-flex align-items-center">
            <TooltipComponent title={TranslationService.translate.Tags}>
                <i className="fa fa-tag activity-icon-color my-2" />
            </TooltipComponent>
            <div className="d-flex align-items-center gap-2">
                {list ?
                    tags.split(",").map((tag: any) =>
                        <div>
                            <LabelActionText bgColor={false} text={tag} action={() => { }} />
                        </div>
                    )
                    : null}
            </div>
        </div>
    );
};

const DisplayGroupName = (data: { groupName?: string } | object) => {
    if ("groupName" in data && data.groupName && data.groupName.length > 0) {
        return <div><strong>{CompanyService.getGroupName()}</strong>: <em>{data.groupName}</em></div>;
    }
    return <></>;
};

const DisplayActivityName = (data: { ActivityType?: { ActivityTypeName: string } } | object) => {
    if ("ActivityType" in data && data.ActivityType?.ActivityTypeName !== undefined) {
        return <>{" - "}{data.ActivityType.ActivityTypeName}</>;
    }
    return <></>;
};

const DisplayStatusWhatsapp = (data: { typeName: string, Status?: WhatsappStatus } | object) => {
    if ("typeName" in data && data.typeName === "Whatsapp" && "Status" in data && data?.Status && data.Status > 0) {
        return <StatusMessage status={data?.Status} />;
    }
    return <></>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const DisplayInvoices = (data: {hasIOs: boolean }) => {
//     if (data.hasIOs !== true) {
//         return <></>;
//     }
//     return (
//         <i className="fal fa-copy fa-fw" title={TranslationService.translate.ManagementAssociatedWithInvoices}></i>);
// };

const ActivitySms = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;
    const openSms = () => {
        FloatingPanelService.showPanel({
            children: <SMSView data={data} />,
            title: "SMS",
            width: 600,
            height: 400,
        });
    };
    const actions = [{ icon: "fa-search", onClick: openSms }];
    if (CompanyService.canDo("deletecomment") && (CompanyService.getIsAdmin() || (data.Creator ?? 0) === parseInt(CompanyService.getUserid()))) {
        actions.push(deleteActivityAction(data.ID, translate.SMSDeleteTitle, translate.SMSDeleteMessage, reload));
    }

    const content = <>
        {data.Content}
    </>;
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.sms} date={new Date(data.date)} creator={data.smsphone ?? ""} content={content} actions={actions} />
    );
};

const ActivityComment = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;

    const openEditCommentModal = (handleClose?: () => void) =>
        FloatingPanelService.showPanel({
            children: <NewComment clientid={data.PersonId.toString()} data={data} reload={reload} handleClose={handleClose} />,
            title: translate.EditComment,
            height: 800,
            width: 600,
        });
    const actions = [];
    if (CompanyService.canDo("editcomment")) {
        actions.push({ icon: "fa-pen text-secondary", onClick: openEditCommentModal, tooltip: translate.Edit });
    }
    if (CompanyService.canDo("deletecomment")) {
        actions.push(deleteActivityAction(data.ID, translate.CommentDeleteTitle, translate.CommentDeleteMessage, reload));
    }

    const contentBeforeTitle = data.hasIOs ?
        <TooltipComponent title={TranslationService.translate.ManagementAssociatedWithInvoices}>
            <i className="far fa-notes activity-icon-color my-2" />
        </TooltipComponent>
        : undefined;

    const content = <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: ActivityService.replaceUsersToHtml(data.Content) }}></div>;
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.comment} date={new Date(data.date)} creator={data.Username} content={content} actions={actions} contentBeforeTitle={contentBeforeTitle} />
    );
};

const ActivityTask = ({ data, reload, users }: { data: ActivityListResponse.Item, reload: (action?: (val?: any) => void) => void, users: { Id: string, Value: string }[] }) => {
    const [loadingTaskCheck, setloadingTaskCheck] = useState(false);
    const username = Switch(
        [() => data.Status === TaskStatus.Validated && data.ValidatorUserID !== undefined, users.find(x => x.Id === data.ValidatorUserID?.toString())?.Value],
        [() => data.Status === TaskStatus.Fulfilled, data.ResponsibleUsername],
        [() => isNullOrWhitespace(data.Username), data.CreatorUsername],
        [true, data.Username],
    ) ?? data.Username ?? data.CreatorUsername;

    const onTaskClick = (CompanyService.canDo("edittask") || CompanyService.canDo("checktask")) ? async () => {
        setloadingTaskCheck(true);
        await TaskService.toggle(data.ID, TaskStatus.Pending);
        await reload(setloadingTaskCheck);
    } : undefined;
    const openEditCommentModal = () =>
        FloatingPanelService.showPanel({
            children: <TaskDetail taskId={data.ID} clientId={data.PersonId} creatorUserId={data.CreatorUserID} dateCreated={data.date} reload={reload} />,
            title: "",
            height: 800,
            width: 750,
        });
    const content = <>
        {/* <i className={"pe-2 fa " + (data.Status === TaskStatus.Validated ? "fa-check-double" : "fa-check") + (onTaskClick ? " pointer" : "")} onClick={onTaskClick} /> */}
        {data.Content}

    </>;

    const actions = [{ icon: "fa-pen text-secondary", onClick: openEditCommentModal, tooltip: TranslationService.translate.Edit }, deleteTaskAction(data.ID, reload)];
    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.task} date={new Date(data.date)} creator={username} content={content} actions={CompanyService.canDo("edittask") ? actions : []} onTaskClick={onTaskClick} loadingTaskCheck={loadingTaskCheck} />
    );
};

// const ActionButtons = ({personid, person, selectedTabId, emailAddress, reload, openModalDefault = ""}: {personaId: string, person?: Client, emailAddress: string, selectedTabId: number, reload: () => void, openModalDefault?: ModalTypeDefault | string }) => {
//     const clientDetailContextValues = useContext(ClientDetailContext);
//     const {translate} = TranslationService;
//     const {getCounterInbox} = useContext(TabPositionContext);

//     const openNewCommentModal = () => {
//         FloatingPanelService.showPanel({
//             title: translate.NewComment,
//             children: <NewComment clientid={personaId} reload={reload} />,
//             width: 650,
//             height: 1000,
//             position: 'bottom-right'
//         });
//     };

//     const openNewTaskModal = () => {
//         FloatingPanelService.showPanel({
//             title: translate.NewTask,
//             children: <NewTaskForm personId={personaId} onNewTask={reload} personName={person?.Name ?? ""} />,
//         });
//     };

//     const openNewSMSModal = () => {
//         FloatingPanelService.showPanel({
//             title: translate.NewSMS,
//             children: <SMSCompose person={person!} onSubmit={reload} />,
//             width: 800,
//             height: 396,
//         });
//     };

//     const openNewWhatsappModal = useCallback(() => {
//         FloatingPanelService.showPanel({
//             title: translate.NewWhatsApp,
//             children: <WhatsappCompose person={person!} personId={personaId} onSubmit={reload} getCounterInbox={getCounterInbox} />,
//             width: 800,
//             height: 800,
//             position: 'bottom-right'
//         });
//     }, [getCounterInbox, person, personaId, reload, translate.NewWhatsApp]);

//     const openNewEmailModal = useCallback(() => {
//         FloatingPanelService.showPanel({
//             title: translate.EmailToClient,
//             children: <EmailCompose personId={personaId} defaultTo={emailAddress} clientDetailContext={clientDetailContextValues} onSubmit={reload} />,
//             width: 900,
//             height: 669,
//         });
//     }, [clientDetailContextValues, emailAddress, personaId, reload, translate.EmailToClient])

//     const buttons = [undefined,
//         !CompanyService.canDo("newmail") ? undefined : {title: translate.Email, onClick: openNewEmailModal },
//         !CompanyService.canDo("newcomment") ? undefined : {title: translate.Comment, onClick: openNewCommentModal },
//         !CompanyService.canDo("newtask") ? undefined : {title: translate.Task, onClick: openNewTaskModal },
//         !CompanyService.canDo("newsms") ? undefined : {title: translate.SMS, onClick: openNewSMSModal },
//         undefined,
//         (CompanyService.canDo("newwhatsapp") && CompanyService.canShowWhatsApp()) ? {title: translate.WhatsApp, onClick: openNewWhatsappModal } : undefined,
//     ];


//     const filteredButtons = buttons.filterFalsey();

//     if (filteredButtons.length === 0) {
//         return <></>;
//     }

//     switch (selectedTabId) {
//         case 0:
//             return (<MenuButton showTitle text={translate.New} actions={filteredButtons.map(x => ({ action: x!.onClick, text: x!.title }))}></MenuButton>);
//         case 6:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.NewWhatsApp}</button>);
//         case 4:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.NewSMS}</button>);
//         case 3:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.NewTask}</button>);
//         case 2:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.NewComment}</button>);
//         case 1:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.NewEmail}</button>);
//         default:
//             return (<button className="btn btn-primary" onClick={buttons[selectedTabId]?.onClick}>{TranslationService.translate.New}</button>);
//     }
// };

const ActivityTabFilter = ({ personid, person, emailAddress, setReloads = undefined, openModalDefault = "" }: { personid: string, person?: Client, emailAddress: string, setReloads?: { activity: () => void }, openModalDefault?: ModalTypeDefault | string }) => {
    const { applySearch, reload: reloadTable } = useContext(TableContext);
    const [selected, setSelected] = useState(0);
    const { translate } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<ActivityPendingResponse>();
    const clientDetailContextValues = useContext(ClientDetailContext);

    const { getCounterInbox } = useContext(TabPositionContext);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reload = (action?: (val?: any) => void) => {
        (reloadTable as ((skipReloading: boolean) => void))(true);
        reloadPending(action);
    };


    const openNewCommentModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewComment,
            children: <NewComment clientid={personid} reload={reload} clientDetailContext={clientDetailContextValues} />,
            width: 650,
            height: 750,
            position: "bottom-right"
        });
    };

    const openNewTaskModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewTask,
            children: <NewTaskForm personId={personid} onNewTask={reload} personName={person?.Name ?? ""} />,
            width: 629,
            height: 650,
        });
    };

    const openNewSMSModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewSMS,
            children: <SMSCompose person={person!} onSubmit={reload} />,
            width: 800,
            height: 600,
        });
    };

    const openNewWhatsappModal = useCallback(() => {
        FloatingPanelService.showPanel({
            title: translate.NewWhatsApp,
            children: <WhatsappCompose person={person!} personId={personid} onSubmit={reload} getCounterInbox={getCounterInbox} />,
            width: 800,
            height: 800,
            position: "bottom-right"
        });
    }, [getCounterInbox, person, personid, reload, translate.NewWhatsApp]);

    const openNewEmailModal = useCallback(() => {
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children: <EmailCompose personId={personid} defaultTo={emailAddress} clientDetailContext={clientDetailContextValues} onSubmit={reload} />,
            width: 900,
            height: 669,
        });
    }, [clientDetailContextValues, emailAddress, personid, reload, translate.EmailToClient]);


    const reloadPending = useCallback((action?: (val?: any) => void) => {
        const getData = async () => {
            const result = await ActivityService.getPending(personid);
            if (result instanceof Error) {
                setError(true);
            } else {
                setResponse(result);
            }
            setLoading(false);
            action && action(false);
        };
        getData();
    }, [personid]);

    useEffect(() => {
        setLoading(true);
        reloadPending();
    }, [reloadPending]);

    const onTabClick = useCallback((selection: number) => {
        setSelected(selection);
        if (selection < 7) {
            applySearch(selection.toString());
        }
    }, [applySearch]);

    if (setReloads) {
        setReloads.activity = reload;
    }
    const showWhatsapp = CompanyService.canDo("newwhatsapp") && CompanyService.canShowWhatsApp();
    const showSMS = CompanyService.canDo("newsms") && CompanyService.canShowSMS();
    const buttons = [
        ...addIf(CompanyService.canDo("newmail"), { selected: 1, text: TranslationService.translate.NewEmail, action: openNewEmailModal }),
        ...addIf(CompanyService.canDo("newcomment"), { selected: 2, text: TranslationService.translate.NewComment, action: openNewCommentModal }),
        ...addIf(CompanyService.canDo("newtask"), { selected: 3, text: TranslationService.translate.NewTask, action: openNewTaskModal }),
        ...addIf(showSMS, { selected: 4, text: TranslationService.translate.NewSMS, action: openNewSMSModal }),
        ...addIf(showWhatsapp, { selected: 6, text: TranslationService.translate.NewWhatsApp, action: openNewWhatsappModal }),
    ];

    return (
        <>
            <div>
                <h3 className="mb-0 client-detail-section-title">
                    {TranslationService.translate.PendingTask}
                </h3>
                <hr className="mb-0" />
                <div className="historyManagement">
                    <span id="pending-activity-header-marker" />
                    <ActivityPendingTasks {...{ loading, error, response: response!, reload, selected }} />
                </div>
            </div>
            <div>
                <h3 className="mb-0 client-detail-section-title">
                    {TranslationService.translate.ActivityHistory}
                </h3>
                <hr className="mb-0" />
                <div className="historyManagement">
                    <span id="activity-header-marker" />
                    <ActivityPending {...{ loading, error, response: response!, reload, selected }} />
                </div>
                <hr className="mb-0" />
                <div className="historyManagement">
                    <FixToTop backgroundClass="bg-intiza-grey">
                        <div className="historyManagementActions">
                            <div className="genericHeader ps-0" id="activity-table-header">
                                <div className="d-flex gap-12 align-items-center">
                                    {selected ?
                                        <button type="button" className="btn btn-primary" onClick={buttons.find(b => b.selected === selected)!.action}>
                                            <i className="fa-light fa-plus me-2" /> {buttons.find(b => b.selected === selected)!.text}
                                        </button>
                                        : <MenuButton extraClassName="mb-0" text={TranslationService.translate.NewActivity} actions={buttons} icon="fa-light fa-plus" showTitle={true} hideChevron />}
                                    <AdvancedFiltersButton />
                                    <Dropdown
                                        onChange={i => onTabClick(i)}
                                        items={[
                                            TranslationService.translate.AllActivities,
                                            TranslationService.translate.Email,
                                            TranslationService.translate.Comments,
                                            TranslationService.translate.Tasks,
                                            ...addIf(showSMS, TranslationService.translate.SMS),
                                            ...addIf(showWhatsapp, TranslationService.translate.WhatsApp),
                                        ].map((x, i) => ({
                                            text: x,
                                            value: Math.floor(i / 5) + i,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <AdvancedFilters page={FilterCollection.Activity} />
                    </FixToTop>

                    <ActivityList reload={reload} />
                </div>
            </div>
        </>
    );
};

export default ActivityHistory;
