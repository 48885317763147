import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ToggleEnableActionWrapper } from "./Configuration/OtherConfigurationItems/ToggleEnableActionWrapper";
import { ReceivablesAgingGraphScale } from "./Configuration/ReceivablesAgingGraphScale";
import { PriorityIntervals } from "./Configuration/PriorityIntervals";
import { PriorityFormulaWeights } from "./Configuration/PriorityFormulaWeights";
import { PasswordConfiguration } from "./Configuration/PasswordConfiguration";
import { TotalSegmentPriorities } from "./Configuration/TotalSegmentPriorities";
import { CreateActivityWhenInvoiceStatusChange } from "./Configuration/CreateActivityWhenInvoiceStatusChange";
import { EnableCustomerMessages } from "./Configuration/EnableCustomerMessages";
import { InboundMailsActivityType } from "./Configuration/InboundMailsActivityType";
import { DefaultFieldForSMS } from "./Configuration/DefaultFieldForSMS";
import { DefaultFieldForWhatsapp } from "./Configuration/DefaultFieldForWhatsapp";
import { DefaultOrderOfInvoicesInEmailsAndLetters } from "./Configuration/DefaultOrderOfInvoicesInEmailsAndLetters";
import { PaymentMethods } from "./Configuration/PaymentMethods";
import { BackupConfiguration } from "./Configuration/BackupConfiguration";
import { ReceiptTemplateConfiguration } from "./Configuration/ReceiptTemplateConfiguration";
import { CustomerPortalLinkExpirationTime } from "./Configuration/CustomerPortalLinkExpirationTime";
import CompanyService from "../../services/CompanyService";
import { GroupInvoicesinEmailsandLetters } from "./Configuration/GroupInvoicesinEmailsandLetters";
import { TranslationService } from "../../services/TranslationService";
import { AutomaticActionsLanguage } from "./Configuration/OtherConfigurationItems/AutomaticActionsLanguage";
import { TextLinkPortal } from "./Configuration/TextLinkPortal";

type ConfigurationObjetItemType = {
    field: number;
    key?: string;
    name: string;
    value: string;
    IncludeInList: boolean;
    IncludeInMail: boolean;
    ShowAlways: boolean;
    IncludeInClientExport: boolean;
    Entity: number;
    ManualEdit: boolean;
    IncludeMainCurrency: boolean;
    ShowDueDays: boolean;
    ExcludeInFilters: boolean;
    Whatsapp: boolean;
    IncludeInCustomerPortal: boolean;
}

type otherConfigurationListType = {
    titleGroup: string;
    childrenGroup: ({
        name: string;
        component: () => JSX.Element;
    } | {
        name: string;
        component: null;
    })[];
}

type ConfigurationContextType = {
    otherConfigurationList?: otherConfigurationListType[],
    configValues: ConfigurationObjetItemType[] | [],
    refreshConfigValues: () => void
}

const ConfigurationContext = React.createContext({} as ConfigurationContextType);

export const ConfigurationProvider = ({ children }: PropsWithChildren<any>) => {
    const [configValues, setConfigValues] = useState<ConfigurationObjetItemType[] | []>([]);
    const hasReceipt = (CompanyService.canDo("hasReceipt") && CompanyService.canShowReceipt());

    const keyValueChecked = useMemo(() => configValues?.length > 0 ?
        configValues.map(({ name, value }: any) => ({ [name]: value }))
            .reduce((obj: any, item: any) => {
                obj = { ...obj, ...item };
                return obj;
            }, {}) : undefined, [configValues]);


    const otherConfigurationList = useMemo(() => {
        let optionsValues = undefined;
        if (keyValueChecked) {
            optionsValues = [
                {
                    titleGroup: "AutomaticActions",
                    childrenGroup: [
                        {
                            name: "AllowAutoActionsToSendEmailsToCustomers",
                            component: () => <ToggleEnableActionWrapper title="AllowAutoActionsToSendEmailsToCustomers" initialValue={keyValueChecked["actionsFull"]} action="actionsFull" />
                        },
                        {
                            name: "ReplyMailsViaIntiza",
                            component: () => <ToggleEnableActionWrapper title="ReplyMailsViaIntiza" initialValue={keyValueChecked["replyintiza"]} action="replyintiza" />
                        },
                        {
                            name: "AutomaticActionsLanguage",
                            component: () => <AutomaticActionsLanguage
                                title="AutomaticActionsLanguage"
                                action="actionsCulture"
                                initialValue={keyValueChecked["actionsCulture"]}
                                items={[
                                    { value: null, text: TranslationService.translate.SameCreator },
                                    { value: "es", text: "Español" },
                                    { value: "en", text: "English" },
                                    { value: "pt", text: "Portuguese" }
                                ]}
                            />
                        },
                        { name: 'CustomerPortalLinkTextInEmails', component: () => <TextLinkPortal linkPortal={keyValueChecked['linkPortal']} /> },
                    ]
                },
                {
                    titleGroup: "Dashboard",
                    childrenGroup: [
                        {
                            name: "ReceivablesAgingGraphScale",
                            component: () => <ReceivablesAgingGraphScale initialValue={keyValueChecked["graphscales"]} />
                        },
                        {
                            name: "PriorityIntervals",
                            component: () => <PriorityIntervals initialValue1={keyValueChecked["rank1"]} initialValue2={keyValueChecked["rank2"]} />
                        },
                        {
                            name: "PriorityFormulaWeights",
                            component: () => <PriorityFormulaWeights
                                amountRankPercentValue={keyValueChecked["amountRankPercent"]}
                                dueRankPercentValue={keyValueChecked["dueRankPercent"]}
                                lastActivityRankPercentValue={keyValueChecked["lastActivityRankPercent"]}
                                lastTaskRankPercentValue={keyValueChecked["lastTaskRankPercent"]}
                            />
                        },
                    ]
                },
                {
                    titleGroup: "ActionButtons",
                    childrenGroup: [
                        {
                            name: "EditInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="EditInvoices"
                                invertValue
                                action="hideeditio" initialValue={keyValueChecked["hideeditio"]} />
                        },
                        {
                            name: "CreateInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="CreateInvoices"
                                invertValue
                                action="hidecreateio" initialValue={keyValueChecked["hidecreateio"]}
                            />
                        },
                        {
                            name: "EditCustomers",
                            component: () => <ToggleEnableActionWrapper
                                title="EditCustomers"
                                invertValue
                                action="hideeditperson" initialValue={keyValueChecked["hideeditperson"]}
                            />
                        },
                        {
                            name: "EditPayments",
                            component: () => <ToggleEnableActionWrapper
                                title="EditPayments"
                                invertValue
                                action="hideeditpayment" initialValue={keyValueChecked["hideeditpayment"]}
                            />
                        },
                        {
                            name: "EnablePaymentInMultiCurrency",
                            component: () => <ToggleEnableActionWrapper
                                title="EnablePaymentInMultiCurrency"
                                action="enablemulticurrencytransaction" initialValue={keyValueChecked["enablemulticurrencytransaction"]}
                            />
                        },
                        {
                            name: "CreateCustomers",
                            component: () => <ToggleEnableActionWrapper
                                title="CreateCustomers"
                                invertValue
                                action="hidecreateperson" initialValue={keyValueChecked["hidecreateperson"]}
                            />
                        },
                        {
                            name: "ImportInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportInvoices"
                                invertValue
                                action="hideioimport" initialValue={keyValueChecked["hideioimport"]}
                            />
                        },
                        {
                            name: "ImportNewInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportNewInvoices"
                                invertValue
                                action="hidenewioimport" initialValue={keyValueChecked["hidenewioimport"]}
                            />
                        },
                        {
                            name: "UpdateMultipleInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="UpdateMultipleInvoices"
                                invertValue
                                action="hideupdateioimport" initialValue={keyValueChecked["hideupdateioimport"]}
                            />
                        },
                        {
                            name: "ImportCustomers",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportCustomers"
                                invertValue
                                action="hidepersonimport" initialValue={keyValueChecked["hidepersonimport"]}
                            />
                        },
                        {
                            name: "ImportPayments",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportPayments"
                                invertValue
                                action="hidetransactionimport" initialValue={keyValueChecked["hidetransactionimport"]}
                            />
                        },
                        {
                            name: "ImportComments",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportComments"
                                invertValue
                                action="hideactivityimport" initialValue={keyValueChecked["hideactivityimport"]}
                            />
                        },
                        {
                            name: "ImportFiles",
                            component: () => <ToggleEnableActionWrapper
                                title="ImportFiles"
                                invertValue
                                action="hidefileimport" initialValue={keyValueChecked["hidefileimport"]}
                            />
                        },
                        {
                            name: "DeleteMultipleCustomers",
                            component: () => <ToggleEnableActionWrapper
                                title="DeleteMultipleCustomers"
                                invertValue
                                action="hidedeletepersonlist" initialValue={keyValueChecked["hidedeletepersonlist"]}
                            />
                        },
                        {
                            name: "EnableMultipleInvoiceRemoval",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableMultipleInvoiceRemoval"
                                action="enableiodelete" initialValue={keyValueChecked["enableiodelete"]}
                            />
                        },
                        {
                            name: "EnableAutomaticInvoiceUpdate",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableAutomaticInvoiceUpdate"
                                action="enableioautomaticupdate" initialValue={keyValueChecked["enableioautomaticupdate"]}
                            />
                        },
                        {
                            name: "EnableAutomaticCustomerUpdate",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableAutomaticCustomerUpdate"
                                action="enablepersonautomaticupdate" initialValue={keyValueChecked["enablepersonautomaticupdate"]}
                            />
                        },
                    ]
                },
                {
                    titleGroup: "PasswordConfiguration",
                    childrenGroup: [
                        {
                            name: "PasswordConfiguration",
                            component: () =>
                                <PasswordConfiguration
                                    pwdUpper={keyValueChecked["pwdUpper"]}
                                    pwdLower={keyValueChecked["pwdLower"]}
                                    pwdNumbers={keyValueChecked["pwdNumbers"]}
                                    pwdSymbols={keyValueChecked["pwdSymbols"]}
                                    pwdLength={keyValueChecked["pwdLength"]}
                                    pwdExpireDays={keyValueChecked["pwdExpireDays"]}
                                    pwdUnableToReuseLastNPwds={keyValueChecked["pwdUnableToReuseLastNPwds"]}
                                    pwdUnableToChangePwdForNDays={keyValueChecked["pwdUnableToChangePwdForNDays"]}
                                />
                        },

                    ]
                },
                {
                    titleGroup: "OtherSettings",
                    childrenGroup: [
                        {
                            name: "IncludeSegmentsInCustomerExports",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeSegmentsInCustomerExports"
                                action="exportsegment" initialValue={keyValueChecked["exportsegment"]} />
                        },
                        {
                            name: "EnableCustomerGroups",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableCustomerGroups"
                                action="multiplekey" initialValue={keyValueChecked["multiplekey"]} />
                        },
                        {
                            name: "EnableTaskValidator",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableTaskValidator"
                                action="taskvalidator" initialValue={keyValueChecked["taskvalidator"]} />
                        },
                        {
                            name: "EnabledProgrammedTasks",
                            component: () => <ToggleEnableActionWrapper
                                title="EnabledProgrammedTasks"
                                action="taskscheduler" initialValue={keyValueChecked["taskscheduler"]} />
                        },
                        {
                            name: "EnableActivitiesAndInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableActivitiesAndInvoices"
                                action="messageiolink" initialValue={keyValueChecked["messageiolink"]} />
                        },
                        {
                            name: "RegisterAutomateActionsToEmails",
                            component: () => <ToggleEnableActionWrapper title="RegisterAutomateActionsToEmails" action="registerallactivities" initialValue={keyValueChecked["registerallactivities"]} />
                        },
                        {
                            name: "MandatoryActivityTypeInComments",
                            component: () => <ToggleEnableActionWrapper
                                title="MandatoryActivityTypeInComments"
                                action="mandatorycommenttype" initialValue={keyValueChecked["mandatorycommenttype"]} />
                        },
                        {
                            name: "MandatoryActivityTypeInEmails",
                            component: () => <ToggleEnableActionWrapper
                                title="MandatoryActivityTypeInEmails"
                                action="mandatoryemailtype" initialValue={keyValueChecked["mandatoryemailtype"]} />
                        },
                        {
                            name: "EnableImportOfCustomerAndInvoiceDataByCustomField",
                            component: () =>
                                <ToggleEnableActionWrapper title="EnableImportOfCustomerAndInvoiceDataByCustomField" action="customfieldpersonimport" initialValue={keyValueChecked["customfieldpersonimport"]} />
                        },
                        {
                            name: "MandatoryActivityTypeInTasks",
                            component: () => <ToggleEnableActionWrapper title="MandatoryActivityTypeInTasks" action="mandatorytasktype" initialValue={keyValueChecked["mandatorytasktype"]} />
                        },
                        {
                            name: "MandatoryInvoiceListingInComments",
                            component: () => <ToggleEnableActionWrapper title="MandatoryInvoiceListingInComments" action="mandatoryiocomment" initialValue={keyValueChecked["mandatoryiocomment"]} />
                        },
                        {
                            name: "InboundMailsActivityType",
                            component: () => <InboundMailsActivityType initialValue={keyValueChecked["inboundactivitytypeid"]} />
                        },
                        {
                            name: "MandatoryTagInComments",
                            component: () => <ToggleEnableActionWrapper
                                title="MandatoryTagInComments"
                                action="mandatorytag" initialValue={keyValueChecked["mandatorytag"]} />
                        },
                        {
                            name: "Max1TagInComments",
                            component: () => <ToggleEnableActionWrapper
                                title="Max1TagInComments"
                                action="only1tag" initialValue={keyValueChecked["only1tag"]} />
                        },
                        {
                            name: "CanSelectCommentDate",
                            component: () => <ToggleEnableActionWrapper
                                title="CanSelectCommentDate"
                                action="canselectactivitydate" initialValue={keyValueChecked["canselectactivitydate"]} />
                        },
                        {
                            name: "IncludeLastActivityInCustomerReport",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeLastActivityInCustomerReport"
                                action="includelastlogclient" initialValue={keyValueChecked["includelastlogclient"]} />
                        },
                        {
                            name: "IncludeLastActivityInInvoiceReport",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeLastActivityInInvoiceReport"
                                action="includelastlogio" initialValue={keyValueChecked["includelastlogio"]} />
                        },
                        {
                            name: "IncludeLastActivityInPaymentsReport",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeLastActivityInPaymentsReport"
                                action="includelastlogtransaction" initialValue={keyValueChecked["includelastlogtransaction"]} />
                        },
                        {
                            name: "IncludeUserInPaymentReport",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeUserInPaymentReport"
                                action="includeuserintransactionreport" initialValue={keyValueChecked["includeuserintransactionreport"]} />
                        },
                        {
                            name: "MandatoryFileUploadInEffectiveActivities",
                            component: () => <ToggleEnableActionWrapper
                                title="MandatoryFileUploadInEffectiveActivities"
                                action="mandatoryeffectiveactivityfile" initialValue={keyValueChecked["mandatoryeffectiveactivityfile"]} />
                        },
                        {
                            name: "ShowInsuredTable",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowInsuredTable"
                                action="showgrouptable" initialValue={keyValueChecked["showgrouptable"]} />
                        },
                        {
                            name: "CreateActivityWhenInvoiceStatusChange",
                            component: () => <CreateActivityWhenInvoiceStatusChange
                                iostatuscomment={keyValueChecked["iostatuscomment"]}
                                iostatuscommentactivitytype={keyValueChecked["iostatuscommentactivitytype"]}
                            />
                        },
                        {
                            name: "ShowTotalsInCutomersCurrency",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowTotalsInCutomersCurrency"
                                action="showtotalincustomercurrency" initialValue={keyValueChecked["showtotalincustomercurrency"]} />
                        },
                        {
                            name: "IncludeCustomerDataInInvoiceListHeader",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeCustomerDataInInvoiceListHeader"
                                action="includepersontable" initialValue={keyValueChecked["includepersontable"]} />
                        },
                        {
                            name: "IncludePendingTasksAsActivity",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludePendingTasksAsActivity"
                                action="lastActivityPendingTask" initialValue={keyValueChecked["lastActivityPendingTask"]} />
                        },
                        {
                            name: "IncludeLogoInMails",
                            component: () => <ToggleEnableActionWrapper title="IncludeLogoInMails" action="includelogoinmails" initialValue={keyValueChecked["includelogoinmails"]} />
                        },
                        {
                            name: "EnableMailsWithFormat",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableMailsWithFormat"
                                action="htmlmails" initialValue={keyValueChecked["htmlmails"]} />
                        },
                        {
                            name: "ShowUntypedInteractionsEffective",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowUntypedInteractionsEffective"
                                action="noactivitytypeeffective" initialValue={keyValueChecked["noactivitytypeeffective"]} />
                        },
                        {
                            name: "NotifyDirectEmailsFollowUp",
                            component: () => <ToggleEnableActionWrapper title="NotifyDirectEmailsFollowUp" action="notifydirectemails" initialValue={keyValueChecked["notifydirectemails"]} />
                        },
                        {
                            name: "IncludePendingAmountReceipts",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludePendingAmountReceipts"
                                action="includependinginreceipt" initialValue={keyValueChecked["includependinginreceipt"]} />
                        },
                        {
                            name: "GroupTotalsCurrencySelectingInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="GroupTotalsCurrencySelectingInvoices"
                                action="groupediototals" initialValue={keyValueChecked["groupediototals"]} />
                        },
                        {
                            name: "PriorityBySegments",
                            component: () => <ToggleEnableActionWrapper
                                title="PriorityBySegments"
                                action="enablesegmentpriority" initialValue={keyValueChecked["enablesegmentpriority"]} />
                        },
                        {
                            name: "TotalSegmentPriorities",
                            component: () => <TotalSegmentPriorities initialValue={keyValueChecked["totalsegmentpriorities"]} />
                        },
                        {
                            name: "EnableCustomerPortal",
                            component: () => <ToggleEnableActionWrapper initialValue={keyValueChecked["customerportalenabled"]} title="EnableCustomerPortal" action="customerportalenabled" />
                        },
                        {
                            name: "ShowInvoiceHistory",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowInvoiceHistory"
                                action="customerportalshowhistory" initialValue={keyValueChecked["customerportalshowhistory"]} />
                        },
                        {
                            name: "ShowOnlyPositiveInvoicesByDefault",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowOnlyPositiveInvoicesByDefault"
                                action="customerportalonlypositive" initialValue={keyValueChecked["customerportalonlypositive"]} />
                        },
                        {
                            name: "EnableCustomerMessages",
                            component: () => <EnableCustomerMessages
                                customerMessages={keyValueChecked["customerMessages"]}
                                customermessageactivitytypeid={keyValueChecked["customermessageactivitytypeid"]}
                                customerMessagesTo={keyValueChecked["customerMessagesTo"]}
                                customerMessagesToAddress={keyValueChecked["customerMessagesToAddress"]}
                                customerMessagesToAdditional={keyValueChecked["customerMessagesToAdditional"]} />
                        },
                        {
                            name: "DefaultFieldForSMS",
                            component: () => <DefaultFieldForSMS initialValue={keyValueChecked["defaultsmsfield"]} />
                        },
                        {
                            name: "DefaultFieldForWhatsapp",
                            component: () => <DefaultFieldForWhatsapp initialValue={keyValueChecked["defaultwafield"]} />
                        },
                        {
                            name: "LockTheStatusOfACompletedTask",
                            component: () => <ToggleEnableActionWrapper
                                title="LockTheStatusOfACompletedTask"
                                action="taskdonelock" initialValue={keyValueChecked["taskdonelock"]} />
                        },
                        {
                            name: "NotifyEnable",
                            component: () => <ToggleEnableActionWrapper
                                title="NotifyEnable"
                                action="notifymails" initialValue={keyValueChecked["notifymails"]} />
                        },
                        {
                            name: "SyncEnableBalance",
                            component: () => <ToggleEnableActionWrapper
                                title="SyncEnableBalance"
                                action="partialiosync" initialValue={keyValueChecked["partialiosync"]} />
                        },
                        {
                            name: "EnableImportsDictionaries",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableImportsDictionaries"
                                action="allowimportdictionary" initialValue={keyValueChecked["allowimportdictionary"]} />
                        },
                        {
                            name: "EnableInvoiceDataUpdateNoCustomer",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableInvoiceDataUpdateNoCustomer"
                                action="ioupdatebyref" initialValue={keyValueChecked["ioupdatebyref"]} />
                        },
                        {
                            name: "EnableAdditionalPaymentFields",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableAdditionalPaymentFields"
                                action="allowtransactionadditional" initialValue={keyValueChecked["allowtransactionadditional"]} />
                        },
                        {
                            name: "EnablePreviousImports",
                            component: () => <ToggleEnableActionWrapper
                                title="EnablePreviousImports"
                                action="allowimportpredecessor" initialValue={keyValueChecked["allowimportpredecessor"]} />
                        },
                        {
                            name: "CustomerPortalLinkExpirationTime",
                            component: () => <CustomerPortalLinkExpirationTime initialValue={keyValueChecked["customersiteexpiredays"]} />
                        },
                        {
                            name: "AllowGuestUsersSegmentAccessAllCustomers",
                            component: () => <ToggleEnableActionWrapper
                                title="AllowGuestUsersSegmentAccessAllCustomers"
                                action="allowsegmentfiltertoggle" initialValue={keyValueChecked["allowsegmentfiltertoggle"]} />
                        },
                        {
                            name: "EnableManualEmailSenderEdit",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableManualEmailSenderEdit"
                                action="allowemailfromedit" initialValue={keyValueChecked["allowemailfromedit"]} />
                        },
                        {
                            name: "ShowLastInvoiceUpdateDateMain",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowLastInvoiceUpdateDateMain"
                                action="enableshowlastimport" initialValue={keyValueChecked["enableshowlastimport"]} />
                        },
                        {
                            name: "ShowUntypedInteractionsAsEffective",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowUntypedInteractionsAsEffective"
                                action="noactivitytypeeffective" initialValue={keyValueChecked["noactivitytypeeffective"]} />
                        },
                        {
                            name: "IncludeNonEffectiveInteractionsInLastInteraction",
                            component: () => <ToggleEnableActionWrapper
                                invertValue
                                title="IncludeNonEffectiveInteractionsInLastInteraction"
                                action="lastLogEffective" initialValue={keyValueChecked["lastLogEffective"]} />
                        },
                        {
                            name: "IncludeAutomaticInteractionsInLastInteraction",
                            component: () => <ToggleEnableActionWrapper
                                title="IncludeAutomaticInteractionsInLastInteraction"
                                action="lastLogAuto" initialValue={keyValueChecked["lastLogAuto"]} />
                        },

                        {
                            name: "GroupedTotalsByCurrencyWhenSelectingInvoices",
                            component: () => <ToggleEnableActionWrapper
                                title="GroupedTotalsByCurrencyWhenSelectingInvoices"
                                action="groupediototals" initialValue={keyValueChecked["groupediototals"]} />
                        },
                        {
                            name: "DefaultOrderOfInvoicesInEmailsAndLetters",
                            component: () => <DefaultOrderOfInvoicesInEmailsAndLetters
                                ordermailby={keyValueChecked["ordermailby"]}
                                ordermailbyorderdesc={keyValueChecked["ordermailbyorderdesc"]}
                            />
                        },
                        {
                            name: "EnableScheduledManagementReports",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableScheduledManagementReports"
                                action="allowscheduledreports" initialValue={keyValueChecked["allowscheduledreports"]} />
                        },
                        {
                            name: "EnableFieldEditingAutoActions",
                            component: () => <ToggleEnableActionWrapper
                                title="EnableFieldEditingAutoActions"
                                action="allowactionadditionaldefinition" initialValue={keyValueChecked["allowactionadditionaldefinition"]} />
                        },
                        {
                            name: "DescendingOrderZA",
                            component: () => <ToggleEnableActionWrapper
                                title="DescendingOrderZA"
                                action="groupbyorderdesc" initialValue={keyValueChecked["groupbyorderdesc"]} />
                        },
                        {
                            name: "ShowNegativeAmountsRed",
                            component: () => <ToggleEnableActionWrapper
                                title="ShowNegativeAmountsRed"
                                action="iosnegativeamountinred" initialValue={keyValueChecked["iosnegativeamountinred"]} />
                        },
                        {
                            name: "GroupInvoicesinEmailsandLetters",
                            component: () => <GroupInvoicesinEmailsandLetters
                                alloweditgroupmailby={keyValueChecked["alloweditgroupmailby"]}
                                groupmailby={keyValueChecked["groupmailby"]}
                                groupbyorderdesc={keyValueChecked["groupbyorderdesc"]}
                            />
                        },
                        {
                            name: "PaymentTypeMandatoryField",
                            component: () => <ToggleEnableActionWrapper
                                title="PaymentTypeMandatoryField"
                                action="transactiontyperequired" initialValue={keyValueChecked["transactiontyperequired"]} />
                        },
                        {
                            name: "UnlimitedLengthSMS",
                            component: () => <ToggleEnableActionWrapper title="UnlimitedLengthSMS" action="unlimitedsms" initialValue={keyValueChecked["unlimitedsms"]} />
                        },
                        {
                            name: "HideDecimals",
                            component: () => <ToggleEnableActionWrapper
                                title="HideDecimals"
                                action="hidedecimals" initialValue={keyValueChecked["hidedecimals"]} />
                        },
                        {
                            name: "EnableDeletedUsersInteractionFilter",
                            component: () => <ToggleEnableActionWrapper title="EnableDeletedUsersInteractionFilter" action="deletedusersfilter" initialValue={keyValueChecked["deletedusersfilter"]} />
                        },
                        {
                            name: "DeleteValuesInFieldsAssociatedWithInvoiceStatusOnChange",
                            component: () => <ToggleEnableActionWrapper title="DeleteValuesInFieldsAssociatedWithInvoiceStatusOnChange" action="cleariostatusadditionalfields" initialValue={keyValueChecked["cleariostatusadditionalfields"]} />
                        },
                    ]
                },
                {
                    titleGroup: "PaymentMethods",
                    childrenGroup: [
                        {
                            name: "PaymentMethods",
                            component: () => <PaymentMethods paymentMethod={keyValueChecked["paymentMethod"]} />
                        }
                    ]
                },
                {
                    titleGroup: "Backup",
                    childrenGroup: [
                        {
                            name: "Backup",
                            component: () => <BackupConfiguration {...keyValueChecked} />
                        }
                    ]
                },
            ];
            if (hasReceipt) {
                optionsValues.push({
                    titleGroup: "ReceiptTemplate",
                    childrenGroup: [
                        {
                            name: "ReceiptTemplate",
                            component: () => <ReceiptTemplateConfiguration
                                receiptTemplate={keyValueChecked["receiptTemplate"]}
                                receiptNumber={keyValueChecked["receiptNumber"]} />
                        }
                    ]
                })
            }
        }
        return optionsValues;
    }, [hasReceipt, keyValueChecked]);



    const refreshConfigValues = () => {
        CompanyService.getConfigsCompany().then((res: any) => setConfigValues(res.configs))
    };

    useEffect(() => { refreshConfigValues(); }, []);

    return (<ConfigurationContext.Provider value={{ otherConfigurationList, configValues, refreshConfigValues }}>
        {children}
    </ConfigurationContext.Provider>);
};

export default ConfigurationContext;    