import AdvancedFilterService from "../../../../services/AdvancedFilterService";
import { FilterCollection } from "../../../../services/FilterService";
import { TranslationService } from "../../../../services/TranslationService";
import AdvancedFilters, { AdvancedFiltersButton } from "../../../shared/components/AdvancedFilters";
import Label from "../../../shared/form/Label";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps } from "../EditAction";

export const InvoiceFilters = ({ propGen }: EditProps) => {
    const props = propGen.str.required("Mail_IOFilter");
    const onFilterApply = (filters: string[]) => {
        const prepared = AdvancedFilterService.filterStringsToQueryString(filters
            .filter(x => x !== undefined && x.length > 0));
        props.onChange(prepared);
    };
    return (
        <div className="row mb-3">
            <div className="col-10">
                <Label label={TranslationService.translate.Filters} className={""}>
                    <AdvancedFiltersButton />
                </Label>
            </div>
            <div className="col-10 mt-3">
                <AdvancedFilters page={FilterCollection.ActionEdit}
                    onFilterApply={onFilterApply} defaultValue={props.defaultValue} />
            </div>
            <div className="col-10">
                <ValidationMessage {...props} validationMethod={x => Boolean(x && x.length)}
                    message={TranslationService.translate.IOFilter} />
            </div>
        </div>);
};