import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import SMSConfigurationEdit, { SmsConfigurationEditModel } from "./SMSConfigurationEdit";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import CompanyService from "../../../services/CompanyService";
import { useEffect, useState } from "react";


const SMSConfiguration = () => {
    const { translate } = TranslationService;
    const [data, setData] = useState<SmsConfigurationEditModel>({smsmethod: "SMSManual"} as SmsConfigurationEditModel);
    const getData = async () => {
        const result = await handleErrorWithToast(CompanyService.getSmsConfig());
        const keyMap: Record<string, string> = {
            Method: "smsmethod",
            User: "username",
            Pass: "password",
        };
        const config: Record<string, string> = {};
        result.item.forEach(x => config[keyMap[x.Name] ?? x.Name.toLocaleLowerCase()] = x.Value);
        setData(config as SmsConfigurationEditModel);
    };
    useEffect(() => {
        getData();
    }, []);
    const showChangeSMS = () => {
        FloatingPanelService.showPanel({
            children: <SMSConfigurationEdit data={data!} onSubmit={getData}/>,
            title: translate.EditImportTemplate,
            width: 400,
            height: 600,
            position: "center",
        });
    };

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.SMSSetup}</h2>
            </div>
            <div className="mb-3 row align-items-center show-child-on-hover">
                <div className="col-auto"><label className="">Método de envío</label></div>
                <div className="col-4"><input className="form-control" value={smsName[(data?.smsmethod ?? "SMSManual") as keyof typeof smsName]} readOnly></input></div>
                <div className="col"><button className="btn btn-primary" onClick={() => showChangeSMS()}>{TranslationService.translate.Change}</button></div>
            </div>
        </div>
    );
};

export const smsName =
{
    SMSManual: "Manual",
    SMSAltiria: "Altiria",
    SMSConceptoMovil: "Concepto Móvil",
    SMSConnectUs: "ConnectUs",
    SMSDiloo: "Diloo",
    SMSHablame: "Háblame",
    SMSInfobip: "Infobip",
    SMSIntico: "Intico",
    SMSITDChile: "ITD Chile",
    SMSQuiubas: "Quiubas",
    SMSBox: "SMS Box",
    SMSFacil: "SMS Fácil",
    SMSInteligente: "SMS Inteligente",
    SMSMasivos: "SMS Masivos",
    SMSMasiv: "SMS Masiv",
    SMSTeleprom: "Teleprom",
    SMSTigoBusinessParaguay: "Tigo Business PY",
    SMSTigoBusinessGuatemala: "Tigo Business GT",
    SMSTigoGuatemala: "Tigo GT",
    SMSWhaticket: "Whaticket",
    Twilio: "Twilio",
};

export type SmsType = keyof typeof smsName;


export default SMSConfiguration;