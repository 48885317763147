import { useContext, useState } from "react";
import ActivityService from "../../../../services/ActivityService";
import CompanyService from "../../../../services/CompanyService";
import GroupService from "../../../../services/GroupService";
import ToastContext from "../../../shared/bootstrap/Toast";
import Dropdown from "../../../shared/components/Dropdown";
import { AutocompleteSelect, TagsDropdownEditor } from "../../../shared/components/Editors";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { ActivityGetResponse } from "../entities/AcitivityGetResponse";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import { parseDate, restTimezone } from "../../../../utils/ParseUtils";
import { OptionalMap } from "../../../../utils/Utils";
import { CacheEntity, StorageService } from "../../../../services/StorageService";
import { DisplayFileIcon } from "../ActivityHistory";
import { ActivityHistoryType } from "../../../shared/enums";
import { ActivityPendingResponse } from "../entities/AcitivityPendingResponse";
import { formatDateTimeDigits } from "../../../../utils/FormatUtils";
import FileService from "../../../../services/FileService";

class EmailEditModel {
    id: number;
    date: string;
    groupId?: string;
    tags?: string
    activityTypeId?: string

    constructor(email: ActivityGetResponse.Item) {
        const tags = CompanyService.getTags();
        this.id = email.MessageID;
        this.date = OptionalMap(parseDate(email.date), x => restTimezone(x).toString()) ?? "";
        this.tags = email.tagList?.split(", ").map(x => tags.find(y => y.Value === x)?.Id).filter(x => x).join(", ");
        this.activityTypeId = email.ActivityTypeID?.toString();
    }
}

export const EmailEdit = ({ email, onSubmit = undefined, actions }: { email: ActivityGetResponse.Item, onSubmit?: () => void, actions?: any }) => {
    const [emailEdit, setEmailEdit] = useState(new EmailEditModel(email));
    const [isSaving, setIsSaving] = useState(false);
    const [mailEdited, setMailEdited] = useState(false)
    const { translate } = TranslationService;
    // eslint-disable-next-line
    const { showToast } = useContext(ToastContext);

    const activityTypeOptions = CompanyService.getActivityTypes().filter(x => x.Mail).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName }));
    const defaultActivityType = CompanyService.getActivityTypes().filter(x => x.Mail).find(x => x.MailDefault)?.ActivityTypeID;

    const updateEmail = (propName: string) => (value: string | boolean | undefined | number) => {
        setMailEdited(true);
        setEmailEdit({ ...emailEdit, [propName]: value });
    };

    const groupSelectOptions = async (inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (!email.PersonID || inputValue.length < minInputLength) { return list; }
        const result = await GroupService.search(email.PersonID.toString(), inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.data.toString(), label: x.value }));
        }
        return list;
    };
    const groupName: string = email.Group ?? (email.GroupID && StorageService.getCache(CacheEntity.GroupName, email.GroupID));
    const canSubmit = () => !isSaving && !!emailEdit.date;

    const submit = async () => {
        if (!canSubmit()) { return; }
        setIsSaving(true);
        const result = await ActivityService.editEmail(emailEdit.id, emailEdit.date, emailEdit.activityTypeId, emailEdit.tags, emailEdit.groupId);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            showToast(translate.SavedChanges, undefined, "success");
            onSubmit && onSubmit();
            setEmailEdit(new EmailEditModel(email));
        }
        setIsSaving(false);
        FloatingPanelService.hidePanel();
    };

    const download = (fileId: string, fileName: string) => FileService.downloadFileMessage(fileId, email.MessageID, fileName);

    return (
        <>
            <div className="floatingBody p-4">
                <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.Date}
                    </div>
                    <div className="col-8">
                        {formatDateTimeDigits(new Date(emailEdit.date))}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.From}
                    </div>
                    <div className="col-8">
                        {" " + email?.FromName + ' <' + email.From + '>'}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.EmailTo}
                    </div>
                    <div className="col-8">
                        {email.To}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.Subject}
                    </div>
                    <div className="col-8">
                        {email.Subject}
                    </div>
                </div>
                {email.BCC && <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.Bcc}
                    </div>
                    <div className="col-8">
                        {email.BCC}
                    </div>
                </div>}
                {email.CC && <div className="row mb-2">
                    <div className="col-3 font-weight-bold">
                        {translate.Cc}
                    </div>
                    <div className="col-8">
                        {email.CC}
                    </div>
                </div>}
                <div className="card mb-2">
                    <div className="card-body" dangerouslySetInnerHTML={{ __html: email.Content }}>
                    </div>
                </div>
                {DisplayFileIcon(email as unknown as ActivityPendingResponse.Mail, ActivityHistoryType.email, true)}
                {Boolean(CompanyService.getTags()?.length) && <div className="row mb-2">
                    <label className="col-3 font-weight-normal col-form-label">
                        {translate.Tags}
                    </label>
                    <div className="col-8">
                        <TagsDropdownEditor onChange={updateEmail("tags")} defaultValue={emailEdit.tags} />
                    </div>
                </div>}
                <div className="row mb-2">
                    <label className="col-3 font-weight-normal col-form-label">
                        {translate.ActivityType}
                    </label>
                    <div className="col-8">
                        <Dropdown onChange={updateEmail("activityTypeId")} items={activityTypeOptions} optionLabel={translate.None} defaultValue={email.ActivityTypeID ?? defaultActivityType} />
                    </div>
                </div>
                {CompanyService.getGroupName()?.length > 0 && <div className="row mb-2">
                    <label className="col-3 font-weight-normal col-form-label">{CompanyService.getGroupName()}</label>
                    <div className="col-8">
                        <AutocompleteSelect loadOptions={groupSelectOptions} onChange={x => updateEmail("groupId")(x?.value)} defaultVal={email.GroupID && groupName ? { value: email.GroupID, label: groupName } : undefined} />
                    </div>
                </div>}
                <div className="modal-body">
                    {email.files.map((x, index) => (
                        <div className="col" key={x.id}>
                            <button className="btn btn-link p-0 m-0 ms-2" onClick={() => download(x.id, x.fileName)}>
                                {x.fileName}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <FloatingPanelFooter>
                <div className="d-flex col justify-space-between">
                    <div className="d-flex col gap-2">
                        {actions && actions.map((action: any, index: number) =>
                            <button key={index} className='btn btn-secondary' onClick={() => action.onClick()}>
                                <i className={"far " + action.icon} />
                                {action.tooltip}
                            </button>
                        )}
                    </div>
                    <div className="d-flex align-end">
                        {mailEdited &&
                            <button className='btn btn-primary' onClick={submit}>
                                {translate.Save}
                                {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                            </button>}
                    </div>
                </div>
            </FloatingPanelFooter>
        </>
    );
};