import { useContext } from "react";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import NewTaskForm from "../../task/components/NewTaskForm";
import NewComment from "../activity/components/NewComment";
import ClientDetailContext from "../ClientDetailContext";
import { EmailCompose } from "./EmailCompose";
import { SMSCompose } from "./SMSCompose";
import { Client } from "../entities/ClientGetResponse";
import IconButtonDebts from "./IconButtonDebts";
import TooltipComponent from "../../shared/TooltipComponent";
import { WhatsappCompose } from "./WhatsappCompose";
import TabPositionContext from "../../shared/TabPositionContext";

export const ClientDetailLeftMenuButtons = ({ direction, client, clientEmail, reload, toggleMenu }: { direction?: "row" | "column", client: Client, clientEmail: string, reload: () => void, toggleMenu: () => void, }) => {
    const { translate } = TranslationService;
    const clientDetailContextValues = useContext(ClientDetailContext);
    const { getCounterInbox } = useContext(TabPositionContext);

    const openNewCommentModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewComment,
            children: <NewComment clientid={client.PersonId.toString()} reload={reload} clientDetailContext={clientDetailContextValues} />,
            width: 800,
            height: 700,
        });
    };

    const openNewTaskModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewTask,
            children: <NewTaskForm personId={client.PersonId.toString()} personName={client.Name} onNewTask={reload} />,
        });
    };

    const openNewSMSModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewSMS,
            children: <SMSCompose person={client} onSubmit={reload} />,
            width: 800,
            height: 620,
        });
    };

    const openNewEmailModal = () => {
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children: <EmailCompose personId={client.PersonId.toString()} defaultTo={clientEmail} clientDetailContext={clientDetailContextValues} onSubmit={reload} />,
            width: 1000,
            height: 769,
        });
    };

    const openNewWhatsappModal = () => {
        FloatingPanelService.showPanel({
            title: translate.NewWhatsApp,
            children: <WhatsappCompose person={client!} personId={client.PersonId.toString()} onSubmit={reload} getCounterInbox={getCounterInbox} />,
            width: 800,
            height: 800,
            position: "bottom-right"
        });
    };
    const openIcon =
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2.5 -2.5 30 30" fill="none" className="clientDetailOpenIcon pointer" onClick={() => toggleMenu()} data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-expanded="false" aria-controls="collapseMenu">
            <circle cx="12.5" cy="12.5" r="15" fill="#0076b6" />
            <path d="M7 8 L11 12 L7 16" fill="none" stroke="white" strokeWidth="2" />
            <path d="M14 8 L18 12 L14 16" fill="none" stroke="white" strokeWidth="2" />
        </svg>;

    return (
        <div className={"actions " + direction}>
            {direction === "column" && openIcon}
            {CompanyService.canDo("newmail") && <IconButtonDebts onClick={openNewEmailModal}>
                <TooltipComponent title={translate.ComposeMail}>
                    <i className="far fa-envelope"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo("newtask") && <IconButtonDebts onClick={openNewTaskModal}>
                <TooltipComponent title={translate.NewTask}>
                    <i className="far fa-square-check"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {CompanyService.canDo("newcomment") && <IconButtonDebts onClick={openNewCommentModal}>
                <TooltipComponent title={translate.NewComment}>
                    <i className="far fa-message-dots"></i>
                </TooltipComponent>
            </IconButtonDebts>}
            {(CompanyService.canDo("newsms") && CompanyService.canShowSMS()) &&
                <IconButtonDebts onClick={openNewSMSModal}>
                    <TooltipComponent title={translate.NewSMS}>
                        <i className="fa-regular fa-phone"></i>
                    </TooltipComponent>
                </IconButtonDebts>}
            {(CompanyService.canDo("newwhatsapp") && CompanyService.canShowWhatsApp()) &&
                <IconButtonDebts onClick={openNewWhatsappModal}>
                    <TooltipComponent title={translate.NewWhatsApp}>
                        <i className="fa-brands fa-whatsapp"></i>
                    </TooltipComponent>
                </IconButtonDebts>}

            {
                direction === "row" && <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2.5 -2.5 30 30" fill="none"
                        className="clientDetailOpenIcon clientDetailOpenIcon-column pointer"
                        style={{ right: 11, position: 'absolute' }}
                        onClick={() => toggleMenu()}
                        data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-expanded="false" aria-controls="collapseMenu">
                        <circle cx="12.5" cy="12.5" r="15" fill="#0076b6" />
                        <path d="M7 8 L11 12 L7 16" fill="none" stroke="white" strokeWidth="2" />
                        <path d="M14 8 L18 12 L14 16" fill="none" stroke="white" strokeWidth="2" />
                    </svg>
                </>
            }
        </div>
    );
};