import { InvoiceDeleteFileResponse } from "../entities/invoice/InvoiceDeleteFileResponse";
import { InvoiceSetFileResponse } from "../entities/invoice/InvoiceSetFileResponse";
import { ReportAgingRequest } from "../entities/reports/ReportAging/ReportAgingRequest";
import { ReportAgingListResponse } from "../entities/reports/ReportAging/ReportAgingResponse";
import { ReportInvoiceGroupResponse } from "../entities/reports/reportInvoiceGroup/ReportInvoiceGroupResponse";
import { InvoiceEditMultipleResult } from "../pages/client/invoice/entities/InvoiceEditMultipleResult";
import { InvoiceEditStatus } from "../pages/client/invoice/entities/InvoiceEditStatus";
import { InvoiceGetResponse } from "../pages/client/invoice/entities/InvoiceGetResponse";
import { InvoiceHistoryListRequest } from "../pages/client/invoice/entities/InvoiceHistoryListRequest";
import { InvoiceHistoryListResponse } from "../pages/client/invoice/entities/InvoiceHistoryListResponse";
import { InvoiceGroupListResponse, InvoiceListRequest } from "../pages/client/invoice/entities/InvoiceListRequest";
import { InvoiceListResponse } from "../pages/client/invoice/entities/InvoiceListResponse";
import { InvoiceStatusChangeResponse } from "../pages/client/invoice/entities/InvoiceStatusChangeResponse";
import { InvoiceTogglePayResponse } from "../pages/client/invoice/entities/InvoiceTogglePayResponse";
import { InvoiceModel } from "../pages/client/invoice/InvoiceNew";
import { EntityField } from "../pages/shared/entities/EntityField";
import { TableHeader, TableHeaderWithFieldId } from "../pages/shared/Table";
import { formatDateDigits, formatIntizaDate } from "../utils/FormatUtils";
import { parseDate } from "../utils/ParseUtils";
import { Cast, MapIfEqual, OptionalMap } from "../utils/Utils";
import CompanyService, { DataType, Entities } from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

export enum InvoiceFields {
    client = -1,
    emittedDate = -2,
    amount = -3,
    referenceNumber = -4,
    dueDate = -5,
    description = -6,
    group = -7,
    status = -8,
    iostatus = -9,
    currency = -10,
    pending = -11,
    paidDate = -12,
    currencyrate = -13,
    dueDays = -14,
    claimable = -15,
    hasFiles = -16
}

export enum InvoiceSetFields {
    fromDate = -1001,
    toDate = -1002,
    fromDue = -1003,
    toDue = -1004,
    personId = -1005,
    personName = -1006,
    groupId = -1007,
    groupName = -1008,
    referenceNumber = -1009,
    status = -1010,
    amount = -1011,
    pendingAmount = -1012,
    description = -1013,
    tags = -1014,
    ioStatus = -1015,
    reclaimable = -1016,
    currency = -1017,
    fromPaid = -1018,
    toPaid = -1019,
    hasFiles = -1020,
    hasFailedEmails = -1021,
    importid = -1022,
    emittedDate = -1023,
    dueDate = -1024,
    paidDate = -1025
}

type TogglePayRequest = {
    pay: boolean,
    invoiceIds: number[],
    all: boolean,
    filters: string | undefined,
    personId: string,
    comments?: string,
    date?: Date,
    transactiontype?: string,
    additional: { Id: string, Value: string }[],
    receipt: boolean
}

type StatusChangeRequest = {
    invoiceIds: number[],
    all: boolean,
    filters: string | undefined,
    personId: string,
    statusEdit: InvoiceEditStatus
}

type EditMultipleRequest = {
    invoiceIds: number[],
    all: boolean,
    filters: string | undefined,
    personId: string,
    fields: { field: string, value: string | undefined }[]
}

class InvoiceService {
    public static downloadAllFilesFromInvoice(invoiceId: number) {
        return RequestService.downloadFile(`/file/downloadiofiles?ioid=${invoiceId}`);
    }
    public static getList(request: InvoiceListRequest): Promise<InvoiceListResponse | Error> {
        request.additionaldefinitions = CompanyService.getAdditionalDefinitions();
        request.currencies = CompanyService.getCurrencies();
        request.includelastlog = true;
        return RequestService.post("/io/list", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies() });
    }
    public static getInvoiceGroups(request: InvoiceListRequest): Promise<InvoiceGroupListResponse | Error> {
        request.additionaldefinitions = CompanyService.getAdditionalDefinitions();
        return RequestService.post("/io/getiogroup", { ...request });
    }
    public static getInvoice(invoiceId: number): Promise<InvoiceGetResponse | Error> {
        return RequestService.post("/io/get", { id: invoiceId, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getReportAging(reportAgingRequest: ReportAgingRequest): Promise<ReportAgingListResponse | Error> {
        return RequestService.post("/io/groupAging", { ...reportAgingRequest, currencies: CompanyService.getCurrencies() });
    }
    public static setField(invoiceId: number, field: string, value: string, groupName?: string) {
        let requestBody = {
            id: invoiceId,
            field,
            value,
            groupName
        };
        if (field === InvoiceFields.group.toString() && isNaN(parseInt(value))) {
            requestBody = {
                id: invoiceId,
                field,
                value: '',
                groupName: value
            };
        }
        return RequestService.post("/io/setField", { ...requestBody, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getInvoiceGroupList(groupBy: number, filter: string | null = null): Promise<ReportInvoiceGroupResponse | Error> {
        return RequestService.post("/io/reportgroup", { id: groupBy, page: 0, filter, currencies: CompanyService.getCurrencies() });
    }
    public static async export(request: InvoiceListRequest & { reid: number | null }, count: number) {
        return await ExportService.requestExport("/io/export", { ...request, includelastlog: true, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies() }, count);
    }

    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/io/delete", { id });
    }

    public static exportHistoryIoList(dateFrom: string, dateTo: string, count: number) {
        return ExportService.requestExport("/io/exporthistoryiolist", { dateFrom, dateTo, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, count);
    }

    public static getIoHistoryList(request: InvoiceHistoryListRequest): Promise<InvoiceHistoryListResponse | Error> {
        return RequestService.post("/io/historyiolist", {
            ...request,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            iostatus: CompanyService.getIoStatus(),
            currencies: CompanyService.getCurrencies(),
            users: CompanyService.getUsers(),
        });
    }

    public static getReferenceNumberName() {
        return CompanyService.getConfigItems().find(x => x.field === -4 && x.Entity === Entities.Invoice)?.name ?? TranslationService.translate.ReferenceNumber;
    }

    public static async setFile(invoiceId: string, fileId: string): Promise<InvoiceSetFileResponse | Error> {
        const request = {
            ioid: parseInt(invoiceId),
            id: fileId
        };
        const result: InvoiceSetFileResponse | Error = await RequestService.post("/io/setfile", request);
        return result;
    }
    public static deleteFile(fileId: string): Promise<InvoiceDeleteFileResponse | Error> {
        return RequestService.post("/io/deletefile", { id: fileId });
    }

    public static exportAgingGroup(expectedRows: number, ids: number[], filter: string) {
        ExportService.requestExport("/io/exportGroupAging", { expectedRows, ids, filter, currencies: CompanyService.getCurrencies() }, expectedRows, "groupagingexport.xls");
    }

    public static statusChange(request: StatusChangeRequest): Promise<InvoiceStatusChangeResponse | Error> {
        const requestBody = {
            ids: request.invoiceIds.join(","),
            chkall: request.all ? "1" : "0",
            filter: request.filters,
            iostatusid: request.statusEdit.statusId,
            additionals: request.statusEdit.additionals,
            personid: request.personId,
            iostatus: CompanyService.getIoStatus(),
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            currencies: CompanyService.getCurrencies(),
        };
        return RequestService.post("/io/iostatuschange", requestBody);
    }

    public static editMultiple(request: EditMultipleRequest): Promise<InvoiceEditMultipleResult | Error> {
        const requestBody = {
            ids: request.invoiceIds.join(","),
            personid: request.personId,
            chkall: request.all ? "1" : "0",
            filter: request.filters,
            fields: request.fields,
            currencies: CompanyService.getCurrencies(),
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
        };
        return RequestService.post("/io/setfields", requestBody);
    }

    public static togglePay(request: TogglePayRequest): Promise<InvoiceTogglePayResponse | Error> {
        const requestBody = {
            pay: request.pay ? "1" : "0",
            ids: request.invoiceIds?.join(","),
            checkall: request.all ? "1" : "0",
            filter: request.filters,
            personid: request.personId,
            comments: request.comments,
            transactiontype: request.transactiontype ? parseInt(request.transactiontype) : undefined,
            date: OptionalMap(request.date, formatIntizaDate),
            additional: request.additional,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            currencies: CompanyService.getCurrencies(),
            receipt: request.receipt
        };
        return RequestService.post("/io/togglepay", requestBody);
    }

    public static set(invoice: InvoiceModel): Promise<Error | { groupid: number }> {
        return RequestService.post("/io/set", { item: invoice, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }

    public static exportInvoiceGroupList(groupBy: number, filter: string = '', expectedRows: number = 10): Promise<void> {
        return ExportService.requestExport("/io/exportReportGroup", {
            id: groupBy,
            filter,
            currencies: CompanyService.getCurrencies()
        }, expectedRows, "clients.xlsx");
    }



    static getTableHeaders(addSecondPending = true, addStatusIcon = false): TableHeaderWithFieldId[] {
        const { translate } = TranslationService;
        const ids = CompanyService.getIoSortedFields().split(",").map(x => parseInt(x));
        const values: TableHeaderWithFieldId[] = [];
        CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Invoice && x.IncludeInList)
            .forEach(x => values[x.AdditionalDefinitionID] = {
                ...new TableHeaderWithFieldId(
                    "ioadditional." + (x.Type === DataType.List ? "list" : "text") + "field" + x.Field,
                    x.Name,
                    [DataType.Currency, DataType.Number].includes(x.Type),
                    true,
                    x.AdditionalDefinitionID.toString(),
                    x.Type,
                ),
            });
        values[InvoiceFields.referenceNumber] = {
            ...new TableHeader(
                "referenceNumber",
                InvoiceService.getReferenceNumberName(),
                false
            ),
            fieldId: "referenceNumber",
            type: DataType.Text,
            className: "w-200px"
        };
        if (CompanyService.getGroupName()) {
            values[InvoiceFields.group] = {
                ...new TableHeader(
                    "group",
                    CompanyService.getGroupName(),
                    false,
                    true,
                ),
                fieldId: "group",
                type: DataType.Text,
            };
        }
        values[InvoiceFields.amount] = {
            ...new TableHeader(
                "amount",
                translate.Amount,
                true
            ),
            fieldId: "amount",
            type: DataType.Currency,
        };
        values[InvoiceFields.pending] = {
            ...new TableHeader(
                "pending",
                translate.Pending,
                true
            ),
            fieldId: "pending",
            type: DataType.Currency,
        };
        values[InvoiceFields.dueDate] = {
            ...new TableHeader(
                "dueDate",
                translate.DueDate,
                false
            ),
            fieldId: "dueDate",
            type: DataType.Date,
        };
        values[InvoiceFields.iostatus] = {
            ...new TableHeader(
                "iostatus",
                translate.IOStatus,
                false,
                false,
                "w-250px"
            ),
            fieldId: "status",
            type: DataType.Text,
        };
        values[InvoiceFields.description] = {
            ...new TableHeader(
                "notes",
                translate.Observations,
                false, false
            ),
            fieldId: "description",
            type: DataType.Text,
            className: "w-325px",
        };
        values[InvoiceFields.emittedDate] = {
            ...new TableHeader(
                "emittedDate",
                translate.IssueDate,
                false, true
            ),
            fieldId: "emittedDate",
            type: DataType.Date,
        };
        const allHeaders = ids.map(x => {
            const value = values[x];
            //@ts-expect-error The undefined values we set here are filtered later.
            values[x] = undefined;
            return value;
        });
        const fixedToRemoveIds = CompanyService.getConfigItems()
            .filter(x => x.Entity === Entities.Invoice && !x.IncludeInList)
            .map(x => InvoiceFields[x.field].toString());
        if (!CompanyService.getConfigItems().find(x => x.Entity === Entities.Invoice && x.field === -9)?.IncludeInList) {
            fixedToRemoveIds.push("status");
        }
        const tableHeaders = [
            ...allHeaders.filter(x => x !== undefined && !fixedToRemoveIds.includes(x.fieldId)),
            ...Object.values(values).filter(x => x !== undefined && !fixedToRemoveIds.includes(x.fieldId)),
        ];

        /// Fix because of missing field "pending" in ioSortedFields
        const pendingIndex = tableHeaders.findIndex(x => x.key === "pending");
        if (pendingIndex === tableHeaders.length - 1) {
            const indexOfAmount = allHeaders.filter(x => x !== undefined).findIndex(x => x.fieldId === "amount") + Number(tableHeaders.findIndex(x => x.fieldId === "amount") >= 0) - 1;
            const vals = tableHeaders.splice(pendingIndex, 1);

            if (addSecondPending && CompanyService.getConfigItems().find(x => x.field === -3)?.IncludeMainCurrency) {
                const secondPending = {
                    ...new TableHeader(
                        "pending",
                        `${translate.Pending} (${translate.MainCurrency})`,
                        true
                    ),
                    fieldId: "pending2",
                    type: DataType.Currency,
                };
                vals.push(secondPending);
            }
            tableHeaders.splice(indexOfAmount + 1, 0, ...vals);
        }

        if (addStatusIcon) {
            tableHeaders.push(
                new TableHeaderWithFieldId("statusIcon", "", false, false, "StatusIcon", DataType.Text, "w-40px")
            );
        }
        return tableHeaders;
    }
}

const InvoiceFieldTitleKey = {
    [InvoiceFields.client]: "Client",
    [InvoiceFields.emittedDate]: "IssueDate",
    [InvoiceFields.amount]: "Amount",
    [InvoiceFields.referenceNumber]: "",
    [InvoiceFields.dueDate]: "DueDate",
    [InvoiceFields.description]: "Observations",
    [InvoiceFields.group]: "Group",
    [InvoiceFields.status]: "Status",
    [InvoiceFields.iostatus]: "IOStatus",
    [InvoiceFields.currency]: "Currency",
    [InvoiceFields.pending]: "Pending",
    [InvoiceFields.paidDate]: "",
    [InvoiceFields.currencyrate]: "",
    [InvoiceFields.dueDays]: "",
    [InvoiceFields.claimable]: "",
    [InvoiceFields.hasFiles]: "",
};

const InvoiceSetFieldsKey = {
    [InvoiceSetFields.groupName]: () => CompanyService.getGroupName(),
    [InvoiceSetFields.referenceNumber]: () => InvoiceService.getReferenceNumberName(),
    [InvoiceSetFields.pendingAmount]: () => TranslationService.translate.PendingAmount,
    [InvoiceSetFields.ioStatus]: () => TranslationService.translate.IOStatus,
};

export const getInvoiceFieldTitle = (field: InvoiceFields) => field === InvoiceFields.group ? CompanyService.getGroupName() : TranslationService.getTranslation(InvoiceFieldTitleKey[field]);
export const translateInvoiceSetFields = (value: InvoiceSetFields) =>
    MapIfEqual(getInvoiceFieldTitle(Cast<InvoiceFields>(InvoiceFields[(InvoiceSetFields as unknown as Record<InvoiceSetFields, keyof InvoiceFields>)[value] as unknown as number])), undefined, () => InvoiceSetFieldsKey[Cast<-1008>(value)]());
export const getInvoiceFieldIsDefault = (id: number) => Object.values(InvoiceFields).includes(id);
export const getInvoiceDefaultFieldType = (id: number) => {
    switch (id) {
        case InvoiceFields.emittedDate:
        case InvoiceFields.paidDate:
        case InvoiceFields.dueDate:
            return DataType.Date;
        case InvoiceFields.amount:
            return DataType.Currency;
        case InvoiceFields.group:
            return DataType.Group;
        case InvoiceFields.status:
        case InvoiceFields.iostatus:
            return DataType.List;
        case InvoiceFields.dueDays:
        case InvoiceFields.client:
        case InvoiceFields.description:
        case InvoiceFields.currency:
        case InvoiceFields.pending:
        case InvoiceFields.currencyrate:
        case InvoiceFields.claimable:
        case InvoiceFields.hasFiles:
        case InvoiceFields.referenceNumber:
        default:
            return DataType.Text;
    }
};

export const getInvoiceAdditionalValue = (field: EntityField, invoice: InvoiceGetResponse.Item) => {
    return invoice.additionals.find(additional => additional.Id === field.id.toString())?.Value;
};

const getInvoiceDefaultFieldProperty = (id: number) => InvoiceFields[id];
export const getInvoiceFieldDisplayValue = (field: EntityField, invoice: InvoiceGetResponse.Item) => {
    if (getInvoiceFieldIsDefault(field.id)) {
        const invoiceProp = getInvoiceDefaultFieldProperty(field.id);
        return invoice[invoiceProp as keyof InvoiceGetResponse.Item];
    }

    const value = getInvoiceAdditionalValue(field, invoice);
    if (!value) {
        return undefined;
    }
    let date;
    switch (field.type) {
        case DataType.List:
            return field.listItems.find(x => x.id.toString() === value)?.value;
        case DataType.Date:
            date = parseDate(value);
            if (date) {
                return formatDateDigits(date);
            }
            return "";
        //return formatDateDigits(new Date(value));
        case DataType.Text:
        default:
            return value;
    }
};

export default InvoiceService;