import React from "react";
import { Tooltip } from "bootstrap";

const TooltipComponent = (props: React.PropsWithChildren<Partial<Tooltip.Options> & {title: string}>) => {
    const spanRef = React.useRef<HTMLSpanElement|null>(null);
    React.useEffect(() => {
        const span = spanRef.current;
        let tooltip: null | Tooltip = null;
        if (span) {
            tooltip = new Tooltip(span,{
                ...props,
                customClass: "tooltipIntiza " + (props.customClass ?? ""),  
            });
        }
        return () => {
            if (tooltip) {
                tooltip.dispose();
            }
        };
    }, [props]);
    return <span ref={spanRef}>{props.children}</span>;
};

export default TooltipComponent;