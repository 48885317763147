import { useContext, useState } from "react";
import FormContext from "../../providers/FormContext";
import CSS from 'csstype';

const Input = (props: any) => {

    const [value, setValue] = useState(props.filterKey ? props.item[props.filterKey] : "")

    const { disabled, getData, setData } = useContext(FormContext);

    const displayName = (): CSS.Properties => {

        const displayTrue: CSS.Properties = {
            'display': 'block'
        }

        const hidden: CSS.Properties = {
            'visibility': 'hidden'
        }

        const displayFalse: CSS.Properties = {
            'display': 'none'
        }

        if (props.name !== undefined) {
            return displayTrue
        }

        if (props.useLabelSpace !== undefined) {
            if (props.useLabelSpace) {
                return hidden
            }
        }

        return displayFalse
    }

    const handleInput = (e: any) => {

        if (props.type === "number") {
            if (props.callback) {
                if (props.isAdditional) {
                    props.callback(props.formKey, e.target.value)
                    setValue(e.target.value)
                } else {
                    props.callback(e)
                    setValue(e.target.value)
                }
            } else {
                setValue(e.target.value)
                setData(props.formKey, Number(e.target.value))
            }
        }
        if (props.type === "text") {
            if (props.callback) {
                if (props.isAdditional) {
                    props.callback(props.formKey, e.target.value)
                    setValue(e.target.value)
                } else {
                    props.callback(e)
                    setValue(e.target.value)
                }
            } else {
                setValue(e.target.value)
                setData(props.formKey, e.target.value);
            }
        }
    }

    const getAdditionalValue = () => {
        const adds = getData("additionals")
        if (adds) {
            const item = adds.filter((add: any) => add.Id === props.formKey)
            if (item.length > 0) {
                return item[0].Value
            }
        }
    }

    return (
        props.withBox ?
            <div className={props.className}>
                {props.name ? <label style={displayName()}>{props.name ? props.name : "Default"}</label> : <></>}
                <div className="input-group">
                    <input type={props.type} value={props.isAdditional ? getAdditionalValue() : (getData(props.formKey) ? getData(props.formKey) : value)} className="form-control form-control-dng" onChange={(e) => handleInput(e)} placeholder={props.placeholder} pattern={props.pattern} disabled={disabled} />
                    <div className="input-group-prepend">
                        <div className="input-group-text border-left-0" style={{ height: '100%' }}>{props.boxText}</div>
                    </div>
                </div>
            </div>
            :
            <div className={props.className}>
                <label style={displayName()}>{props.name ? props.name : "Default"}</label>
                <input type={props.type} value={props.isAdditional ? getAdditionalValue() : (getData(props.formKey) ? getData(props.formKey) : value)} className="form-control" onChange={(e) => handleInput(e)} placeholder={props.placeholder} pattern={props.pattern} disabled={disabled} />
            </div>
    );
}

export default Input;