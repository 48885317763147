import { useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import AccountService, { AccountSignUpRequest } from "../../services/AccountService";
import { toEvent } from "../../utils/FuncUtils";
import { RequiredManager, ValidationMessage } from "../shared/RequieredManager";
import { isNullOrWhitespace } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import { handleErrorWithToast } from "../../utils/RequestUtils";

export const Create = () => {
    const history = useHistory();
    const token = new URLSearchParams(history.location.search).get("token") ?? "";
    const [model, setModel] = useState<Partial<AccountSignUpRequest>>({
        token,
    });
    const [repeatedPassword, _setRepeatedPassword] = useState("");
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visiblePasswordRepeat, setVisiblePasswordRepeat] = useState(false);
    const [loading, setLoading] = useState(false);
    const requiredManager = new RequiredManager();
    const submit = async () => {
        if (!requiredManager.validate()) {
            return;
        }
        setLoading(true);
        await handleErrorWithToast(AccountService.accountSignUp(model as AccountSignUpRequest), () => setLoading(false));
        history.push("/");
    };
    const changeModel = <T extends keyof AccountSignUpRequest,>(field: T) => requiredManager.makeRequiredWithId((value: string) => setModel(x => ({ ...x, [field]: value })), field);
    const setRepeatedPassword = requiredManager.makeRequired((value: string) => _setRepeatedPassword(value));
    return (
        <>
            <p className="mb-3">La contraseña debe tener al menos 8 caracteres, debe incluir mayúsculas, minúsculas y números o símbolos ($, !, %, etc.)</p>
            <div className="box">
                <h1>Crea tu cuenta</h1>
                <div className="input-group">
                    <input type="text" placeholder={TranslationService.translate.Name} onChange={toEvent(changeModel("firstname"))} />
                </div>
                <ValidationMessage defaultValue={model?.firstname} onChange={requiredManager.getValidation("firstname")} />
                <div className="input-group">
                    <input type="text" placeholder={TranslationService.translate.LastName} onChange={toEvent(changeModel("lastname"))} />
                </div>
                <ValidationMessage defaultValue={model?.lastname} onChange={requiredManager.getValidation("lastname")} />
                <div className="input-group">
                    <input type="text" placeholder={TranslationService.translate.Email} onChange={toEvent(changeModel("email"))} />
                    <i className="fas fa-user icono-input" />
                </div>
                <ValidationMessage defaultValue={model?.email} onChange={requiredManager.getValidation("email")} />
                <div className="input-group">
                    <input type={visiblePassword ? "text" : "password"} placeholder={TranslationService.translate.Password} onChange={toEvent(changeModel("password"))} />
                    <i className={`fas fa-${visiblePassword ? "un" : ""}lock-alt icono-input`} onClick={() => setVisiblePassword(x => !x)} />
                </div>
                <ValidationMessage defaultValue={model?.password} onChange={requiredManager.getValidation("password")} />
                <div className="input-group">
                    <input type={visiblePasswordRepeat ? "text" : "password"} placeholder={TranslationService.translate.RepeatPassword} onChange={toEvent(setRepeatedPassword)} />
                    <i className={`fas fa-${visiblePasswordRepeat ? "un" : ""}lock-alt icono-input`} onClick={() => setVisiblePasswordRepeat(x => !x)} />
                </div>
                <ValidationMessage defaultValue={repeatedPassword} onChange={setRepeatedPassword} validationMethod={(x) => !isNullOrWhitespace(x) && x === model?.password}
                    message="Las contraseñas deben coincidir" />
                <div className="login-btn" id="loginbut">
                    <button type="button" onClick={() => submit()} className="botondemo" disabled={loading}>
                        {"Crea tu cuenta".toUpperCase()}
                        {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>
                </div>
            </div>
        </>
    );
};