import { SortDirection } from "../pages/shared/entities/Sort";
import RequestPortalService from "./RequestPortalService";

export type PortalClientResponse = {
    personName: string,
    urlLogo: string,
    paymentMethod: string,
    clientInfo: ClientInfoType,
    customerFieldName?: string,
    customersFieldName?: string,
    customerMessages?: boolean,
    customerPortalShowHistory?: boolean,
    groupName?: string,
    showGroup?: boolean,
    referenceNumberName?: string,
    companyName?: string
}

export type ClientInfoType = {
    Observaciones?: string,
    Domicilio?: string,
    Contacto?: string,
    Teléfono?: string,
    CUIT?: string,
    Categoria?: string,
    Celular?: string,
    Ciudad?: string,
    Fecha?: string,
    BLOQUEADO?: string,
    Hipervínculo?: string,
}

export type PortalIoStatusestResponse = {
    personName: string,
    urlLogo: string,
    paymentMethod: string,
    clientInfo: any,
    customerFieldName?: string,
    customersFieldName?: string,
}

export type PortalClientDataResponse = {
    data: PortalClientResponse,
    itemCount?: number;
    pageSize?: number;
}
export type FieldDataStatus = {
    Dependencies: any[]
    IOStatusID: number,
    Name: string
}
export type PortalIoStatusesDataResponse = {
    statuses: FieldDataStatus[]
};

export type PortalCustomerTokenResponse = {
    token: string
};

export type fieldsandvaluesKeyValue = { id: number, value?: string }
export class PortalRequestSaveMessage {
    ids: string;
    iostatusid: number;
    email: string | null = null;
    comment: string | null = null;
    fieldsandvalues: fieldsandvaluesKeyValue[] | [] = [];
    files: string[] | [] = [];

    constructor() {
        this.ids = '';
        this.iostatusid = 0;
        this.files = [];
        this.fieldsandvalues = [];
    }
}

export type TokenByHashRequest = {
    hash: string
}

export type PortalClientIoDataResponse = {
    headers: [
        {
            columnId: string,
            dataType: number
            columnTitle: string
        }
    ],
    rowsToShow: [
        {
            Key: string,
            Value: true
        }
    ],
    count: number
    page: number
    pagesize: number
    totalAmount: number
    totalPending: number
    currencyTotals: {
        "(1994, $)": [
            {
                Item1: number
                Item2: number
            }
        ],
        "(19037, U$D)": [
            {
                Item1: number
                Item2: number
            }
        ],
        "(67586, €)": [
            {
                Item1: number
                Item2: number
            }
        ]
    }
}

export class PortalClientDataRequest {
    page: number = 0;
    pagesize: number = 10;
    sortColumn: string = 'client';
    descending: SortDirection = SortDirection.Descending;
    filter: string | null = null;
    quickFilter: number | null = null;
}

class PortalClientService {

    public static getClientData(customerToken: string): Promise<PortalClientDataResponse | Error> {
        return RequestPortalService.post("/customer/get", {}, customerToken);
    }
    public static getIoList(request: PortalClientDataRequest, customerToken: string): Promise<PortalClientIoDataResponse | Error> {
        return RequestPortalService.post("/customer/getiolist", { ...request }, customerToken);
    }
    public static getAdditionals(customerToken: string): Promise<PortalClientDataResponse | Error> {
        return RequestPortalService.post("/customer/getadditionals", {}, customerToken);
    }
    public static getIoStatuses(customerToken: string): Promise<{ data: PortalIoStatusesDataResponse } | Error> {
        return RequestPortalService.post("/customer/getiostatuses", {}, customerToken);
    }
    public static saveMessage(data: PortalRequestSaveMessage, customerToken: string): Promise<{ data: PortalIoStatusesDataResponse } | Error> {
        return RequestPortalService.post("/customer/SendMessage", data, customerToken);
    }
    public static getTokenByHash(data: TokenByHashRequest): Promise<{ data: PortalCustomerTokenResponse } | Error> {
        return RequestPortalService.post("/customer/gettoken", data);
    }
    public static async getDownloadIoFile(fileId: string, fileName: string = "export.xlsx") {
        return RequestPortalService.downloadFile("/customer/downloadiofiles?ioID=" + fileId, fileName);
    }

}

export default PortalClientService;