import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { DashboardPriorityResponse } from "../../../entities/company/Dashboard/DashboardPriorityResponse";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatCurrency } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";
import DashboardHeader from "./DashboardHeader";
import PriorityService from "../../../services/PriorityService";
import TooltipComponent from "../../shared/TooltipComponent";

const PriorityChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const history = useHistory();
    const { translate, currentLanguage } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<DashboardPriorityResponse>();

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        const dashboardResult = result as DashboardPriorityResponse;
        setResponse(dashboardResult);
    };

    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);


    const gotoClient = (priority: number) => {
        dashboardFilters.push({
            field: "-14",
            operator: 0,
            value: priority.toString()
        });
        const qs = encodeURIComponent(DashboardService.GetQueryFilter(dashboardFilters, FilterCollection.Client) || "");
        history.push(`/${currentLanguage}/client?filter=${qs}`);
    };

    if (loading) {
        return (<Loading height={316} />);
    }

    if (error || !response) {
        return <></>;
    }

    return (
        <div className="card">
            <div className="card-body">
                <DashboardHeader title={translate.Priority} tooltip={translate.PriorityTip} />
                <div className="table-responsive">
                    <table className="table tablePriority">
                        <thead>
                            <tr>
                                <th style={{ width: "280px" }}>{translate.Priority}</th>
                                <th style={{ width: "100px" }} className="text-end">{translate.Clients}</th>
                                <th style={{ width: "100px" }} className="text-end">{translate.Amount}</th>
                                <th style={{ width: "200px" }}></th>
                                {/*                             <th style={{ width: "100px" }} className="text-end">{translate.AmountDue}</th>
                            <th style={{ width: "100px" }} className="text-end">{translate.NotDueAmount}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {PriorityService.getRankings().map((x, index) => {
                                const dashboardPriority = response.list.find(x => x.Ranking === (index + 1)) ?? {
                                    Ranking: index + 1,
                                    CalculatedRank: undefined,
                                    Count: 0,
                                    Amount: 0,
                                    OverdueAmount: 0,
                                    Invoices: 0
                                };
                                const rankingIndex = PriorityService.getRankingIndex(dashboardPriority.Ranking) as number;
                                return (
                                    <tr key={index} onClick={() => { gotoClient(dashboardPriority.Ranking); }} className="pointer">
                                        <td className="fw-normal">
                                            <span className={"priority-" + rankingIndex}>{dashboardPriority.Ranking}</span>&nbsp;{PriorityService.getPriorityRankText(index + 1)}
                                        </td>
                                        <td className="fw-normal text-end">{dashboardPriority.Count}</td>
                                        <td className="fw-normal text-end">
                                            {formatCurrency(dashboardPriority.Amount, CompanyService.getDefaultCurrencyId())}
                                        </td>
                                        <td className="bar-progress">
                                            <TooltipComponent title={<div>
                                                <div className="text-danger">{TranslationService.translate.Due} {formatCurrency(dashboardPriority.OverdueAmount)}</div>
                                                <div className="text-primary">{TranslationService.translate.NoDue} {formatCurrency(dashboardPriority.Amount - dashboardPriority.OverdueAmount)}</div></div>} >
                                                <div className="bar">
                                                    <div className="expired" style={{ width: (Math.max(dashboardPriority.OverdueAmount, 0) * 100 / (response.maxDebt || 1)) + "%" }}></div>
                                                    <div className="not-expired" style={{ width: (Math.max(dashboardPriority.Amount - dashboardPriority.OverdueAmount, 0) * 100 / (response.maxDebt || 1)) + "%" }}></div>
                                                </div>
                                            </TooltipComponent>
                                        </td>
                                    </tr>
                                );
                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default PriorityChart;