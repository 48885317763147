import { ReactNode, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import ClientEdit from "../ClientEdit";
import InvoiceNew from "../invoice/InvoiceNew";
import SearchInput from "../../shared/components/SearchInput";
import MenuButton from "../../shared/components/MenuButton";
import ClientService from "../../../services/ClientService";
import { ClientTableContextValues } from "../providers/ClientTableProvider";
import AdvancedFilters, { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";
import { FilterCollection } from "../../../services/FilterService";
import ExportButton from "../../shared/components/ExportButton";
import { ClientListRequest } from "../entities/ClientListRequest";
import { NavigationHistoryContext } from "../../shared/NavigationHistory";
import { ImportService } from "../../../services/ImportService";
import { addIf } from "../../../utils/Utils";

const FilterContainer = ({ showActions = false, enableImport = true, additionalAction = null, hideCreate = false }:
    { showActions?: boolean, enableImport?: boolean, additionalAction?: ReactNode, hideCreate?: boolean }) => {
    const tableContext = useContext(TableContext);

    const { applySearch, reload, request } = tableContext as ClientTableContextValues;
    const { translate } = TranslationService;
    const history = useHistory<{ prevRequest?: ClientListRequest } | undefined>();

    const { state, search } = history.location;
    const urlParams = new URLSearchParams(search);
    const filterQS = (state?.prevRequest ? state.prevRequest.filter : urlParams.get("filter")) ?? "";

    const showNewClientPanel = () => {
        FloatingPanelService.showPanel({
            title: translate.NewClient,
            children: <ClientEdit onClientSave={reload} />,
            width: 800,
            height: 669,
        });
    };
    const { setCurrentPath } = useContext(NavigationHistoryContext);
    setCurrentPath({
        name: TranslationService.translate.Clients + ` (${TranslationService.translate.Filtered})`,
        link: () => {
            history.push(`/${TranslationService.currentLanguage}/client`, { prevRequest: request });
        },
    });

    const showNewInvoicePanel = () => {
        FloatingPanelService.showPanel({
            title: translate.NewInvoice,
            children: <InvoiceNew reload={reload} />,
            height: 800,
            width: 750,
        });
    };

    const requestExport = (reportId: number | null) => {
        const { request, response } = tableContext as ClientTableContextValues;
        ClientService.export({ ...request, reid: reportId }, response!.itemCount);
    };

    // const actions: MenuButtonActions = [];
    // if (CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio")) {
    //     actions.push({ text: TranslationService.translate.Invoice, action: showNewInvoicePanel });
    // }

    // if (CompanyService.canDo("newperson") && !CompanyService.getSetting("hidecreateperson")) {
    //     actions.push({ text: TranslationService.translate.Customer, action: showNewClientPanel });
    // }

    const onApplySearch = (keyword: string) => {
        applySearch(keyword);
    };

    const applyFilter = (filters: string[]) => {
        const filterToApply = { extraFilters: filters };
        tableContext.applyFilters(filterToApply);
    };
    const imports = useMemo(() => {
        const importEntities = ImportService.getImports().filter(x => x.isEnabled);
        const openImportPage = (entityId: number) =>
            history.push(`/${TranslationService.currentLanguage}/ImportTemplateManual?entityId=${entityId}`);
        return importEntities.map((x, i) => ({ text: x.text, action: () => openImportPage(x.value), }));
    }, [history]);

    const newActions = [
        ...addIf(CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio"), { text: TranslationService.translate.AddInvoice, action: showNewInvoicePanel }),
        ...addIf((CompanyService.canDo("newperson") && !CompanyService.getSetting("hidecreateperson")), { text: TranslationService.translate.NewClient, action: showNewClientPanel }),
    ];

    return (
        <>
            <div className="genericHeader">
                <div className="actionButtonsHeader">
                    {additionalAction}
                    {(!hideCreate && newActions.length >0) && <MenuButton text={TranslationService.translate.New} showTitle actions={newActions} icon="fa-light fa-plus" hideChevron buttonClass="px-0" />}
                    {CompanyService.canDo("importdata") && enableImport &&
                        <MenuButton extraClassName="select-intiza-outline" text={TranslationService.translate.ImportAction} actions={imports} icon="fa-light fa-arrow-up-to-line" hideChevron/>}
                    {CompanyService.canDo("export") && enableImport && <ExportButton reportTypeId={2} onClick={(report) => requestExport(report)}></ExportButton>}
                    <AdvancedFiltersButton />
                </div>
                <div className="searcherFilterHeader" style={{ height: 40 }}>
                    <SearchInput onSearch={onApplySearch} autosearch={false} />
                </div>
            </div>
            <AdvancedFilters defaultValue={filterQS} page={FilterCollection.Client} onFilterApply={applyFilter} />

        </>
    );
};

export default FilterContainer;