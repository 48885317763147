import { useCallback, useEffect, useState } from "react";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { TranslationService } from "../../../services/TranslationService";
import HighchartWithChildrenChart from "./HighchartWithChildrenChart";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import ErrorCatcher from "../../shared/ErrorCatcher";

const AgingClientChart = ({
    dashboard,
    dashboardFilters,
    filterCallback,
    optionextend
}: {
    dashboard: DashboardEntity,
    dashboardFilters: FilterRequest[],
    filterCallback: (dashboardFilterEntity: DashboardFilterEntity) => void,
    optionextend: any
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<Highcharts.Options>();
    const [valuesEnabled, setValuesEnabled] = useState(false);

    const requestData = async () => {
        setLoading(true);
        const result = { dashboard, optionextend };
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        setError(false);
        const chart = { optionextend: result.optionextend };
        const colors = optionextend.series[0].data.map((el: any) => el[4]);
        chart.optionextend!.colors = colors;
        chart.optionextend.exporting?.buttons?.contextButton?.menuItems?.push("separator");
        const buttons = chart.optionextend.exporting?.buttons?.contextButton?.menuItems;
        const button = { text: TranslationService.translate.ShowHideValues, onclick: () => setValuesEnabled(prev => !prev) };
        (buttons as any).push(button);
        chart.optionextend!.plotOptions!.column!.pointWidth = 36;
        chart.optionextend.chart!.height = 250;

        if (chart.optionextend.chart !== undefined) {
            chart.optionextend!.plotOptions!.column!.borderRadius = "4";
        }
        setResponse({ ...chart.optionextend });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);



    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading height={556} />);
    }

    if (error || !response) {
        return (<></>);
    }

    const dashboarDonut = { ...dashboard };
    dashboarDonut.Type = 0;

    if (response.title !== undefined) {
        response.title.text = "";
    }

    const responseDonut = JSON.parse(JSON.stringify(response));
    
    if (responseDonut.chart !== undefined) {
        responseDonut.chart.type = "pie";
        responseDonut.chart!.height = 190;
        responseDonut.plotOptions.pie.innerSize = "66%";
        responseDonut.plotOptions.pie.dataLabels.enabled = false;
        responseDonut.plotOptions.pie.showInLegend = true;
        responseDonut.exporting.enabled = false;
        if (responseDonut.chart !== undefined) {
            responseDonut!.plotOptions!.pie!.borderRadius = "0%";
        }

        const series = responseDonut.series[0].data;
        const seriesDonut: any[][] = [
            [TranslationService.translate.NoDue, 0, "-33", "0", "#0076B6", "", 2, "601001628.09", "0"],
            [TranslationService.translate.Due, 0, "-33", "1", "#EF4E38", "", 2, "601001628.09", "0"],
        ];
        for (let i = 0; i < series.length; i++) {
            if (series[i][4] === "#EF4E38")
                seriesDonut[1][1] = seriesDonut[1][1] + series[i][1];
            else
                seriesDonut[0][1] = seriesDonut[0][1] + series[i][1];
        }
        responseDonut.series[0].data = seriesDonut;
        responseDonut.colors = ["#0076B6", "#EF4E38"];
    }

    const totalDues = responseDonut.series[0].data[1][1];
    const totalNoDues = responseDonut.series[0].data[0][1];
    const total = totalDues + totalNoDues;

    return (
        <div className="card">
            <div className="row">
                <ErrorCatcher onError={() => setError(true)}>
                    <div className="col-4">
                        <HighchartWithChildrenChart
                            dashboard={dashboarDonut}
                            options={responseDonut}
                            dashboardFilters={dashboardFilters}
                            filterCallback={filterCallback}
                            valuesEnabled={valuesEnabled}
                        />
                        <div className="d-flex justify-content-around">
                            <div className="d-flex fw-bold align-items-centers gap-2">
                                <span className="dashboard-char-reference" style={{ backgroundColor: "#0076B6" }} />
                                <div className="fw-bold align-items-baseline gap-2">
                                    <p style={{ color: "#0076B6", fontSize: 14 }}>{formatCurrency(totalNoDues)}</p>
                                    <p>{formatInteger((totalNoDues * 100) / total)}% {TranslationService.translate.NoDues}</p>
                                </div>
                            </div>
                            <div className="d-flex fw-bold align-items-center gap-2">
                                <span className="dashboard-char-reference" style={{ backgroundColor: "#EF4E38" }} />
                                <div className="fw-bold align-items-baseline gap-2">
                                    <p style={{ color: "#EF4E38", fontSize: 14 }}>{formatCurrency(totalDues)}</p>
                                    <p>{formatInteger((totalDues * 100) / total)}% {TranslationService.translate.Dues}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        {optionextend &&
                            <HighchartWithChildrenChart
                                dashboard={dashboard}
                                options={response}
                                dashboardFilters={dashboardFilters}
                                filterCallback={filterCallback}
                                valuesEnabled={valuesEnabled}
                            />}
                    </div>
                </ErrorCatcher>
            </div>
        </div>
    );
};
export default AgingClientChart;

