import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import { DashboardTableResponse } from "../../../entities/company/Dashboard/DashboardTableResponse";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatCurrency } from "../../../utils/FormatUtils";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";
import { OptionalMap } from "../../../utils/Utils";
import { parseIntOrDefault } from "../../../utils/ParseUtils";

const TableChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const { translate, currentLanguage } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<DashboardTableResponse>();
    const history = useHistory();

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        const dashboardResult = result as DashboardTableResponse;
        setResponse(dashboardResult);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);


    const goToClient = function (index: number) {
        if (index >= 0) {
            const data: Array<any> = (response!.serie![0] as any).data[index];

            // if (serie.data[0][6] === 0) {
            //     let minDate = new Date(0);
            //     let maxDate = new Date(2200, 0);
            //     if (data[3] === "1") {
            //         maxDate = new Date();
            //     }
            //     else {
            //         minDate = new Date();
            //     }
            //     const value = formatIntizaDate(minDate) + "-" + formatIntizaDate(maxDate);
            //     dashboardFilters.push({
            //         field: "-1024",
            //         operator: data[8],
            //         value: value
            //     });
            // }
            // else {
            dashboardFilters.push({
                field: (data[2] === -29) ? "-7" : data[2],
                operator: data[8],
                value: data[3]
            });
        }
        // }
        const qs = encodeURI(DashboardService.GetQueryFilter(dashboardFilters, FilterCollection.Client) || "");
        history.push(`/${currentLanguage}/client?filter=${qs}`);
    };

    if (loading) {
        return (<Loading height={175} />);
    }

    if (error || !response) {
        return <></>;
    }

    let variable = "";
    let amount = false;
    let splitdue = false;
    const serie = response.serie[0];
    if (serie?.data?.length > 0) {
        variable = DashboardService.getVariableName(serie.data[0][6])!;
        switch (serie.data[0][6]) {
            case 2: //PENDING AMOUNT
                amount = true;
                splitdue = true;
                break;
            case 0: //CUSTOMER QTY:

                break;
            case 7: //CLAIMABLE AMOUNT
                amount = true;
                break;
            case 1: //INVOICE QTY

                break;
            case 3: //DUE AMOUNT
                amount = true;
                break;
        }
    }

    if (!splitdue) {
        return (
            <div className="card">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{variable}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serie?.data?.map((x, index) => (
                                <tr key={index}>
                                    <td>
                                        <button className="btn btn-link" onClick={() => goToClient(index)}>{x[0]}</button>
                                    </td>
                                    <td className="pointer" onClick={() => goToClient(index)}>
                                        {amount ? formatCurrency(x[1], CompanyService.getDefaultCurrencyId()) : x[1]}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <button className="btn btn-link" onClick={() => goToClient(-1)}>{TranslationService.translate.Total}</button>
                                </td>
                                <td className="pointer" onClick={() => goToClient(-1)}>
                                    {OptionalMap(serie.data.reduce((prev, curr) => prev + parseIntOrDefault(curr[1], 0), 0), x => amount ? formatCurrency(x) : x)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    const due = serie.data.reduce((prev, curr) => prev + parseIntOrDefault(curr[7], 0), 0);
    const varTotal = serie.data.reduce((prev, curr) => prev + parseIntOrDefault(curr[1], 0), 0);
    const noDue = varTotal - due;
    return (
        <div className="card">
            <div className="card-body">
                <table className="table chart">
                    <thead>
                        <tr>
                            <th>{serie.data[0][5]}</th>
                            <th>{translate.NotDue}</th>
                            <th>{translate.Due}</th>
                            <th>{variable}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serie.data.map((x, index) => (
                            <tr key={index}>
                                <td>
                                    <button className="btn btn-link" onClick={() => goToClient(index)}>{x[0]}</button>
                                </td>
                                <td className="pointer" onClick={() => goToClient(index)}>
                                    {amount ? formatCurrency(parseIntOrDefault(x[1], 0) - parseIntOrDefault(x[7], 0)) : x[1] - x[7]}
                                </td>
                                <td className="text-danger pointer" onClick={() => goToClient(index)}>
                                    {amount ? formatCurrency(parseIntOrDefault(x[7], 0)) : x[7]}
                                </td>
                                <td className="pointer" onClick={() => goToClient(index)}>
                                    {amount ? formatCurrency(parseIntOrDefault(x[1], 0)) : x[1]}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <button className="btn btn-link" onClick={() => goToClient(-1)}>{TranslationService.translate.Total}</button>
                            </td>
                            <td className="pointer" onClick={() => goToClient(-1)}>
                                {amount ? formatCurrency(noDue) : noDue}
                            </td>
                            <td className="text-danger pointer" onClick={() => goToClient(-1)}>
                                {amount ? formatCurrency(due) : due}
                            </td>
                            <td className="pointer" onClick={() => goToClient(-1)}>
                                {amount ? formatCurrency(varTotal) : varTotal}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
        </div >
    );

};
export default TableChart;