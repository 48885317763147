import { forwardRef, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Cast, OptionalMap, Switch, encodeFilters } from "../../utils/Utils";
import { default as Table, TableHeaderWithFieldId } from "../shared/Table";
import { TranslationService } from "../../services/TranslationService";
import { IoActivityListRequest } from "./entities/IoActivityListRequest";
import InvoiceService from "../../services/InvoiceService";
import ClientService from "../../services/ClientService";
import ActivityService from "../../services/ActivityService";
import { IoActivityListEntities } from "./entities/IoActivityListResponse";
import { formatCurrency, formatDateShort, formatInteger, formatNumber, unbreak } from "../../utils/FormatUtils";
import CompanyService, { DataType } from "../../services/CompanyService";
import { parseDate } from "../../utils/ParseUtils";
import { TaskStatus } from "../task/entities/TaskStatus";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import Dropdown from "../shared/components/Dropdown";
import TableContext from "../task/TableContext";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import { FilterCollection } from "../../services/FilterService";
import FloatingPanelService from "../shared/FloatingPanel";
import { ReportScheduledEditItem } from "./ReportScheduledEditItem";
import { calculatePageCount, setCurrentPageHelper, useEndpointData } from "../../utils/TableUtils";
import TooltipComponent from "../shared/TooltipComponent";

const ReportIoActivityList = () => {
    const tableHeaders = useMemo(getTableHeaders, []);
    const [request, setRequest] = useState(new IoActivityListRequest());
    const endpoint = useCallback(() => ActivityService.getIoActivityList(request), [request]);
    const context = useEndpointData(endpoint);
    const countItems = useMemo(() => context.response?.itemCount, [context.response?.itemCount])

    const typeValues = [
        { text: TranslationService.translate.AllActivities, value: 0 },
        { text: TranslationService.translate.OnlyEmails, value: 1 },
        { text: TranslationService.translate.OnlyComments, value: 2 },
        // { text: TranslationService.translate.OnlySMS, value: 4 },
    ];

    context.pageCount = calculatePageCount(context.response);
    context.currentPage = request.page;
    context.setCurrentPage = setCurrentPageHelper(setRequest, context.pageCount);


    const onFilterChange = (value: number) => {
        setRequest(r => ({ ...r, quickfilter: value }));
    };

    const applyFilters = (filters: string[]) => {
        const filterEncoded = encodeFilters(filters);
        setRequest(r => ({ ...r, filter: filterEncoded }));
    };

    const openModal = () => {
        FloatingPanelService.showPanel({
            children: <ReportScheduledEditItem defaultReportId={3} />,
            title: TranslationService.translate.ScheduledReports,
        });
    };

    return (
        <TableContext.Provider value={context}>
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>{TranslationService.translate.ReportInvociesActivities}</h2>
                    {CompanyService.getSetting("allowscheduledreports") &&
                        <Link to={`/${TranslationService.currentLanguage}/report/scheduled`}>
                            {TranslationService.translate.ViewScheduledReports}
                        </Link>}
                </div>
                <div className="genericHeader">
                    <div className="searcherFilterHeader align-items-center">
                        <TooltipComponent title={TranslationService.translate.Export} >
                            <ExportButton ReportTypeId={3} requestData={request} count={countItems} />
                        </TooltipComponent>
                        <AdvancedFiltersButton />
                        <Dropdown quickFilter onChange={onFilterChange} items={typeValues} defaultValue={0} />
                    </div>
                    <div className="d-flex gap-2">
                        {CompanyService.getSetting("allowscheduledreports") &&
                            <button onClick={openModal} className="btn btn-primary">
                                {TranslationService.translate.Program}
                            </button>}
                    </div>
                </div>
                <div className="pt-0">
                    <AdvancedFilters page={FilterCollection.ReportIoActivity} onFilterApply={applyFilters} />
                </div>
                <Table headers={tableHeaders} item={ReportActivityItem} stickyHeader={true} />
            </div>
        </TableContext.Provider>
    );
};

const ExportButton = ({ ReportTypeId = 1, requestData, count }: { ReportTypeId?: number, requestData: any, count?: number }) => {

    const exportInvoices = (reportId: number | null) => {
        ActivityService.exportIoActivityList({ ...requestData, reid: reportId || 0 }, count);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === ReportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader">
        {CompanyService.canDo("export") &&
            <MenuButton text={TranslationService.translate.ExportTo} actions={exportItems} extraClassName="select-intiza-outline" icon="fa-light fa-arrow-down-to-line" hideChevron />}
    </div>);
};

const ReportActivityItem = ({ data }: { data: IoActivityListEntities.ListItem }) => {
    const tableHeaders = getTableHeaders();
    const clientFieldIds = ClientService.getClientHeaders().map(x => x.fieldId);

    const specialCases = {
        "download": () => !data.Item2.HasFiles ? <td></td> : <td><i className="fas fa-fw fa-download pointer" onClick={(e) => { e.stopPropagation(); handleErrorWithToast(InvoiceService.downloadAllFilesFromInvoice(data.Item2.IOID)); }}></i></td>,
        "link": () => !data.Item2.HasFiles ? <td></td> : <td><i className="fas fa-fw fa-download pointer" onClick={(e) => { e.stopPropagation(); handleErrorWithToast(InvoiceService.downloadAllFilesFromInvoice(data.Item2.IOID)); }}></i></td>,
        "ActivityTypeID": (x: IoActivityListEntities.ListItem) => (<td>
            {CompanyService.getActivityTypes().find(y => y.ActivityTypeID === x.Item1.ActivityTypeID)?.ActivityTypeName}
        </td>),
        "Content": (x: IoActivityListEntities.ListItem) => <td><ActivityContent item={x} /></td>,
        "typeName": ActivityTypeName,
    };

    return (
        <tr key={data.Item2.IOID} >
            {tableHeaders.map(header => clientFieldIds.includes(header.fieldId) ?
                <ClientColumn key={header.fieldId} data={data} header={header} specialCases={specialCases} /> :
                <TableColumnItem key={header.fieldId} data={data} header={header} specialCases={specialCases} />)}
        </tr>
    );
};

const ActivityContent = forwardRef<HTMLTableCellElement, { item: IoActivityListEntities.ListItem }>(({ item: x }, ref) => (<div ref={ref}>
    {x.Item1.typeName === "Email" ? EmailDisplay({ item: x }) : x.Item1.typeName === "Comment" ? <span dangerouslySetInnerHTML={{ __html: x.Item1.Content }}></span> : x.Item1.typeName === "SMS" ? <span>
        <b>{x.Item1.SMSPhone}:</b>{x.Item1.Content}</span> : x.Item1.Content}
</div>));

const EmailDisplay = ({ item }: { item: IoActivityListEntities.ListItem }) => {
    const { translate } = TranslationService;

    return (
        <>
            <div><b>{translate.From}:</b> {item.Item1.From}</div>
            <div><b>{translate.To}:</b> {item.Item1.To}</div>
            <div className="mb-2"><b>{translate.Subject}:</b> {item.Item1.Subject}</div>
            <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: item.Item1.Content }} />
        </>
    );
};

const ActivityTypeName = (x: IoActivityListEntities.ListItem) => {
    switch (x.Item1.typeName) {
        case "Comment":
            return (<td>
                <i className="fa fa-comment-alt-lines"></i> {TranslationService.translate.Comment}
            </td>);
        case "Email":
            return (<td>
                <i className="fa fa-envelope"></i> {TranslationService.translate.Email}
            </td>);
        case "SMS":
            return (<td>
                <i className={"fa fa-sms" + (x.Item1.Status === -999 ? " text-danger" : "")}></i> {TranslationService.translate.SMS}
            </td>);
        case "Task":
            return (<td>
                <i className={"fa fa-fw " + Switch(
                    [x.Item1.Status === TaskStatus.Validated, "fa-check-double"],
                    [x.Item1.Status === TaskStatus.Fulfilled, "fa-check"]) ?? ""}></i> {TranslationService.translate.Task}
            </td>);
        default:
            return (<td></td>);
    }
};

function getTableHeaders() {
    const clientFields = ClientService.getClientHeaders();
    clientFields.forEach(x => x.sorteable = false);
    const activtyFieldsStart = ActivityService.getActivityHeadersStart();
    const activtyFieldsEnd = ActivityService.getActivityHeadersEnd();
    const invoiceFields = InvoiceService.getTableHeaders();
    const headersToFilter = ["hasAttachments", "date2"];
    return [
        ...activtyFieldsStart,
        ...clientFields,
        ...activtyFieldsEnd,
        ...invoiceFields,
        {
            ...new TableHeaderWithFieldId(
                "download",
                TranslationService.translate.Download,
                false, false,
                "download",
                DataType.Link,
            )
        }
    ].filter(x => !headersToFilter.includes(x.fieldId));
}

const TableColumnItem = <T,>({ data, header, specialCases = {} }: { data: IoActivityListEntities.ListItem, header: TableHeaderWithFieldId, specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) } }) => {
    const { translate, currentLanguage } = TranslationService;

    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.Item2.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Value
                || Cast<{ transactionadditionals?: typeof data.Item2.Person.additionals }>(data).transactionadditionals?.find(x => x.Id.toString() === header.fieldId)?.Value
                || data.Item2.additionals.find(x => x.Id.toString() === header.fieldId)?.Value;
        }

        return data.Item1[header.fieldId as keyof IoActivityListEntities.ListItem["Item1"]]
            || data.Item2[header.fieldId as keyof IoActivityListEntities.ListItem["Item2"]];
    };

    const negativeValueClass = CompanyService.getSetting("iosnegativeamountinred") ? "text-danger" : "";
    const value = getValue();
    const specialCase = specialCases[header.fieldId];
    if (specialCase) {
        const caseResult = specialCase(Cast<T>(data));
        if (caseResult) {
            return caseResult;
        }
    }

    switch (header.fieldId) {
        case "StatusIcon":
            return <td>{data.Item2.pending === 0 ? <i className="far fa-square-check"></i> : ""}</td>;
        case "dueDate":
            if (data.Item2.dueDays === 0) {
                return <td>{data.Item2.dueDate ? formatDateShort(new Date(data.Item2.dueDate)) : ""}</td>;
            }
            if (data.Item2.dueDays > 0) {
                return <td className="text-danger">{formatDateShort(new Date(data.Item2.dueDate))}<small className="ps-1">({data.Item2.dueDays} {translate.Days})</small></td>;
            }
            return <td>{unbreak(formatDateShort(new Date(data.Item2.dueDate)))}</td>;
        case "pending2":
            {
                const defaultCurrencyId = CompanyService.getDefaultCurrencyId();
                if (data.Item2.CurrencyID === defaultCurrencyId) {
                    return <td className={"text-end"}></td>;
                }
                const currencies = CompanyService.getCurrencies();
                const rate = currencies.find(x => x.CurrencyId === data.Item2.CurrencyID)!.Rate;
                return <td className={"text-end " + (data.Item2.pending < 0 ? negativeValueClass : "")}>{formatCurrency(data.Item2.pending * rate, defaultCurrencyId, formatNumber)}</td>;
            }
        case "Name":
            return (<td className="is-link long-text">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.Item2.personId}`}>
                        {Cast<{ Fullname: string }>(data).Fullname}
                    </Link>
                </span>
            </td>);
        case "Address":
        case "Phone":
            return (<td>{data.Item2.Person[header.fieldId]}</td>);
        case "status": {
            const status = CompanyService.getIoStatus().find(x => x.IOStatusID === data.Item2.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName();
            return <td style={{ padding: 0, maxWidth: "unset", textAlign: "right" }}>
                {status}
            </td>;
        }
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end " + (parseFloat(value) < 0 ? negativeValueClass : "")}>{formatCurrency(parseFloat(value), data.Item2.CurrencyID, formatNumber)}</td>;
        case DataType.Number:
            return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{OptionalMap(parseDate(value), x => formatDateShort(x))}</td>;
        case DataType.List:
            {
                const additionalId = header.fieldId;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId);
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value);
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
};

const ClientColumn = <T,>({ data, header, specialCases }: { data: IoActivityListEntities.ListItem, header: TableHeaderWithFieldId, specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) } }) => {
    const { currentLanguage } = TranslationService;

    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.Item2.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data.Item2.Person[header.fieldId as keyof IoActivityListEntities.ListItem["Item2"]["Person"]];
    };

    const negativeValueClass = CompanyService.getSetting("iosnegativeamountinred") ? "text-danger" : "";
    const value = getValue();
    const specialCase = specialCases && specialCases[header.fieldId];
    if (specialCase) {
        const caseResult = specialCase(Cast<T>(data));
        if (caseResult) {
            return caseResult;
        }
    }

    switch (header.fieldId) {
        case "Address":
        case "Phone":
            return (<td>{data.Item2.Person[header.fieldId]}</td>);
        case "Name":
            return (
                <td className="is-link long-text">
                    <span className="w-md">
                        <Link to={`/${currentLanguage}/client/details?id=${data.Item2.Person.PersonId}`}>
                            {data.Item2.Person.Name}
                        </Link>
                    </span>
                </td>
            );
        case "dueDays":
        case "averageAgingDays":
        case "averagePaymentDays":
        case "count":
            return <td className={"text-end"}>{formatInteger(value)}</td>;
    }

    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }

    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end " + (parseFloat(value) < 0 ? negativeValueClass : "")}>{formatCurrency(parseFloat(value), CompanyService.getDefaultCurrencyId(), formatNumber)}</td>;
        case DataType.Number:
            return <td className={"text-end"} > {formatInteger(value)}</td >;
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
        case DataType.List:
            {
                const additionalId = data.Item2.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Id;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId?.toString());
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value);
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
};

export default ReportIoActivityList;